import { LISTING_TEAM_MEMBERS } from '../graphQl/subscriptions'
import { ADD_COMMUNICATION_PROVIDER } from '../graphQl/mutations'
import client from '../apollo/apollo';
import { message } from 'antd'

// get transaction template status
export function getListingTeamMembers(param) {
    return new Promise(function (resolve, reject) {
        try {

            const tempVariable = {
                listingId: param
            }
            try {
                client
                    .subscribe({ query: LISTING_TEAM_MEMBERS, variables: tempVariable })
                    .subscribe(
                        res => {
                            if (res && res.data && res.data.listing_team && res.data.listing_team.length > 0) {
                                resolve(res.data.listing_team)
                            }
                        }, err => {
                            reject(err)
                        })
            } catch (err) {
                reject(err)
            }

        }
        catch (e) {
            reject(e)
        }
    })
}

// get transaction template detail by template id
export function AddListingCommunictionProviderSMS(param) {
    return new Promise(function (resolve, reject) {
        try {

            let variableObject = {
                listingId: param,
                communicationProviderId: "4db11a82-d9e4-4f33-a7f2-b6a7828f59ec",
                communicationScope: {
                    promotional: [
                        "TOTELY"
                    ],
                    "transactional": [
                        "TOTELY",
                    ]
                },
                type: "sms",
                apiCredentials: {
                    promotional: "2+mGOGIqAjM-bY76uJsjmeo1kj6RRRqRiTRUrBHfvR",
                    transactional: "T9MXJu6QsT8-r6nbY5LCbYYW0qevI2qGY10c8OheNs"
                },
            }
            if (variableObject) {
                client.mutate({ mutation: ADD_COMMUNICATION_PROVIDER, variables: variableObject }).then((response) => {
                    resolve(response)
                })
                    .catch(err => {
                        message.error(err.message)
                        reject(err)
                    });
            }
        }
        catch (e) {
            reject(e)
        }
    })
}
// get transaction template detail by template id
export function AddListingCommunictionProviderEMAIL(param) {
    return new Promise(function (resolve, reject) {
        try {
            let variableObject = {
                listingId: param,
                communicationProviderId: "cbedcce1-6c82-4694-968c-e812811b4092",
                communicationScope: {
                    promotional: [
                        "ttlymail.com"
                    ],
                    transactional: [
                        "totelymail.com"
                    ]
                },
                type: "email",
                apiCredentials: {
                    promotional: "key-ce2adce25b90d4591a8eaec0c7537ecb",
                    transactional: "key-ce2adce25b90d4591a8eaec0c7537ecb"
                },

            }
            if (variableObject) {
                client.mutate({ mutation: ADD_COMMUNICATION_PROVIDER, variables: variableObject }).then((response) => {
                    resolve(response)
                })
                    .catch(err => {
                        message.error(err.message)
                        reject(err)
                    });
            }
        }
        catch (e) {
            reject(e)
        }
    })
}
