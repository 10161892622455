import React, { Component } from "react";
import List from '../List/index'
import gql from 'graphql-tag';
import _ from 'lodash'
import { Subscription } from 'react-apollo';
import { BROADCAST_SUB, BROADCAST_SUB_COUNT, RECEIVED_MESSAGES, RECEIVED_MESSAGES_COUNT } from 'graphQl/subscriptions';
import { GET_CONTACT_ID, GET_MESSAGES_FILTER_DATA } from 'graphQl/queries'
import CircularProgress from "../../components/CircularProgress/index";
import client from '../../apollo/apollo';

class NotifyScreen extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tabFrom: '',
            limitValue: 50,
            broadcastData: [],
            loading: false,
            screenFlag: false,
            dataCount: 0,
            pageValue: 1
        }
    }

    componentDidMount() {
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.pathname) {
            const splitFirst = _.split(this.props.history.location.pathname, 'received/');
            const splitSecond = _.split(splitFirst[1], '/');
            if (splitSecond[0] === 'messages') {
                this.setState({ tabFrom: splitSecond[1], screenFlag: true }, () => {
                    // this.getBroadcastList()
                    const userId = localStorage.getItem('uid')
                    if (userId) {
                        this.getContactId(userId)
                    }
                })
            } else {
                if (this.props && this.props.match && this.props.match.params) {
                    this.setState({ tabFrom: this.props.match.params.type }, () => {
                        this.getBroadcastList()
                    })
                }
            }
        }

    }

    getContactId = (uid) => {
        let temp = []
        client
            .query({
                query: GET_CONTACT_ID,
                variables: {
                    mappingUserId: uid
                }
            })
            .then(
                response => {
                    if (response.data && response.data.contact && response.data.contact.length > 0) {
                        const contact = response.data.contact
                        contact.forEach(element => {
                            temp.push(element.id)
                        })
                        if (temp.length === response.data.contact.length) {
                            this.getReceivedMessages(temp)
                        }                      
                    }
                })
            .catch(err => {
                console.log(err)
            })
    }

    getReceivedMessages = (contactId) => {
        this.setState({ loading: true }, () => {
            let variables = {
                id: contactId,
                type: this.state.tabFrom === "sms" ? 'SMS' : "EMAIL",
            }

            client
                .subscribe({ query: RECEIVED_MESSAGES, variables: variables })
                .subscribe(
                    res => {
                        if (res.data && res.data.broadcast.length > 0) {
                            this.setState({ broadcastData: res.data.broadcast, loading: false }, () => {
                                this.getCount(variables, 'receivedMessages')
                            })
                        } else {
                            this.setState({ broadcastData: [], loading: false })
                        }
                    }, err => {
                        this.setState({ loading: false })
                    })
        })
    }


    getBroadcastList = () => {
        this.setState({ loading: true }, () => {
            let variables = {
                listingId: localStorage.getItem('selectedListingId'),
                type: this.state.tabFrom === "sms" ? 'SMS' : "EMAIL",
                "limit": this.state.limitValue,
                "offset": 0
            }

            client
                .subscribe({ query: BROADCAST_SUB, variables: variables })
                .subscribe(
                    res => {
                        if (res.data && res.data.broadcast.length > 0) {
                            this.setState({ broadcastData: res.data.broadcast, loading: false }, () => {
                                const params = {
                                    listingId: localStorage.getItem('selectedListingId'),
                                    type: this.state.tabFrom === "sms" ? 'SMS' : "EMAIL",
                                }
                                this.getCount(params, 'messages')
                            })

                        } else {
                            this.setState({ broadcastData: [], loading: false })
                        }
                    }, err => {
                        this.setState({ loading: false })
                    })
        })
    }

    getCount = (variables, type) => {

        const gqlValue = type === 'messages' ? BROADCAST_SUB_COUNT : RECEIVED_MESSAGES_COUNT
        const query = gql`${gqlValue}`
        client
            .subscribe({
                query,
                variables: variables
            })
            .subscribe(
                res => {
                    if (res.data && res.data.broadcast_aggregate && res.data.broadcast_aggregate.aggregate && res.data.broadcast_aggregate.aggregate.count) {
                        this.setState({ dataCount: res.data.broadcast_aggregate.aggregate.count }, () => {
                        })
                    }
                },
                err => {
                    console.log('err', err)
                })
    }

    asignNextLimit(val) {
        this.setState({ limitValue: val }, () => {
            this.getBroadcastList()
        })
    }

    onFilterDataList = (data, filterValues) => {
        if (data) {
            if (data.isTag) {
                this.setState({ selectedFilterName: data.tagName });
            }
            this.getFilterResultData(data, filterValues)
        } else {
            this.setState({ selectedFilterData: {} }, () => {
                this.getBroadcastList()
            })
        }
    }

    getFilterResultData = (filterData, data) => {
        this.setState({ loading: true, selectedFilterData: data }, () => {
            let temp = filterData.messageType
            filterData.messageType = temp ? temp.toLowerCase() : temp
            filterData.listingId = localStorage.getItem('selectedListingId')
            filterData.messageFormatType = this.state.tabFrom === "sms" ? 'SMS' : "EMAIL"

            let variablesData = {
                "p_data":
                    filterData
            }
            client.query({ query: GET_MESSAGES_FILTER_DATA, variables: variablesData }).then((res) => {
                this.setState({ dataCount: res.data.get_message_filter_by_params.length })
                if (res && res.data && res.data.get_message_filter_by_params.length > 0) {
                    this.setState({
                        broadcastData: res.data.get_message_filter_by_params,
                        loading: false
                    })
                } else {

                    this.setState({ broadcastData: [], loading: false })
                }
            }).catch((err) => {
                this.setState({ loading: false })
            })
        })
    }

    setStateSelectedPage = (page) => {
        this.setState({ pageValue: page })
    }

    // setStateSelectedPage = (page) => {
    //     console.log('page123', page)
    //     this.setState({ pageValue: page })
    //   }
    render() {
        const { loading } = this.state

        return (
            <div>
                {/* {
                    loading === true ?
                        <CircularProgress /> : */}
                <div className="gx-d-flex justify-content-center">
                    <List
                        listData={this.state.broadcastData}
                        screenName="notifyScreen"
                        broadcastTab={this.state.tabFrom}
                        fromtrans={this.state.tabFrom === "sms" ? 'SMS' : "EMAIL"}
                        callNext={this.asignNextLimit.bind(this)}
                        filterScreenName={this.state.tabFrom === "sms" ? 'message_sms' : "message_email"}
                        onFilterDataList={this.onFilterDataList}
                        loadingValue={this.state.loading}
                        receivedScreen={this.state.screenFlag}
                        selectedFilterData={this.state.selectedFilterData}
                        totalCount={this.state.dataCount}
                        pageSelected={this.state.pageValue}
                        selectedPage={this.setStateSelectedPage}
                        selectedFilterName={this.state.selectedFilterName}
                    // pageSelected={this.state.pageValue}
                    // selectedPage={this.setStateSelectedPage}
                    />
                </div>
                {/* } */}
            </div>
        );
    }

};

export default NotifyScreen;
