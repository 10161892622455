import React, { Component } from 'react'
import Avatar from 'react-avatar'
import { Icon } from 'antd';
import { Row, Col, InputNumber, Typography } from 'antd'
import _ from 'lodash'

class LineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    }
  }

  formatValue = (value) => {
    if (value) {
      return parseInt(value)
    }
  }

  handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  renderPriceWithDiscountPercent(percentVal, actualPrice) {
    const { Title, Text } = Typography;

    let percentage = parseFloat(percentVal);
    let afterCalc = parseFloat(actualPrice) * (percentage / 100);
    let finalPrice = parseFloat(actualPrice) - parseFloat(afterCalc)
    return (<Text>{parseFloat(finalPrice).toFixed(2)}</Text>)

  }

  render = () => {
    const { categoryIndex, productIndex, name, description, quantities, defaultPriceUnit, image, productImageView, catalogDiscount } = this.props;
    const { Title, Text } = Typography;
    const quantityChildren =
      //  quantities && quantities.product_config && quantities.product_config.map((item, i) => {
      //   let price = item.price ? parseFloat(item.price) : 0.00
      //   if (!item.selected_quantity) {
      //     item.selected_quantity = 0;
      //   }
      // return (
      <div>
        {quantities.product_list_price !== 0.00 &&
          <Row>
            <Col xs={24} md={24} lg={24} className={productImageView ? "quantity-details-view" : "quantity-image-view"}
              style={{ marginBottom: '10px', marginTop: 5 }}>
              <div className="quantity-price-view">
                {/* <Text>{item.name}  </Text> */}
                <Text>{defaultPriceUnit} </Text>
                {(quantities.product_settings && quantities.product_settings.discount_price &&
                  quantities.product_settings.discount_price !== '') ?
                  <>
                    <Text className={`gx-subject gx-text-muted gx-text-strikethrough`}> {quantities.product_list_price.toFixed(2)} </Text>
                    <Text> {parseFloat(quantities.product_settings.discount_price).toFixed(2)} </Text>
                  </>
                  :
                  (quantities.product_category &&
                    quantities.product_category.category_discount) ?
                    <>
                      <Text className={`gx-subject gx-text-muted gx-text-strikethrough`}> {parseFloat(quantities.product_list_price).toFixed(2)} </Text>
                      {this.renderPriceWithDiscountPercent(quantities.product_category.category_discount, quantities.product_list_price)}
                    </>
                    :
                    (catalogDiscount && catalogDiscount !== '') ?
                      <>
                        <Text className={`gx-subject gx-text-muted gx-text-strikethrough`}> {parseFloat(quantities.product_list_price).toFixed(2)} </Text>
                        {this.renderPriceWithDiscountPercent(catalogDiscount, quantities.product_list_price)}
                      </> :
                      <Text> {quantities.product_list_price.toFixed(2)} </Text>
                }
              </div>
              {/* <div id="product-input-view">
                <InputNumber
                  value={(quantities.selected_quantity)}
                  style={{ marginRight: '10px', width: '50%' }}
                  name="quantity"
                  step="1"
                  formatter={value => this.formatValue(value)}
                  onChange={(value) => this.props.changeHandler(quantities, 0, value ? parseInt(value) : value)}
                  type='number'
                  min={0}
                />
              </div> */}
            </Col>
          </Row>
        }
      </div>
    // )
    // });

    return (
      <Col xs={24} sm={12} md={24} lg={24} style={{ padding: 0 }} id="form-title-container"
        style={{ cursor: "pointer" }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ display: 'flex', width: '100%' }}>
            {productImageView &&
              <div>
                {(image && image[0] && image[0].url) ?
                  <img style={{ width: 70, height: 70, marginRight: 10 }}
                    //  onClick={this.handleShowDialog} 
                    src={image[0].url} />
                  :
                  <Avatar
                    style={{ marginRight: 10, color: 'white', }}
                    size={'70px'}
                    className="avatarListingName"
                    fgColor="#FF0000" name={name} round={false} maxInitials={2} />
                }
                {this.state.isOpen && (
                  <dialog
                    className="dialog"
                    style={{ zIndex: 9999 }}
                    open
                  // onClick={this.handleShowDialog}
                  >
                    <Icon type="close" size={20} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}
                    //  onClick={() => this.handleShowDialog} 
                    ></Icon>
                    <img
                      className="image"
                      src={image[0].url}
                    // onClick={this.handleShowDialog}
                    />
                  </dialog>
                )}
              </div>
            }
            <div style={{ width: '100%', paddingLeft: 12 }}>
              <Title level={4} id="title-view" style={{ fontSize: 15, fontWeight: '500', color: 'black' }}>{name}</Title>

              <div className="product-desc-text" style={{ fontSize: 13, fontWeight: '400', color: 'grey' }}>
                {(description && description != '' && description != 'null') &&
                  <>
                    <Text>{description}</Text>
                    {/* <br /><br /> */}
                  </>
                }
              </div>
              <div className="sample">
                {quantityChildren}
              </div>
            </div>
          </div>

        </div>
      </Col>
    )
  }
}

export default LineItem
