import React, { Component } from "react";
import PhoneInput from 'react-phone-input-2'
import { storage } from '../../firebase/firebase';

import 'react-phone-input-2/lib/style.css'

import { Input, Typography, Row, Col, Divider, DatePicker, Form, Upload, Button, message, Icon, Tooltip } from 'antd';
import moment from 'moment'
import _ from 'lodash'
import { cloneDeep } from 'lodash'
import { parsePhoneNumberFromString } from 'libphonenumber-js'


let emptyData = "-"

const { TextArea } = Input;

class UserForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            transactionFormData: {},
            customerDetailFields: [],
            isDataReadOnly: false,
            phoneValue: '',
            orderedData: {},
            customerDataLength: 0
        }
        if (props.getUserValue) {
            props.getUserValue(this.getUserFormValues.bind(this));
        }
    }


    componentDidMount() {
        this.setFormData(this.props)
        this.setFieldsList(this.props)
    }

    componentWillReceiveProps(newProps) {
        if (this.props.data !== newProps.data) {
            this.setFormData(newProps)
        }
        if (this.props.templateSettingsData !== newProps.templateSettingsData) {
            this.setFieldsList(newProps)
        }
    }

    //template fields
    setFieldsList = (propsValue) => {
        if (propsValue && propsValue.templateSettingsData && propsValue.templateSettingsData.customerDetails.length > 0) {
            let customerDetails = propsValue.templateSettingsData.customerDetails;

            customerDetails.map((item, index) => {
                if (item.attributeName.replace(/ /g, '').toLowerCase() === 'customername'
                    || item.attributeName.replace(/ /g, '').toLowerCase() === 'name'
                    || item.attributeName.replace(/ /g, '').toLowerCase() === 'username'
                    || item.attributeName.replace(/ /g, '').toLowerCase() === 'firstname') {
                    if (this.props.contactsDetails) {
                        if (item.attributeValue === '' && this.props.contactsDetails[0]) {
                            item.attributeValue =
                                this.props.contactsDetails[0].firstName + ' ' + this.props.contactsDetails[0].lastName;
                        }
                    } else {
                        item.attributeValue = '';
                    }
                }
                if (item.attributeName.replace(/ /g, '').toLowerCase() === 'mail'
                    || item.attributeName.replace(/ /g, '').toLowerCase() === 'email'
                    || item.attributeName.replace(/ /g, '').toLowerCase() === 'customermail'
                    || item.attributeName.replace(/ /g, '').toLowerCase() === 'customeremail') {
                    if (this.props.contactsDetails) {
                        if (item.attributeValue === '' && this.props.contactsDetails[0]) {
                            item.attributeValue = this.props.contactsDetails[0].email;
                        }
                    } else {
                        item.attributeValue = '';
                    }
                }
                if (item.attributeName.replace(/ /g, '').toLowerCase() === 'phone'
                    || item.attributeName.replace(/ /g, '').toLowerCase() === 'phonenumber'
                    || item.attributeName.replace(/ /g, '').toLowerCase() === 'contactnumber'
                    || item.attributeName.replace(/ /g, '').toLowerCase() === 'mobilenumber') {
                    if (this.props.contactsDetails) {
                        if ((item.attributeValue === '' || item.attributeValue === '+91') && this.props.contactsDetails[0]) {
                            item.attributeValue = this.props.contactsDetails[0].phoneNumber;
                        }
                    } else {
                        item.attributeValue = '';
                    }
                }
            });
            this.setState({ customerDetailFields: customerDetails })
        }
        else {
            this.setState({ customerDetailFields: [] })
        }
    }

    //short url data
    setFormData = (propsValue) => {
        if (propsValue && Object.keys(propsValue.data).length > 0) {
            this.setState({ isDataReadOnly: true }, () => {

                if (propsValue.data && propsValue.data.formData && propsValue.data.formData.UserForm) {
                    this.setState({
                        transactionFormData: propsValue.data.formData.UserForm,
                        orderedData: propsValue.data.formData
                    }, () => {
                        // this.setCustomerDetails()
                        let temp = 0
                        Object.keys(this.state.transactionFormData).map((key) => {
                            let removeSpace = key.replace(' ', '')
                            let lowerCase = removeSpace.toLowerCase()
                            if (lowerCase && lowerCase !== 'name' && lowerCase !== 'phonenumber' && lowerCase !== 'email') {
                                temp = temp + 1
                            }
                        })
                        this.setState({ customerDataLength: temp })
                    })
                }
                else {
                    this.setState({ transactionFormData: {}, orderedData: {} })
                }
            })
        }

    }

    //replace transaction form data with field attribut value
    setCustomerDetails = () => {

        if (this.state.transactionFormData && this.state.customerDetailFields.length > 0) {
            let list = this.state.transactionFormData
            let data = this.state.customerDetailFields
            for (var key in list) {
                if (list.hasOwnProperty(key)) {
                    data.find(v => v.attributeName === key).attributeValue = list[key];
                    // data.find(v => v.attributeName != key).attributeValue = "-";
                }
            }
            this.setState({ customerDetailFields: data })
        }
    }

    //get customer form details while submiting the form
    getUserFormValues = () => {
        // return this.props.form.validateFieldsAndScroll((err, values) => {
        //     if (!err) {
        //         return values
        //     } else {
        //         return "notvalid"
        //     }
        // })

        let newField = {}
        let data = _.find(this.state.customerDetailFields, function (item) {
            if (item && item.required) {
                return !item.attributeValue
            }
        });

        if (!data) {

            this.state.customerDetailFields.map((res, index) => {
                newField[res.attributeName] = res.attributeValue
            })
            let option = {
                userForm: newField,
                userFormFullData: this.state.customerDetailFields
            }

            return option
        } else {
            return null
        }
    }

    uploadFile = (info, indexValue) => {
        let data = cloneDeep(this.state.customerDetailFields)
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {

            const file = info.fileList[0].originFileObj;
            const fileUrl = `ecommerce/${file.name}`;
            const uploadTask = storage.ref(fileUrl).put(file)

            uploadTask.on(
                'state_changed',
                snapshot => {
                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                        // message.success(`${info.file.name} file uploaded successfully`);
                        if (downloadURL) {
                            let value = []
                            value.push(downloadURL)
                            data[indexValue].attributeValue = value
                            this.setState({ customerDetailFields: data }, () => {
                            })
                        }
                    });
                    // progress function
                },
                error => {

                    // this.setState({ showLoading: true })
                    //error function
                })
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    removeFile = (info, indexValue) => {
        let data = cloneDeep(this.state.customerDetailFields)

        data[indexValue].attributeValue = []
        this.setState({ customerDetailFields: data }, () => {
        })
    }

    onChangeValues(event, index) {
        let data = cloneDeep(this.state.customerDetailFields)
        data[index].attributeValue = event.target.value
        this.setState({ customerDetailFields: data }, () => {
        });
    }
    isUrl(string) {
        var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        return regexp.test(string);
    }
    onOpenPreviousLink = (item) => {
        if (item) {
            window.open(`${item}`)
        }
    }


    locale = (string) => {

        if (this.props.templateSettings.LocalizationDetailValues &&
            this.props.templateSettings.LocalizationDetailValues.length > 0 &&
            this.props.templateSettings.LocalizationDetailValues.find(item => item.DefaultValue === string)) {
            return this.props.templateSettings.LocalizationDetailValues.find(item => item.DefaultValue === string).AlternateValue
        } else {
            return string
        }
    }

    render() {

        const { Text, Title } = Typography
        const { templateSettings } = this.props;
        const { customerDetailFields, transactionFormData, isDataReadOnly } = this.state
        const { getFieldDecorator } = this.props.form
        let formData = (Object.keys(transactionFormData).length > 0) ? true : false
        console.log(customerDetailFields, 'bval')
        return (
            <div style={{ marginBottom: '2%' }}>


                {isDataReadOnly === false ?
                    <div>
                        <Divider />
                        <Title level={4} className="pubilc-url-title">
                            {(templateSettings.LocalizationDetailValues &&
                                templateSettings.LocalizationDetailValues.length > 0 &&
                                templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Customer details') ?
                                templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Customer details').AlternateValue : 'Customer Details'
                            )}
                        </Title>
                        <Form>
                            {customerDetailFields && customerDetailFields.map((item, index) => {
                                if (item && item.attributeName && item.showAttributeName === true) {
                                    let name = _.startCase(item.attributeName)
                                    return (
                                        <Form.Item
                                            className="customer-details-field personal-inform-view"
                                            label={name}
                                            labelCol={{ xs: 7, sm: 7, md: 8, lg: 12 }}
                                            wrapperCol={{ xs: 17, sm: 17, md: 16, lg: 12 }}
                                        >
                                            {getFieldDecorator(`${item.attributeName}`, {
                                                initialValue: item.attributeValue,
                                                rules: [
                                                    {
                                                        required: item.required,
                                                        message: 'Please input your' + " " + `${item.attributeName}`,
                                                    },
                                                ],
                                            })(
                                                <div>
                                                    {/* {
                                                    item.attributeValue ?
                                                        <div>
                                                            {
                                                                item.attributeType === "Input" &&
                                                                <Input
                                                                    style={{ color: '#535b63' }}
                                                                    value={item.attributeValue}
                                                                    disabled={item.attributeValue ? true : false} />
                                                            }
                                                            {
                                                                item.attributeType === "TextArea" &&
                                                                <TextArea
                                                                    style={{ color: '#535b63' }}
                                                                    rows={4}
                                                                    value={item.attributeValue}
                                                                    disabled={item.attributeValue ? true : false} />
                                                            }
                                                        </div> : */}
                                                    <div>
                                                        {
                                                            (item.attributeType === "Input" ||
                                                                item.attributeType === "customerName" ||
                                                                item.attributeType === "customerEmail") &&
                                                            <Input
                                                                onChange={(event) => this.onChangeValues(event, index)}
                                                                value={item.attributeValue}
                                                            />
                                                        }
                                                        {
                                                            (item.attributeType === "TextArea" ||
                                                                item.attributeType === "AddressField") &&
                                                            <TextArea
                                                                onChange={(event) => this.onChangeValues(event, index)}
                                                                rows={4}
                                                                value={item.attributeValue}
                                                            />
                                                        }

                                                    </div>
                                                    {/* } */}
                                                    {
                                                        item.attributeType === "PhoneNumber" &&
                                                        <PhoneInput
                                                            country={this.props.formListingCountry}
                                                            enableAreaCodes={true}
                                                            enableSearch={true}
                                                            autoFormat={false}
                                                            onFocus={(e) => {
                                                                console.log(e.target.value, 'bfocus')
                                                                let data = cloneDeep(this.state.customerDetailFields)
                                                                data[index].attributeValue = e.target.value;
                                                                this.setState({ customerDetailFields: data })
                                                            }
                                                            }
                                                            value={item.attributeValue}
                                                            // inputStyle={{ width: '100%' }}
                                                            onChange={(val) => {
                                                                console.log(val, 'bvalue')
                                                                let data = cloneDeep(this.state.customerDetailFields)
                                                                data[index].attributeValue = val;
                                                                this.setState({ customerDetailFields: data })
                                                                // data[index].attributeValue = val;
                                                                // this.setState({ customerDetailFields: this.state.customerDetailFields })
                                                            }
                                                            }
                                                        />
                                                    }
                                                    {
                                                        item.attributeType === "FileUpload" &&
                                                        <Upload
                                                            // name='file'
                                                            action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                                            listType='picture'
                                                            // onChange={this.uploadFile.bind(this)}
                                                            onChange={(data) => this.uploadFile(data, index)}
                                                            multiple={false}
                                                            onRemove={(data) => this.removeFile(data, index)}
                                                        >
                                                            {
                                                                item.attributeValue.length === 0 &&
                                                                <Button>
                                                                    Upload
                                                                </Button>
                                                            }

                                                        </Upload>
                                                    }
                                                </div>
                                            )}
                                        </Form.Item>
                                    )
                                }

                            })
                            }
                        </Form>
                    </div>
                    :
                    <div className="customer-detail-container final-order-user-details" id="customer-info-detail">
                        {this.state.customerDataLength > 0 &&
                            <div>
                                <Divider />
                                <Title level={4} className="pubilc-url-title">
                                    {(templateSettings.LocalizationDetailValues &&
                                        templateSettings.LocalizationDetailValues.length > 0 &&
                                        templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Customer details') ?
                                        templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Customer details').AlternateValue : 'Customer Details'
                                    )}
                                </Title>
                            </div>
                        }
                        {transactionFormData && Object.keys(transactionFormData).map((key) => {
                            let name = _.startCase(key)

                            let removeSpace = key.replace(' ', '')
                            let lowerCase = removeSpace.toLowerCase()
                            if (lowerCase && lowerCase !== 'name' && lowerCase !== 'phonenumber' && lowerCase !== 'email') {
                                return (
                                    <Form.Item
                                        className="customer-details-field"
                                        label={name}
                                        labelCol={{ xs: 24, sm: 24, md: 12, lg: 12 }}
                                        wrapperCol={{ xs: 24, sm: 24, md: 12, lg: 12 }}
                                    >
                                        {getFieldDecorator(`${key}`, {})(
                                            <div>
                                                <div >
                                                    {this.isUrl(transactionFormData[key]) ?
                                                        <Tooltip title="Click to open">
                                                            <Icon id="icon-view" type="file" style={{ marginLeft: '10px' }}
                                                                onClick={() => this.onOpenPreviousLink(transactionFormData[key])}
                                                            />
                                                        </Tooltip>
                                                        :
                                                        // <Text>{transactionFormData[key] ? transactionFormData[key]:"-"}</Text>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: transactionFormData[key] ? `${transactionFormData[key].replace(/\r?\n/g, '')}` : emptyData
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        )
                                        }
                                    </Form.Item>
                                )
                            }
                        })
                        }
                        {
                            this.state.orderedData && this.state.orderedData.loggedinPhoneNumber &&
                            <Form.Item
                                className="customer-details-field"
                                label={this.locale('Order Placed From')}
                                labelCol={{ xs: 24, sm: 24, md: 12, lg: 12 }}
                                wrapperCol={{ xs: 24, sm: 24, md: 12, lg: 12 }}
                            >
                                {getFieldDecorator(`phone`, {})(
                                    <div>
                                        {this.state.orderedData.loggedinPhoneNumber}
                                    </div>

                                )
                                }
                            </Form.Item>
                        }
                    </div>
                }
            </div>
        )
    }
}

const WrappedApp = Form.create()(UserForm);
export default WrappedApp;

