import React, { Component } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { storage } from '../../firebase/firebase';

import {
    Input, Typography, Row, Col, Divider, DatePicker, Form, Upload, Button,
    Radio, TimePicker, message, Tooltip, Icon, Select, Modal
} from 'antd';
import moment from 'moment'
import _ from 'lodash'
import { cloneDeep } from "lodash";
import * as  date from '../../util/DateFormat'
import { object } from "prop-types";


let emptyData = "-"

const { TextArea } = Input;
const { Option } = Select;
class DeliveryDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            transactionFormData: {},
            orderDetailFields: {},
            selectedDeliveryOption: "",
            deliveryOption: "",
            deliveryDate: "",
            deliveryTime: "",
            isDataReadOnly: false,
            editedBySeller: false,
            selectedOption: [],
            messageModalEnable: false,
            deilveryModalMessage: false,
            workHoursDetails: [],
            HoursofselectedDay: []
        }
        if (props.getDeliveryDetail) {
            props.getDeliveryDetail(this.getDeliveryDetailValues.bind(this));
        }
        if (props.getDeliveryDetail) {
            props.getSelectedDelivery(this.getSelectedDeliveryOption.bind(this));
        }
        if (props.getDeliveryDetail) {
            props.getMinorderVal(this.getMinorderValue.bind(this));
        }
    }


    componentDidMount() {
        this.setFormData(this.props)
        this.setFieldsList(this.props)
    }

    componentWillReceiveProps(newProps) {
        if (this.props.data !== newProps.data) {
            this.setFormData(newProps)
        }
        if (this.props.templateSettingsData !== newProps.templateSettingsData) {
            this.setFieldsList(newProps)
        }
    }



    setWorkinghoursFlow = (props) => {
        let detail = [];
        let storedValue = [];
        if (props && props.templateSettingsData && props.templateSettingsData.DeliveryPickupDetailValues) {
            if (this.state.deliveryOption === 'Delivery') {
                detail = props.templateSettingsData.DeliveryPickupDetailValues.DeliveryDetails;
                storedValue = this.state.orderDetailFields.DeliveryDetails;
            } else if (this.state.deliveryOption === 'Pickup') {
                detail = props.templateSettingsData.DeliveryPickupDetailValues.PickUpDetails;
                storedValue = this.state.orderDetailFields.PickUpDetails;
            } else if (this.state.deliveryOption === 'Seating') {
                detail = props.templateSettingsData.DeliveryPickupDetailValues.seatingDetails;
                storedValue = this.state.orderDetailFields.seatingDetails;
            }

            if (detail.find(v => v.attributeType === "Workinghours") && detail.find(v => v.attributeType === "Orderahead")) {
                let workinghoursdetails = detail.filter((item) => {
                    return item.attributeType === "Workinghours"
                });
                let commaSeperated = [];
                let workhour_data = [];
                let i = 0;
                let orderaheadVal = detail.find(v => v.attributeType === "Orderahead");
                //find order head start day value
                let orderaheadStartDayVal = detail.find(v => v.attributeType === "OrderaheadStartDay");
                let requestdateIndex = detail.findIndex((obj => obj.attributeType == "RequestDate"));
                if (requestdateIndex && requestdateIndex >= 0) {
                    storedValue[requestdateIndex].attributeValue = '';
                }

                for (i = 0; i <= parseInt(orderaheadVal.attributeValue); i++) {
                    workinghoursdetails.map((wrkhr, index) => {
                        let slottedHours = [];
                        commaSeperated = wrkhr.attributeValue.split(',');
                        if (moment().day(wrkhr.attributeName).format('dddd') === moment().add(i, 'days').format('dddd')) {
                            if (wrkhr.attributeName !== 'holiday' &&
                                i < parseInt(orderaheadVal.attributeValue)) {

                                let calcDay
                                //if order head start day value is set, calc working day after order head start day value
                                if (orderaheadStartDayVal && parseInt(orderaheadStartDayVal.attributeValue)) {
                                    let orderValue = parseInt(orderaheadStartDayVal.attributeValue)

                                    let day = moment().add(i + orderValue, 'days').format('dddd')
                                    day = day.toLowerCase()
                                    //find out calculated day is present in working hours list
                                    let data = _.find(workinghoursdetails, (res) => {
                                        if (res && res.attributeName && res.attributeName.toLowerCase() === day) {
                                            return res
                                        }
                                    })

                                    //exclude holiday day
                                    if (data) {
                                        if (data.attributeValue === "holiday") {
                                            calcDay = ""
                                            slottedHours = []
                                        } else {
                                            commaSeperated = data.attributeValue.split(',');
                                            calcDay = moment().add(i + orderValue, 'days').format('ddd, MMM Do');
                                            slottedHours = this.Timeintervalslots(commaSeperated, i + orderValue, wrkhr);
                                        }
                                    } else {
                                        calcDay = ""
                                        slottedHours = []
                                    }
                                }
                                //otherwise cal working day from current day
                                else {
                                    slottedHours = this.Timeintervalslots(commaSeperated, i, wrkhr);
                                    calcDay = moment().add(i, 'days').format('ddd, MMM Do');
                                }
                                if (calcDay) {
                                    let workdayString = calcDay;
                                    let workdayStringSplitted = workdayString.split(',');
                                    let localizedworkday = this.locale(workdayStringSplitted[0]) + "," + workdayStringSplitted[1];
                                    workhour_data.push({ 'workDay': localizedworkday, 'workHours': slottedHours });
                                }
                            }
                        }
                    });
                }

                this.setState({ workHoursDetails: workhour_data }, () => {

                })

                this.setState({ isWorkingHours: true });
            } else {
                this.setState({ isWorkingHours: false });
            }
        }
    }



    Timeintervalslots = (commaSeperated, i, wrkhr) => {
        let colanSeperated = [];
        var result = [];
        commaSeperated.map((comaval) => {
            colanSeperated = comaval.split(':');
            var start = moment(colanSeperated[0], 'HH:mm');
            var end = moment(colanSeperated[1], 'HH:mm');

            // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
            // note that 59 will round up to 60, and moment.js handles that correctly
            start.minutes(Math.ceil(start.minutes() / 15) * 15);
            var current = moment(start);
            // console.log(moment().add(i, 'days'), moment(), moment().add(i, 'days') > moment(), 'memthh');
            while (current <= end) {
                //check if today is lesser than working days

                if (!(moment().add(i, 'days') > moment())) {
                    if (moment().add(i, 'minutes').format('HH:mm') < current.format('HH:mm')) {
                        result.push(current.format('hh:mm') + ' - ' + this.localizedcurrent(current));
                    } else {
                        current.add(15, 'minutes');
                        continue;
                    }
                } else {
                    result.push(current.format('hh:mm') + ' - ' + this.localizedcurrent(current));
                }

                if (current.format('hh:mm A') === end.format('hh:mm A')) break;
            }
        });

        return result;
    }

    localizedcurrent = (current) => {
        let currentFormatted = current.add(15, 'minutes').format('hh:mm A')
        let currentStringSplitted = currentFormatted.split(' ');
        let localizedCurrent = currentStringSplitted[0] + " " + this.locale(currentStringSplitted[1]);
        return localizedCurrent;
    }



    //template fields
    setFieldsList = (propsValue) => {
        if (propsValue && propsValue.templateSettingsData && propsValue.templateSettingsData.DeliveryPickupDetailValues) {
            this.setState({ orderDetailFields: propsValue.templateSettingsData.DeliveryPickupDetailValues })
            let details = propsValue.templateSettingsData.DeliveryPickupDetailValues
            if (details.selectedOption) {
                if (details.selectedOption === "Both" ||
                    details.selectedOption === "All" ||
                    details.selectedOption === "DeliverySeating" ||
                    details.selectedOption === "PickupSeating") {
                    this.setState({ selectedDeliveryOption: details.selectedOption, deliveryOption: "Delivery" }, () => this.setWorkinghoursFlow(propsValue)
                    )
                } else {
                    this.setState({ selectedDeliveryOption: details.selectedOption, deliveryOption: details.selectedOption }, () => this.setWorkinghoursFlow(propsValue))
                }

            }
        }
        else {
            // this.setState({ orderDetailFields: {} })
        }

    }

    //short url data
    setFormData = (propsValue) => {
        if (propsValue && Object.keys(propsValue.data).length > 0) {
            this.setState({ isDataReadOnly: true }, () => {
                if (propsValue.data.formData.editedBySeller) {
                    if (propsValue.data && propsValue.data.formData.editedBySellerValues) {
                        let editedDeliceryPickup = {};
                        editedDeliceryPickup = propsValue.data.formData ? propsValue.data.formData.DeliveryDetails ? propsValue.data.formData.DeliveryDetails : {} : {};
                        if (editedDeliceryPickup.selectedOption) {
                            if (editedDeliceryPickup.selectedOption === 'Delivery') {
                                if (propsValue.data.formData.editedBySellerValues.EditedDeliveryDetails && propsValue.data.formData.editedBySellerValues.EditedDeliveryDetails.length > 0) {

                                    this.setState({ editedBySeller: true }, () => { });
                                    editedDeliceryPickup.detail = propsValue.data.formData.editedBySellerValues.EditedDeliveryDetails;
                                }
                            } else if (editedDeliceryPickup.selectedOption === 'Seating') {
                                if (propsValue.data.formData.editedBySellerValues.EditedSeatingDetails && propsValue.data.formData.editedBySellerValues.EditedSeatingDetails.length > 0) {

                                    this.setState({ editedBySeller: true }, () => { });
                                    editedDeliceryPickup.detail = propsValue.data.formData.editedBySellerValues.EditedSeatingDetails;
                                }
                            } else {
                                if (propsValue.data.formData.editedBySellerValues.EditedPickupDetails && propsValue.data.formData.editedBySellerValues.EditedPickupDetails.length > 0) {
                                    editedDeliceryPickup.detail = propsValue.data.formData.editedBySellerValues.EditedPickupDetails;
                                    this.setState({ editedBySeller: true });
                                }
                            }
                            let newField = {};
                            //make attribute name, values as key value pair 
                            if (editedDeliceryPickup.detail && editedDeliceryPickup.detail.length > 0) {
                                editedDeliceryPickup.detail.map((res, index) => {
                                    newField[res.attributeName] = res.attributeValue
                                });
                                editedDeliceryPickup.detail = newField;
                            }
                            this.setState({ transactionFormData: editedDeliceryPickup }, () => {
                                // this.setCustomerDetails()
                            })
                        } else {
                            this.setState({ transactionFormData: {} })
                        }
                    }
                    else {
                        this.setState({ transactionFormData: {} })
                    }
                } else {
                    if (propsValue.data && propsValue.data.formData && propsValue.data.formData.DeliveryDetails) {
                        this.setState({ transactionFormData: propsValue.data.formData.DeliveryDetails }, () => {
                            // this.setCustomerDetails()
                        })
                    }
                    else {
                        this.setState({ transactionFormData: {} })
                    }
                }

            })
        }

    }

    //replace transaction form data with field attribut value
    setCustomerDetails = () => {
        if (this.state.transactionFormData && this.state.transactionFormData.detail && this.state.orderDetailFields) {

            let fieldData = ""
            let option = ""
            if (this.state.transactionFormData.selectedOption) {
                fieldData = this.state.transactionFormData.selectedOption === "Delivery" ?
                    this.state.orderDetailFields.DeliveryDetails :
                    this.state.transactionFormData.selectedOption === "Seating" ?
                        this.state.orderDetailFields.seatingDetails :
                        this.state.orderDetailFields.PickUpDetails
            }
            let list = this.state.transactionFormData.detail
            let data = fieldData
            for (var key in list) {
                if (list.hasOwnProperty(key)) {
                    let findValue = data.find(v => v.attributeName == key)
                    if (findValue) {
                        data.find(v => v.attributeName == key).attributeValue = list[key];
                    }
                    if (key === "deliveryDate") {
                        this.setState({ deliveryDate: list.deliveryDate ? moment(list.deliveryDate).format('DD-MM-YYYY') : "" }, () => {
                        })
                    }
                    if (key === "deliveryTime") {
                        this.setState({ deliveryTime: list.deliveryTime ? list.deliveryTime : "" })
                    }

                }
            }



            let fieldsValues = cloneDeep(this.state.orderDetailFields)
            if (this.state.transactionFormData.selectedOption === "Delivery") {
                fieldsValues.DeliveryDetails = data
            } else if (this.state.transactionFormData.selectedOption === "Seating") {
                fieldsValues.seatingDetails = data
            } else {
                fieldsValues.PickUpDetails = data
            }
            this.setState({ orderDetailFields: fieldsValues, deliveryOption: this.state.transactionFormData.selectedOption })
        }
    }

    //get customer form details while submiting the form
    getDeliveryDetailValues = () => {
        var newField = {};
        var initValue = ''
        var Finaldata = (this.state.deliveryOption === "Delivery") ? this.state.orderDetailFields.DeliveryDetails :
            (this.state.deliveryOption === "Seating") ? this.state.orderDetailFields.seatingDetails :
                this.state.orderDetailFields.PickUpDetails
        let data = _.find(Finaldata, function (item) {
            if (item && item.required) {
                if ((item.attributeType === "Option") || (item.attributeType === "deliveryFee")
                    || (item.attributeType === "PaymentType")) {
                    return !item.duplicateAttributeValue;
                } else {
                    return !item.attributeValue;
                }
            }
        });
        // this.props.form.validateFields((err, values) => {
        //     console.log(values, 'valuesvaluesvalues');
        //     this.values = values;
        // })
        // console.log(this.values, 'valuesvaluesvalues');
        if (!data) {
            Finaldata.map((res, index) => {
                if (res.attributeType === "RequestDate" && res.attributeValue !== '') {
                    if (this.state.isWorkingHours === false) {
                        initValue = moment(res.attributeValue).format();
                    } else {
                        initValue = res.attributeValue;
                    }
                } else if (res.attributeType === "RequestTime" && res.attributeValue !== '') {
                    if (this.state.isWorkingHours === false) {
                        initValue = moment(res.attributeValue).format("h:mm A");
                    } else {
                        initValue = res.attributeValue;
                    }
                } else {
                    if (res.attributeType !== "MinOrderValue" && res.attributeType !== "Orderahead"
                        && res.attributeType !== "Workinghours" && res.attributeType !== "OrderaheadStartDay") {
                        initValue = res.attributeValue;
                    }
                }

                if ((res.attributeType === "Option") || (res.attributeType === "deliveryFee")
                    || (res.attributeType === "PaymentType")) {
                    initValue = res.duplicateAttributeValue;
                }
                if (res.attributeType !== "MinOrderValue" && res.attributeType !== "Orderahead"
                    && res.attributeType !== "Workinghours" && res.attributeType !== "OrderaheadStartDay") {
                    newField[res.attributeName] = initValue
                }
            })
            let option = {
                deliveryDetailsForm: newField,
                deliveryDetailsFormFullData: Finaldata
            }
            return option
        } else {
            if (!this.props.screenFrom === 'listingDetail') {
                message.warning('Please fill required fields');
            }
            return null
        }
        //

    }

    getSelectedDeliveryOption = () => {
        return this.state.deliveryOption
    }

    getMinorderValue = () => {
        var Finaldata = (this.state.deliveryOption === "Delivery") ? this.state.orderDetailFields.DeliveryDetails :
            (this.state.deliveryOption === "Seating") ? this.state.orderDetailFields.seatingDetails :
                this.state.orderDetailFields.PickUpDetails
        let data = _.find(Finaldata, function (item) {
            if (item && item.required) {
                return !item.attributeValue
            }
        });

        if (!data) {
            let colanSplitted = [];
            Finaldata.map((res, index) => {
                if (res.attributeType === "MinOrderValue" && !Array.isArray(res.attributeValue) && res.attributeValue !== '') {
                    colanSplitted = res.attributeValue.split(':');
                }
            });
            return colanSplitted;
        }
    }

    fieldChanges = (e, option, index) => {
        if ((option[index].attributeType === 'Option') || (option[index].attributeType === 'deliveryFee') ||
            (option[index].attributeType === 'PaymentType')) {
            option[index].duplicateAttributeValue = e;
            let message = ((option[index].attributeType === 'Option' ||
                option[index].attributeType === 'PaymentType')) ? e[1] : e[2]
            this.setState({ selectedOption: e, messageModalEnable: true, deilveryModalMessage: message }, () => {
                if (option[index].attributeType === 'deliveryFee' && this.props.getDeliveryFee) {
                    this.props.getDeliveryFee(e)
                }
                if (this.props.showDeliveryMessageModal && this.state.deilveryModalMessage) {
                    this.props.showDeliveryMessageModal(false)
                }
            });
        } else {
            option[index].attributeValue = e.target.value;
        }
    }

    onChangeDate = (date, option, index) => {
        if (typeof date === 'number') {
            option[index].attributeValue = this.state.workHoursDetails[date].workDay;
            this.setState({ HoursofselectedDay: this.state.workHoursDetails[date].workHours });
        } else {
            option[index].attributeValue = date;
        }
        if (this.state.deliveryOption === 'Delivery') {
            this.state.orderDetailFields.DeliveryDetails = option;
        } else if (this.state.deliveryOption === 'Seating') {
            this.state.orderDetailFields.seatingDetails = option;
        } else {
            this.state.orderDetailFields.PickUpDetails = option;
        }
        this.setState({ orderDetailFields: this.state.orderDetailFields }, console.log(this.state.orderDetailFields, 'ordesstae'))
    }

    onChangeTime = (date, option, index) => {
        option[index].attributeValue = date;
        if (this.state.deliveryOption === 'Delivery') {
            this.state.orderDetailFields.DeliveryDetails = option;
        } else if (this.state.deliveryOption === 'Seating') {
            this.state.orderDetailFields.seatingDetails = option;
        } else {
            this.state.orderDetailFields.PickUpDetails = option;
        }
        this.setState({ orderDetailFields: this.state.orderDetailFields })
    }


    uploadFile = (info, indexValue) => {
        let data = this.state.deliveryOption === 'Delivery' ?
            cloneDeep(this.state.orderDetailFields.DeliveryDetails) : this.state.deliveryOption === 'Seating' ?
                cloneDeep(this.state.orderDetailFields.seatingDetails) : cloneDeep(this.state.orderDetailFields.PickUpDetails);
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {

            const file = info.fileList[0].originFileObj;
            const fileUrl = `ecommerce/${file.name}`;
            const uploadTask = storage.ref(fileUrl).put(file)

            uploadTask.on(
                'state_changed',
                snapshot => {
                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                        // message.success(`${info.file.name} file uploaded successfully`);
                        if (downloadURL) {
                            let value = []
                            value.push(downloadURL)
                            data[indexValue].attributeValue = value
                            this.state.deliveryOption === 'Delivery' ? this.state.orderDetailFields.DeliveryDetails = data :
                                this.state.deliveryOption === 'Seating' ? this.state.orderDetailFields.seatingDetails = data :
                                    this.state.orderDetailFields.PickUpDetails = data
                            this.setState({ orderDetailFields: this.state.orderDetailFields }, () => {
                            })
                        }
                    });
                    // progress function
                },
                error => {

                    // this.setState({ showLoading: true })
                    //error function
                })
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    removeFile = (info, indexValue) => {
        let data = this.state.deliveryOption === 'Delivery' ?
            cloneDeep(this.state.orderDetailFields.DeliveryDetails) : this.state.deliveryOption === 'Seating' ?
                cloneDeep(this.state.orderDetailFields.seatingDetails) : cloneDeep(this.state.orderDetailFields.PickUpDetails);

        data[indexValue].attributeValue = [];
        this.state.deliveryOption === 'Delivery' ? this.state.orderDetailFields.DeliveryDetails = data :
            this.state.deliveryOption === 'Seating' ? this.state.orderDetailFields.seatingDetails = data :
                this.state.orderDetailFields.PickUpDetails = data;
        this.setState({ orderDetailFields: this.state.orderDetailFields }, () => {
        })
    }

    isUrl(string) {
        var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        return regexp.test(string);
    }
    onOpenPreviousLink = (item) => {
        if (item) {
            window.open(`${item}`)
        }
    }

    isDate(sDate) {
        let splitValue = _.split(sDate, '-')
        if (splitValue && splitValue.length > 1) {
            if (typeof sDate === "object") {
                return false
            } else {
                if (sDate.toString() === parseInt(sDate).toString()) {
                    return false;
                } else {
                    var tryDate = new Date(sDate);
                    return (tryDate && tryDate.toString() != "NaN" && tryDate != "Invalid Date");
                }
            }
        } else {
            return false
        }
    }

    onOkMessageModal = () => {
        this.setState({ messageModalEnable: false }, () => {
            if (this.props.showDeliveryMessageModal) {
                this.props.showDeliveryMessageModal(true)
            }
        })

    }

    onChangeDeliveryOption = (event) => {
        this.setState({ deliveryOption: event.target.value }, () => {
            if (this.props.getDeliveryFee) {
                this.props.getDeliveryFee("")
            }
            this.setWorkinghoursFlow(this.props)
        })
    }

    locale = (string) => {
        if (this.props.templateSettings.LocalizationDetailValues &&
            this.props.templateSettings.LocalizationDetailValues.length > 0 &&
            this.props.templateSettings.LocalizationDetailValues.find(item => item.DefaultValue === string)) {
            return this.props.templateSettings.LocalizationDetailValues.find(item => item.DefaultValue === string).AlternateValue
        } else {
            return string
        }
    }

    render() {

        const { Text, Title } = Typography
        const { orderDetailFields, transactionFormData, selectedDeliveryOption, deliveryOption,
            isDataReadOnly, isWorkingHours, workHoursDetails, HoursofselectedDay } = this.state
        const { templateSettings } = this.props;
        const { getFieldDecorator } = this.props.form
        let formData = (Object.keys(transactionFormData).length > 0) ? true : false
        let option = [];
        if (orderDetailFields && deliveryOption) {
            option = (deliveryOption === "Delivery") ? orderDetailFields.DeliveryDetails :
                (deliveryOption === "Seating") ? orderDetailFields.seatingDetails :
                    orderDetailFields.PickUpDetails
        }
        let deliveryLabel = 'Delivery';
        let pickupLabel = 'Pickup';
        let seatingLabel = 'Seating';
        if (templateSettings && templateSettings.LocalizationDetailValues &&
            templateSettings.LocalizationDetailValues.length > 0) {
            deliveryLabel = templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Delivery') ?
                templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Delivery').AlternateValue : 'Delivery';
            pickupLabel = templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Pick up') ?
                templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Pick up').AlternateValue : 'Pickup';
            seatingLabel = templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Seating') ?
                templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Seating').AlternateValue : 'Seating';

        }
        return (
            <div id="delivery-modal-container" style={{ marginTop: '0%', paddingLeft: '5%', paddingRight: '5%' }}>
                <Divider />
                {!isDataReadOnly && selectedDeliveryOption &&
                    <Title level={4} className="pubilc-url-title">
                        {(templateSettings.LocalizationDetailValues &&
                            templateSettings.LocalizationDetailValues.length > 0 &&
                            templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Delivery details') ?
                            templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Delivery details').AlternateValue : 'Delivery Details')}
                    </Title>
                }
                <Form>

                    <div id="delivery-seating-view" style={{ marginBottom: '2%' }}>
                        {
                            selectedDeliveryOption === "Both" && !formData &&
                            <Radio.Group buttonStyle="solid"
                                onChange={(event) => this.onChangeDeliveryOption(event)}
                                // onChange={(event) => this.setState({ deliveryOption: event.target.value })}
                                value={this.state.deliveryOption}>
                                <Radio.Button value="Delivery">{deliveryLabel}</Radio.Button>
                                <Radio.Button value="Pickup">{pickupLabel}</Radio.Button>
                            </Radio.Group>
                        }
                        {
                            selectedDeliveryOption === "All" && !formData &&
                            <Radio.Group buttonStyle="solid"
                                onChange={(event) => this.onChangeDeliveryOption(event)} 
                                // onChange={(event) => this.setState({ deliveryOption: event.target.value })}
                                value={this.state.deliveryOption}>
                                <Radio.Button value="Seating">{seatingLabel}</Radio.Button>
                                <Radio.Button value="Delivery">{deliveryLabel}</Radio.Button>
                                <Radio.Button value="Pickup">{pickupLabel}</Radio.Button>

                            </Radio.Group>
                        }
                        {
                            selectedDeliveryOption === "DeliverySeating" && !formData &&
                            <Radio.Group buttonStyle="solid"
                                onChange={(event) => this.onChangeDeliveryOption(event)}
                                // onChange={(event) => this.setState({ deliveryOption: event.target.value })}
                                value={this.state.deliveryOption}>
                                <Radio.Button value="Seating">{seatingLabel}</Radio.Button>
                                <Radio.Button value="Delivery">{deliveryLabel}</Radio.Button>
                            </Radio.Group>
                        }
                        {
                            selectedDeliveryOption === "PickupSeating" && !formData &&
                            <Radio.Group buttonStyle="solid"
                                onChange={(event) => this.onChangeDeliveryOption(event)}
                                // onChange={(event) => this.setState({ deliveryOption: event.target.value })}
                                value={this.state.deliveryOption}>
                                <Radio.Button value="Seating">{seatingLabel}</Radio.Button>
                                <Radio.Button value="Pickup">{pickupLabel}</Radio.Button>
                            </Radio.Group>
                        }
                        {
                            selectedDeliveryOption === "Delivery" && !formData &&
                            <Radio.Group buttonStyle="solid"
                                onChange={(event) => this.onChangeDeliveryOption(event)}
                                // onChange={(event) => this.setState({ deliveryOption: event.target.value })}
                                value={this.state.deliveryOption}>
                                <Radio.Button value="Delivery">{deliveryLabel}</Radio.Button>
                            </Radio.Group>
                        }
                        {
                            selectedDeliveryOption === "Pickup" && !formData &&
                            <Radio.Group buttonStyle="solid"
                                onChange={(event) => this.onChangeDeliveryOption(event)}
                                // onChange={(event) => this.setState({ deliveryOption: event.target.value })}
                                value={this.state.deliveryOption}>
                                <Radio.Button value="Pickup">{pickupLabel}</Radio.Button>
                            </Radio.Group>
                        }
                        {
                            selectedDeliveryOption === "Seating" && !formData &&
                            <Radio.Group buttonStyle="solid"
                                onChange={(event) => this.onChangeDeliveryOption(event)}
                                // onChange={(event) => this.setState({ deliveryOption: event.target.value })}
                                value={this.state.deliveryOption}>
                                <Radio.Button value="Seating">{seatingLabel}</Radio.Button>
                            </Radio.Group>
                        }
                    </div>
                    {/* <div>
                        {formData &&
                            <div id="delivery-type-view" style={{ display: 'flex', flexDirection: 'row' }}>
                                <p style={{ color: '#a19f9f' }}>{this.locale('Delivery option')}:</p>
                                <span> {(templateSettings && templateSettings.LocalizationDetailValues &&
                                    templateSettings.LocalizationDetailValues.length > 0) ?
                                    deliveryLabel = templateSettings.LocalizationDetailValues.find(item => item.FieldType === this.state.transactionFormData.selectedOption) ?
                                        templateSettings.LocalizationDetailValues.find(item => item.FieldType === this.state.transactionFormData.selectedOption).AlternateValue : this.state.transactionFormData.selectedOption
                                    : this.state.transactionFormData.selectedOption}
                                </span>
                            </div>
                        }
                    </div> */}
                    {/* {!isDataReadOnly &&
                        <div style={{ marginTop: 10 }}>
                            {
                                deliveryOption === "Delivery" &&
                                <Form.Item className="customer-details-field"
                                    label="Delivery Date"
                                    labelCol={{ xs: 7, sm: 7, md: 6, lg: 4 }}
                                    wrapperCol={{ xs: 16, sm: 16 }}
                                >
                                    {getFieldDecorator(`deliveryDate`, {
                                        initialValue: this.state.deliveryDate ? moment(this.state.deliveryDate).format() : "",
                                        rules: [
                                            {
                                                // required: true,
                                                message: 'Please input date',
                                            },
                                        ],
                                    })(
                                        <div>

                                            <DatePicker
                                                value={this.state.deliveryDate}
                                                format="DD-MM-YYYY"
                                                onChange={(time) => this.onChangeDate(time)}
                                                disabledDate={date.disabledDate}
                                            />

                                        </div>
                                    )}
                                </Form.Item>
                            }
                            {
                                deliveryOption === "Delivery" &&
                                <Form.Item className="customer-details-field"
                                    label="Delivery Time"
                                    labelCol={{ xs: 7, sm: 7, md: 6, lg: 4 }}
                                    wrapperCol={{ xs: 16, sm: 16 }}
                                >
                                    {getFieldDecorator(`deliveryTime`, {
                                        initialValue: this.state.deliveryTime ? moment(this.state.deliveryTime).format("h:mm A") : "",
                                        rules: [
                                            {
                                                // required: true,
                                                message: 'Please input your time',
                                            },
                                        ],
                                    })(
                                        <div>

                                            <TimePicker
                                                value={this.state.deliveryTime}
                                                use12Hours
                                                format='h:mm A'
                                                minuteStep={5}
                                                onChange={(time) => this.onChangeTime(time)}
                                                renderExtraFooter={() => (
                                                    <Button size="small" type="primary" onClick={this.handleClose}>
                                                        Ok
                                                    </Button>
                                                )}
                                            />

                                        </div>
                                    )}
                                </Form.Item>
                            }
                        </div>
                    } */}
                    {isDataReadOnly === false ?
                        <div>
                            {option && option.map((item, index) => {
                                if (item && item.attributeName && item.attributeType !== 'MinOrderValue' &&
                                    item.attributeType !== 'Workinghours' && item.attributeType !== 'Orderahead' &&
                                    item.attributeType !== 'OrderaheadStartDay') {
                                    let name = _.startCase(item.attributeName)
                                    let initValue = ''
                                    if (item.attributeType === "RequestDate" && item.attributeValue !== '') {
                                        initValue = moment(item.attributeValue).format();
                                    } else if (item.attributeType === "RequestTime" && item.attributeValue !== '') {
                                        initValue = moment(item.attributeValue).format("h:mm A");
                                    } else {
                                        initValue = item.attributeValue;
                                    }
                                    let commaSplitted = [];
                                    if ((item.attributeType === 'Option') ||
                                        (item.attributeType === "deliveryFee") ||
                                        (item.attributeType === "PaymentType")) {

                                        if (item.hasOwnProperty('attributeValue') && !Array.isArray(item.attributeValue) && item.duplicateAttributeValue !== '') {
                                            commaSplitted = item.attributeValue.split(',');
                                        }
                                    }

                                    return (
                                        <div id="delivery-container-view" key={index}>
                                            <Form.Item className="customer-details-field delivery-container"
                                                label={name}
                                                labelCol={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                                wrapperCol={{ xs: 12, sm: 12 }}
                                            >
                                                {getFieldDecorator(`${item.attributeName}`, {
                                                    initialValue: initValue,
                                                    rules: [
                                                        {
                                                            required: item.required,
                                                            message: 'Please input your' + " " + `${item.attributeName}`,
                                                        },
                                                    ],
                                                })(
                                                    <div>

                                                        <div>
                                                            {/* {
                                                                item.attributeValue ?
                                                                    <div>
                                                                        {
                                                                            item.attributeType === "Input" &&
                                                                            <Input
                                                                                style={{ color: '#535b63' }}
                                                                                value={item.attributeValue}
                                                                                disabled={item.attributeValue ? true : false} />
                                                                        }
                                                                        {
                                                                            item.attributeType === "TextArea" &&
                                                                            <TextArea
                                                                                style={{ color: '#535b63' }}
                                                                                rows={4}
                                                                                value={item.attributeValue}
                                                                                disabled={item.attributeValue ? true : false} />
                                                                        }
                                                                      
                                                                    </div> : */}
                                                            <div>
                                                                {
                                                                    item.attributeType === "Input" &&
                                                                    <Input
                                                                        onChange={(e) => this.fieldChanges(e, option, index)}
                                                                    />
                                                                }
                                                                {
                                                                    item.attributeType === "TextArea" &&
                                                                    <TextArea
                                                                        onChange={(e) => this.fieldChanges(e, option, index)}
                                                                        rows={4}
                                                                    />
                                                                }
                                                                {
                                                                    item.attributeType === "AddressField" &&
                                                                    <TextArea
                                                                        onChange={(e) => this.fieldChanges(e, option, index)}
                                                                        rows={4}
                                                                    />
                                                                }

                                                            </div>

                                                            {item.attributeType === "RequestDate" && isWorkingHours === false &&
                                                                <DatePicker
                                                                    // value={(item.attributeValue && item.attributeValue !== '') ? moment(item.attributeValue).isValid() ? item.attributeValue : moment().format('DD-MM-YYYY'): ''}
                                                                    format="DD-MM-YYYY"
                                                                    onChange={(time) => this.onChangeDate(time, option, index)}
                                                                    disabledDate={date.disabledDate}
                                                                />
                                                            }

                                                            {item.attributeType === "RequestDate" && isWorkingHours === true &&
                                                                <Select placeholder={item.attributeName}
                                                                    // value={item.attributeValue}
                                                                    onChange={(event) => this.onChangeDate(event, option, index)}
                                                                >
                                                                    {workHoursDetails && workHoursDetails.length > 0 && workHoursDetails.map((val, i) => {
                                                                        if (val.workHours[0] && val.workHours[0] !== 'holiday') {
                                                                            return (
                                                                                <Option value={i}>{this.locale(val.workDay)}</Option>
                                                                            )
                                                                        }
                                                                    })
                                                                    }
                                                                </Select>
                                                            }

                                                            {item.attributeType === "RequestTime" && isWorkingHours === false &&
                                                                <TimePicker
                                                                    // value={item.attributeValue && item.attributeValue !== '' ? moment(item.attributeValue).isValid() ? item.attributeValue : moment().format('h:mm A'): ''}
                                                                    use12Hours
                                                                    format='h:mm A'
                                                                    minuteStep={5}
                                                                    onChange={(time) => this.onChangeTime(time, option, index)}
                                                                    renderExtraFooter={() => (
                                                                        <Button size="small" type="primary" onClick={this.handleClose}>
                                                                            {this.locale('Ok')}
                                                                        </Button>
                                                                    )}
                                                                />
                                                            }
                                                            {item.attributeType === "RequestTime" && isWorkingHours === true &&
                                                                <Select placeholder={item.attributeName}
                                                                    // value={item.attributeValue !== '' && item.attributeValue}
                                                                    onChange={(event) => this.onChangeTime(event, option, index)}
                                                                >
                                                                    {HoursofselectedDay && HoursofselectedDay.length > 0 && HoursofselectedDay.map(val => {
                                                                        return (
                                                                            <Option value={this.locale(val)}>{val}</Option>
                                                                        )
                                                                    })
                                                                    }
                                                                </Select>
                                                            }
                                                            {
                                                                item.attributeType === "FileUpload" &&
                                                                <Upload
                                                                    // name='file'
                                                                    action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                                                    listType='picture'
                                                                    // onChange={this.uploadFile.bind(this)}
                                                                    onChange={(data) => this.uploadFile(data, index)}
                                                                    multiple={false}
                                                                    onRemove={(data) => this.removeFile(data, index)}
                                                                >
                                                                    {
                                                                        item.attributeValue.length === 0 &&
                                                                        <Button>
                                                                            Upload
                                                                </Button>
                                                                    }

                                                                </Upload>
                                                            }
                                                            {((item.attributeType === 'Option') ||
                                                                (item.attributeType === "deliveryFee") ||
                                                                (item.attributeType === "PaymentType"))
                                                                &&
                                                                <div>
                                                                    <Select placeholder="Select"
                                                                        onChange={(event) => this.fieldChanges(event, option, index)}
                                                                    >
                                                                        {commaSplitted && commaSplitted.length > 0 && commaSplitted.map(val => {
                                                                            let colanSeperated = val.split(':')
                                                                            return (
                                                                                <Option value={colanSeperated}>{colanSeperated[0]}</Option>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Select>
                                                                    {/* {Array.isArray(this.state.selectedOption) &&
                                                                        <span style={{
                                                                            fontSize: 'smaller',
                                                                            fontWeight: 'bold',
                                                                            lineHeight: 'normal',
                                                                            display: 'inline-block',
                                                                            overflow: 'visible'
                                                                        }}>{this.state.selectedOption[1]}</span>
                                                                    } */}
                                                                </div>
                                                            }

                                                        </div>

                                                    </div>
                                                )}
                                            </Form.Item>
                                        </div>
                                    )
                                }

                            })
                            }
                        </div>
                        :
                        <div id="order-form-address">
                            <div className="order-delivery-inform" id="delivery-container-view" style={{width:'50%',marginTop:4}}>
                                {transactionFormData.detail && Object.keys(transactionFormData.detail).map((key) => {
                                    let splitArray = ""
                                    if (typeof transactionFormData.detail[key] === "object") {
                                        splitArray = transactionFormData.detail[key]
                                    } else {
                                        let splitValues = transactionFormData.detail[key] && transactionFormData.detail[key].split(":")
                                        let checkTime = _.filter(splitValues, o => {
                                            if (_.includes(o, 'A.M') || _.includes(o, 'PM') || _.includes(o, 'AM') || _.includes(o, 'P.M')) {
                                                return o
                                            }
                                        })
                                        if (checkTime.length > 0) {
                                            let temp = []
                                            temp.push(transactionFormData.detail[key])
                                            splitArray = temp
                                        } else {

                                            splitArray = splitValues
                                        }
                                    }
                                    let name = _.startCase(key)

                                    if (key && key !== 'Address' && key !== 'City'
                                        && key !== 'Country' && key !== 'Zip Code (Postal Code)' && key !== 'State/Province/Region') {

                                        return (
                                            <Form.Item className="customer-details-field delivery-container"
                                                label={!this.state.editedBySeller && name}
                                                labelCol={{ xs: 24, sm: 24, md: 4, lg: 12 }}
                                                wrapperCol={{ xs: 24, sm: 24, md: 4, lg: 12 }}
                                            >
                                                {getFieldDecorator(`${key}`, {})(
                                                    <div>
                                                        <div>
                                                            {this.state.editedBySeller &&
                                                                <div><span style={{ color: 'gray', fontWeight: '500' }}>{name}</span></div>
                                                            }
                                                            {this.isDate(transactionFormData.detail[key]) ?
                                                                <Text>{transactionFormData.detail[key] ?
                                                                    moment(transactionFormData.detail[key]).format('DD/MM/YYYY') : emptyData}
                                                                </Text>
                                                                :
                                                                <div>
                                                                    {this.isUrl(transactionFormData.detail[key]) ?
                                                                        <Tooltip title="Click to open">
                                                                            <Icon id="icon-view" type="file" style={{ marginLeft: '10px' }}
                                                                                onClick={() => this.onOpenPreviousLink(transactionFormData.detail[key])}
                                                                            />
                                                                        </Tooltip>
                                                                        :
                                                                        Array.isArray(transactionFormData.detail[key]) ?
                                                                            <div>
                                                                                <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: transactionFormData.detail[key][0] ? `${transactionFormData.detail[key][0].replace(/\r?\n/g, '')}` : emptyData
                                                                                    }}
                                                                                />
                                                                                {/* {
                                                                                transactionFormData.detail[key] && transactionFormData.detail[key][1] && transactionFormData.detail[key][1].match(/\d+/g) === null &&
                                                                                <div style={{
                                                                                    fontSize: 'smaller',
                                                                                    fontWeight: 'bold',
                                                                                    lineHeight: 'normal',
                                                                                    display: 'inline-block',
                                                                                    overflow: 'visible'
                                                                                }}
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: transactionFormData.detail[key][1] ? `${transactionFormData.detail[key][1].replace(/\r?\n/g, '')}` : emptyData
                                                                                    }}
                                                                                />
                                                                            } */}

                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: splitArray && splitArray.length > 0 && splitArray[0] ? splitArray[0] : emptyData
                                                                                    }}
                                                                                />
                                                                                {/* <div style={{
                                                                                fontSize: 'smaller',
                                                                                fontWeight: 'bold',
                                                                                lineHeight: 'normal',
                                                                                display: 'inline-block',
                                                                                overflow: 'visible'
                                                                            }}
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: splitArray && splitArray.length > 0 && splitArray[1] ? `${splitArray[1].replace(/\r?\n/g, '')}` : emptyData
                                                                                }}
                                                                            /> */}
                                                                            </div>

                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                                }
                                            </Form.Item>
                                        )

                                    }
                                })
                                }

                            </div>
                            {transactionFormData && transactionFormData.detail && transactionFormData.detail['Address'] &&
                                <div id="address-seq-container">
                                    <div id="address-seq-view">
                                        <p style={{ color: '#a19f9f' }}>{this.locale('Address')} :</p>
                                    </div>
                                    <p id="address-seq-detail"> {transactionFormData.detail.Address}</p>
                                </div>
                            }
                            {transactionFormData && transactionFormData.detail && transactionFormData.detail['City'] &&
                                <div id="address-seq-container">
                                    <div id="address-seq-view">
                                        <p style={{ color: '#a19f9f' }}>{this.locale('City')} :</p>
                                    </div>
                                    <p id="address-seq-detail"> {transactionFormData.detail['City']} </p>
                                </div>
                            }
                            {transactionFormData && transactionFormData.detail && transactionFormData.detail['Zip Code (Postal Code)'] &&
                                <div id="address-seq-container">
                                    <div id="address-seq-view">
                                        <p style={{ color: '#a19f9f' }}>{this.locale('Zip Code (Postal Code)')} :</p>
                                    </div>
                                    <p id="address-seq-detail"> {transactionFormData.detail['Zip Code (Postal Code)']} </p>
                                </div>
                            }
                            {transactionFormData && transactionFormData.detail && transactionFormData.detail['State/Province/Region'] &&
                                <div id="address-seq-container">
                                    <div id="address-seq-view">
                                        <p style={{ color: '#a19f9f' }}>{this.locale('State/Province/Region')} :</p>
                                    </div>
                                    <p id="address-seq-detail"> {transactionFormData.detail['State/Province/Region']} </p>
                                </div>
                            }
                            {transactionFormData && transactionFormData.detail && transactionFormData.detail['Country'] &&

                                <div id="address-seq-container">
                                    <div id="address-seq-view">
                                        <p style={{ color: '#a19f9f' }}>
                                            {this.locale('Country')} :
                                        </p>
                                    </div>
                                    <p id="address-seq-detail"> {transactionFormData.detail['Country']} </p>
                                </div>
                            }
                        </div>
                    }
                </Form>


                {this.state.deilveryModalMessage &&
                    <Modal
                        // title={this.locale("Please Login")}
                        visible={this.state.messageModalEnable}
                        onOk={() => this.setState({ messageModalEnable: false })}
                        closable={false}
                        footer={
                            <Button onClick={() => this.onOkMessageModal()}
                            >{this.locale('Ok')}</Button>
                        }
                    // onCancel={() => this.setState({ messageModalEnable: false })}
                    >
                        {this.state.deilveryModalMessage}
                    </Modal>
                }
            </div>
        )
    }
}

const WrappedApp = Form.create()(DeliveryDetails);
export default WrappedApp;

