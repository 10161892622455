import React, { Component } from 'react'
import Avatar from 'react-avatar'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, InputNumber, Typography, Modal, Button, Icon, Tag, Alert } from 'antd'
import _ from 'lodash'
import { cloneDeep } from 'lodash'
import client from '../../apollo/apollo';

import {
    GET_PRODUCT_OPTION_LOOKUP, GET_VARIANTS_RESULT, GET_PRODUCT_INVENTORY
} from '../../graphQl/queries';
const { confirm } = Modal
class ProductDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isOpenproductDetail: false,
            selectedProductItem: {},
            productVariantsList: [],
            selectedOptions: {},
            selectedVariantItemIndex: null,
            selectedVariantOptionIndex: null,
            catalogDiscountValue: ""

        }
    }

    componentDidMount() {
        // if(this.props.selectedProductDetail && this.props.selectedProductDetail.hasVariant === true) {
        //     let detail = cloneDeep(this.props.selectedProductDetail)
        //     detail.product_list_price = null
        //     detail.product_image = []
        //     this.setState({ selectedProductItem: detail })
        // } else {
        //     this.setState({ selectedProductItem: this.props.selectedProductDetail })
        // }


        this.setState({
            selectedProductItem: this.props.selectedProductDetail,
            catalogDiscountValue: this.props.catalogDiscount
        })
        this.setState({
            isOpenproductDetail: this.props.isOpenproductDetailModal,
            // selectedProductItem: this.props.selectedProductDetail
        }, () => {
            if (this.state.isOpenproductDetail) {
                this.fetchUILoopup();
            }
            if (Object.keys(this.state.selectedProductItem).length > 0) {
                this.getinventoryDetails(this.state.selectedProductItem)
            }

        });

    }



    componentWillReceiveProps(newProps) {
        if (newProps.isOpenproductDetailModal !== this.props.isOpenproductDetailModal) {
            this.setState({ isOpenproductDetail: newProps.isOpenproductDetailModal }, () => {
                if (this.state.isOpenproductDetail) {
                    this.fetchUILoopup();
                }
            })
        }
        if (newProps.catalogDiscount !== this.props.catalogDiscount) {
            this.setState({ catalogDiscountValue: newProps.catalogDiscount }, () => {
            })
        }

        if (newProps.selectedProductDetail !== this.props.selectedProductDetail) {
            let detail = cloneDeep(newProps.selectedProductDetail)
            // detail.product_list_price = null
            // detail.product_image = []
            this.setState({ selectedProductItem: detail }, () => {
                if (Object.keys(this.state.selectedProductItem).length > 0) {
                    this.getinventoryDetails(this.state.selectedProductItem)
                }
                // this.getinventoryDetails(this.state.selectedProductItem)
            })
        }
    }



    getinventoryDetails = (product) => {

        // if (product.hasVariant) {
        //   product.product_id = product.product_inventory_details[0].product_id;
        // }
        client.query({
            query: GET_PRODUCT_INVENTORY, variables: {
                templateId: product.transaction_template_id,
                productId: product.product_id
            },
            fetchPolicy: 'no-cache',
        }).then((d) => {
            //   productInventoryDetails.push(d.data.product_inventory)
            this.setState({ productInventoryDetails: d.data.product_inventory });
        }).catch((err) => {
            console.log("catch123", err)
        });

    }


    formatValue = (value) => {
        if (value) {
            return parseInt(value)
        }
    }

    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };
    onOpenProductDetailModal = () => {
        this.setState({ isOpenproductDetailModal: true })
    }


    renderTitle = (item) => {
        if (Object.keys(item).length > 0) {
            return (
                <h5>{item.product_name}</h5>
            )
        }
    }

    renderFooter = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {this.state.productVariantsList.length > 0 ?
                    <div>
                        {
                            (Object.keys(this.state.selectedOptions).length > 0 && this.state.selectedProductItem.product_list_price) &&
                            <div>
                                <div className="quantity-row" style={{ display: 'flex' }}>
                                    <Col
                                        style={{ cursor: "pointer" }}
                                        className="quantity-add"
                                        onClick={() => this.onChangeQuantity('minus')}
                                    >
                                        <Icon style={{ cursor: "pointer" }}
                                            type="minus"
                                            onClick={() => this.onChangeQuantity('minus')}
                                        >
                                        </Icon>
                                    </Col>
                                    <Col className="quantity-value">
                                        {this.state.selectedProductItem.selected_quantity > 0 ? this.state.selectedProductItem.selected_quantity : 0}
                                    </Col>
                                    <Col
                                        style={{ cursor: "pointer" }}
                                        // style={{ marginLeft: 10 }}
                                        className="quantity-minus"
                                        onClick={() => this.onChangeQuantity('add')}
                                    >
                                        <Icon style={{ cursor: "pointer" }}
                                            type="plus"
                                            onClick={() => this.onChangeQuantity('add')}
                                        ></Icon>
                                    </Col>
                                </div>
                            </div>
                        }
                    </div> :
                    <div>
                        <div className="quantity-row" style={{ display: 'flex' }}>
                            <Col
                                style={{ cursor: "pointer" }}
                                className="quantity-add"
                                onClick={() => this.onChangeQuantity('minus')}
                            >
                                <Icon style={{ cursor: "pointer" }}
                                    type="minus"
                                    onClick={() => this.onChangeQuantity('minus')}
                                >
                                </Icon>
                            </Col>
                            <Col className="quantity-value">
                                {this.state.selectedProductItem.selected_quantity > 0 ? this.state.selectedProductItem.selected_quantity : 0}
                            </Col>
                            <Col
                                style={{ cursor: "pointer" }}
                                // style={{ marginLeft: 10 }}
                                className="quantity-minus"
                                onClick={() => this.onChangeQuantity('add')}
                            >
                                <Icon style={{ cursor: "pointer" }}
                                    type="plus"
                                    onClick={() => this.onChangeQuantity('add')}
                                ></Icon>
                            </Col>
                        </div>
                    </div>
                }
                {
                    (this.state.productVariantsList.length > 0) ?
                        <div>
                            {
                                <Button type="primary"
                                style={{width:120,height:45,flexDirection:'row',alignItems:'center',fontSize:15,}}
                                    disabled={
                                        ((Object.keys(this.state.selectedOptions).length > 0
                                            && this.state.selectedProductItem.product_list_price
                                            && this.state.selectedProductItem.selected_quantity > 0)) ? false : true}
                                    onClick={() => this.addToCart()}>
                                    {this.props.locale('Add to cart')}
                                </Button>
                            }

                        </div> :
                        <Button type="primary"
                        style={{width:120,height:45,flexDirection:'row',alignItems:'center',fontSize:15}}
                            disabled={
                                (this.state.selectedProductItem.product_list_price
                                    && this.state.selectedProductItem.selected_quantity > 0) ? false : true}
                            onClick={() => this.addToCart()}>
                            {this.props.locale('Add to cart')}</Button>
                }
            </div>
        )
    }


    addToCart = () => {
        if (this.props.onAddToCart) {
            let product = cloneDeep(this.state.selectedProductItem)
            let quantityCheck = product.product_settings ?
                product.product_settings.minimum_order_quantity ?
                    parseInt(product.product_settings.minimum_order_quantity) : 0 : 0;
            if (this.state.selectedProductItem.selected_quantity < quantityCheck) {
                confirm({
                    title: `Information`,
                    content: `Quantity cannot be less than ${quantityCheck}`,
                    onOk() {
                    },
                });
                return
            }
            let productInventoryDetails = this.state.productInventoryDetails;
            product["hasVariant"] = true
            product["product_variant_option"] = this.state.selectedOptions

            product["product_inventory_details"] = (productInventoryDetails &&
                productInventoryDetails.data &&
                productInventoryDetails.data.product_inventory &&
                productInventoryDetails.data.product_inventory.length > 0) ?
                productInventoryDetails.data.product_inventory : [{
                    product_id: product.product_id
                }];

            this.setState({
                selectedVariantItemIndex: null,
                selectedVariantOptionIndex: null, selectedOptions: {}
            })
            this.props.onAddToCart(product)
        }
    }
    formatValue = (value) => {
        if (value) {
            return parseInt(value)
        }
    }
    onChangeQuantity = (option) => {


        let product = cloneDeep(this.state.selectedProductItem)
        // let quantity = Math.abs(value)\
        if (product.hasOwnProperty('selected_quantity')) {
            if (option === "minus") {
                product['selected_quantity'] = product.selected_quantity - 1
            } else {
                product['selected_quantity'] = product.selected_quantity + 1
            }
        } else {
            product['selected_quantity'] = 1
        }

        this.setState({ selectedProductItem: product }, () => {
            // this.props.changeHandler(item, index, product.selected_quantity, "productDetail")
        })

        // console.log("option123", option)

        // this.props.onQuantityChange(this.state.selectedProductItem, 0, option)
    }

    fetchUILoopup = () => {
        client.query({
            query: GET_PRODUCT_OPTION_LOOKUP,
            variables: {
                productId: this.state.selectedProductItem.id,
                listingId: this.state.selectedProductItem.listing_id,
                templateId: this.state.selectedProductItem.transaction_template_id,
                productCategoryId: this.state.selectedProductItem.product_category_id,
                productSubcategoryId: this.state.selectedProductItem.product_subcategory_id,
            }

        })
            .then((res) => {
                if (res && res.data && res.data.product_option_lookup.length > 0) {
                    if (this.state.selectedProductDetail && this.state.selectedProductDetail.hasVariant) {
                        let detail = cloneDeep(this.state.selectedProductItem)
                        detail.product_list_price = null
                        detail.product_image = []
                        this.setState({ selectedProductItem: detail })
                    }
                    this.setState({
                        productVariantsList: res.data.product_option_lookup,
                    }, () => {
                        let tempObject = {}
                        let variantList = this.state.productVariantsList
                        variantList.map(value => {
                            tempObject[value.product_option_name] = value.option_value_list[0]
                        })
                        this.setState({ selectedOptions: tempObject })
                    })
                } else {
                    this.setState({ selectedProductItem: this.state.selectedProductItem })
                    this.setState({
                        productVariantsList: [],
                    })
                }
            }).catch((err) => {

                this.setState({ loading: false })
            })
    }

    onChangevariants = (variantItem, selectedOption, itemIndex, optionIndex) => {

        var previousOptions = this.state.selectedOptions;
        var optionName = variantItem.product_option_name;
        if (previousOptions.hasOwnProperty(optionName)) {
            if (previousOptions[optionName] === selectedOption) {
                previousOptions = _.omit(previousOptions, optionName)
            } else {
                previousOptions[optionName.toLowerCase()] = selectedOption
            }
        } else {
            previousOptions[optionName.toLowerCase()] = selectedOption
        }
        // if (selectedOption) {
        //     if (previousOptions.hasOwnProperty(optionName)) {
        //         previousOptions[optionName.toLowerCase()] = selectedOption
        //     } else {
        //         previousOptions[optionName.toLowerCase()] = selectedOption;
        //     }
        // } else {
        //     previousOptions = _.omit(previousOptions, optionName)
        //     console.log('omit', previousOptions)
        // }
        this.setState({
            selectedOptions: previousOptions, selectedVariantItemIndex: itemIndex,
            selectedVariantOptionIndex: optionIndex
        }, () => {

            if (Object.keys(previousOptions).length > 0) {
                client.query({
                    query: GET_VARIANTS_RESULT,
                    variables: {
                        p_data: {
                            listingId: this.state.selectedProductItem.listing_id,
                            productMasterId: this.state.selectedProductItem.id,
                            prodVariantOption: previousOptions
                        }
                    }
                })
                    .then((res) => {
                        if (res && res.data && res.data.get_product_variant_filter_by_params.length > 0) {
                            let result = res.data.get_product_variant_filter_by_params[0]
                            let product = cloneDeep(this.state.selectedProductItem)
                            product.product_list_price = result.price
                            product.product_image = result.product_image
                            product.product_id = result.product_id
                            product.variantId = result.id
                            product.product_settings = result.product_settings
                            product.product_description = result.product_description
                            this.setState({
                                selectedProductItem: product,
                            }, () => this.getinventoryDetails(product))
                        } else {

                            let product = cloneDeep(this.state.selectedProductItem)
                            product.product_list_price = null
                            product.product_image = []
                            this.setState({
                                selectedProductItem: product,
                            }, () => this.getinventoryDetails(product))
                            this.setState({
                                selectedProductItem: this.state.selectedProductItem,
                            }, () => this.getinventoryDetails(this.state.selectedProductItem))
                        }
                    }).catch((err) => {
                        console.log("err123", err)
                        this.setState({ loading: false })
                    })
            } else {
                // setVariantResult({})
            }
        });


    }

    onCloseModal = () => {
        this.setState({
            selectedOptions: {}, selectedVariantItemIndex: null,
            selectedVariantOptionIndex: null,
        }, () => {
            if (this.props.onCloseProductdetailModal()) {
                this.props.onCloseProductdetailModal()
            }
        })
    }

    renderPriceWithDiscountPercent(percentVal, actualPrice) {
        const { Title, Text } = Typography;

        let percentage = parseFloat(percentVal);
        let afterCalc = parseFloat(actualPrice) * (percentage / 100);
        let finalPrice = parseFloat(actualPrice) - parseFloat(afterCalc)
        return (<Text>{parseFloat(finalPrice).toFixed(2)}</Text>)

    }

    render = () => {

        const { selectedProductItem, productVariantsList, selectedVariantItemIndex, selectedVariantOptionIndex,
            selectedOptions, catalogDiscountValue } = this.state
        const { Title, Text } = Typography
        const { defaultPriceUnit, catalogDiscount } = this.props
        return (
            <div>

                <Modal
                    className="publicView"
                    title={this.renderTitle(selectedProductItem)}
                    visible={this.state.isOpenproductDetail}
                    //  onOk={this.onClickOk}
                    onCancel={() => this.onCloseModal()}
                    footer={this.renderFooter()}
                ><div>

                        {
                            Object.keys(selectedProductItem).length > 0 &&
                            <div>
                                {
                                    (selectedProductItem && selectedProductItem.product_image.length > 0
                                        && selectedProductItem.product_image[0].url) &&
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 12 }}>
                                        <img id="product-image-view" style={{
                                            width: 250,
                                            height: 200,
                                            // objectFit: 'cover'
                                        }}
                                            src={selectedProductItem.product_image[0].url} />
                                    </div>
                                }

                                {selectedProductItem.product_description &&
                                    <div style={{
                                        marginTop: 10,
                                        marginBottom: 16,
                                        padding: 8,
                                        background: '#fff'
                                    }}>
                                        <div>
                                            <h6>{this.props.locale('Product Description')}</h6>
                                        </div>

                                        <Text>{selectedProductItem.product_description}</Text>
                                    </div>
                                }

                                {
                                    productVariantsList.length > 0 && productVariantsList.map((variantItem, itemIndex) => {
                                        return (
                                            <div style={{ marginTop: 3 }}>
                                                <h6>{variantItem.product_option_name}</h6>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {variantItem.option_value_list.length > 0
                                                        && variantItem.option_value_list.map((option, optionIndex) => {
                                                            return (
                                                                <div className="column" id="variant-view" style={{ cursor: "pointer" }}>
                                                                    <Tag
                                                                        style={{ cursor: "pointer" }}
                                                                        // color={
                                                                        //     (selectedVariantItemIndex === itemIndex &&
                                                                        //         selectedVariantOptionIndex === optionIndex)
                                                                        //         ? "red" : "#fff"
                                                                        // }
                                                                        color={
                                                                            selectedOptions[variantItem.product_option_name] === option
                                                                                ? "red" : "#fff"
                                                                        }

                                                                        onClick={() => this.onChangevariants(variantItem, option, itemIndex, optionIndex)}
                                                                    >{option}</Tag>
                                                                </div>
                                                            )


                                                        })

                                                    }
                                                </div>
                                            </div>

                                        )
                                    })

                                }

                                <div style={{
                                    background: '#fff', padding: 8, marginTop: 10,
                                    marginBottom: 10
                                }}>

                                    <div>
                                        <h6>{this.props.locale('Price')}</h6>
                                    </div>


                                    <div>

                                        {productVariantsList.length > 0 ?
                                            <div>
                                                {
                                                    (Object.keys(selectedOptions).length > 0 && selectedProductItem.product_list_price) ?
                                                        <>
                                                            <Text>{defaultPriceUnit} </Text>
                                                            {(selectedProductItem.product_settings && selectedProductItem.product_settings.discount_price &&
                                                                selectedProductItem.product_settings.discount_price !== '') ?
                                                                <>
                                                                    <Text className={`gx-subject gx-text-muted gx-text-strikethrough`}> {selectedProductItem.product_list_price.toFixed(2)} </Text>
                                                                    <Text> {parseFloat(selectedProductItem.product_settings.discount_price).toFixed(2)} </Text>
                                                                </>
                                                                :
                                                                (selectedProductItem.product_category &&
                                                                    selectedProductItem.product_category.category_discount) ?
                                                                    <>
                                                                        <Text className={`gx-subject gx-text-muted gx-text-strikethrough`}> {parseFloat(selectedProductItem.product_list_price).toFixed(2)} </Text>
                                                                        {this.renderPriceWithDiscountPercent(selectedProductItem.product_category.category_discount, selectedProductItem.product_list_price)}
                                                                    </>
                                                                    :
                                                                    (catalogDiscount && catalogDiscount !== '') ?
                                                                        <>
                                                                            <Text className={`gx-subject gx-text-muted gx-text-strikethrough`}> {parseFloat(selectedProductItem.product_list_price).toFixed(2)} </Text>
                                                                            {this.renderPriceWithDiscountPercent(catalogDiscount, selectedProductItem.product_list_price)}
                                                                        </> :
                                                                        <Text> {selectedProductItem.product_list_price.toFixed(2)} </Text>
                                                            }
                                                            {/* <Text> {selectedProductItem.product_list_price}</Text> */}
                                                        </> :
                                                        <Text>{this.props.locale('Please select options')}</Text>
                                                }
                                            </div> :
                                            <>
                                                <Text>{defaultPriceUnit} </Text>
                                                {(selectedProductItem.product_settings && selectedProductItem.product_settings.discount_price &&
                                                    selectedProductItem.product_settings.discount_price !== '') ?
                                                    <>
                                                        <Text className={`gx-subject gx-text-muted gx-text-strikethrough`}> {selectedProductItem.product_list_price.toFixed(2)} </Text>
                                                        <Text> {parseFloat(selectedProductItem.product_settings.discount_price).toFixed(2)} </Text>
                                                    </>
                                                    :
                                                    (selectedProductItem.product_category &&
                                                        selectedProductItem.product_category.category_discount) ?
                                                        <>
                                                            <Text className={`gx-subject gx-text-muted gx-text-strikethrough`}> {parseFloat(selectedProductItem.product_list_price).toFixed(2)} </Text>
                                                            {this.renderPriceWithDiscountPercent(selectedProductItem.product_category.category_discount, selectedProductItem.product_list_price)}
                                                        </>
                                                        :
                                                        (catalogDiscount && catalogDiscount !== '') ?
                                                            <>
                                                                <Text className={`gx-subject gx-text-muted gx-text-strikethrough`}> {parseFloat(selectedProductItem.product_list_price).toFixed(2)} </Text>
                                                                {this.renderPriceWithDiscountPercent(catalogDiscount, selectedProductItem.product_list_price)}
                                                            </> :
                                                            <Text> {selectedProductItem.product_list_price.toFixed(2)} </Text>
                                                }
                                                {/* <Text> {selectedProductItem.product_list_price}</Text> */}
                                            </>
                                        }
                                        {/* {
                                            this.state.quantityAlert === true &&
                                            <div >
                                                <Popconfirm
                                                    title={`Quantity cannot be less than ${this.state.miminmumQuantity}`}
                                                    // onConfirm={confirm}
                                                    onCancel={thucancel}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                </Popconfirm>,
                                            </div>
                                        } */}
                                        {/* {
                                            (Object.keys(selectedOptions).length > 0 && selectedProductItem.product_list_price) ?

                                                <Text>{defaultPriceUnit} {selectedProductItem.product_list_price}</Text> :
                                                <Text>Please select options</Text>
                                        } */}

                                        {/* {productVariantsList.length > 0 ?
                                            <div>
                                                {

                                                    (Object.keys(selectedOptions).length > 0 && selectedProductItem.product_list_price) &&
                                                    <div>
                                                        <div className="quantity-row" style={{ display: 'flex' }}>
                                                            <Col
                                                                style={{ cursor: "pointer" }}
                                                                className="quantity-add"
                                                                onClick={() => this.onChangeQuantity('minus')}
                                                            >
                                                                <Icon style={{ cursor: "pointer" }}
                                                                    type="minus"
                                                                    onClick={() => this.onChangeQuantity('minus')}
                                                                >

                                                                </Icon>
                                                            </Col>
                                                            <Col className="quantity-value">
                                                                {selectedProductItem.selected_quantity > 0 ? selectedProductItem.selected_quantity : 0}
                                                            </Col>
                                                            <Col
                                                                style={{ cursor: "pointer" }}
                                                                // style={{ marginLeft: 10 }}
                                                                className="quantity-minus"
                                                                onClick={() => this.onChangeQuantity('add')}
                                                            >
                                                                <Icon style={{ cursor: "pointer" }}
                                                                    type="plus"
                                                                    onClick={() => this.onChangeQuantity('add')}
                                                                ></Icon>
                                                            </Col>

                                                        </div>
                                                    </div>
                                                }
                                            </div> :
                                            <div>
                                                <div className="quantity-row" style={{ display: 'flex' }}>
                                                    <Col
                                                        style={{ cursor: "pointer" }}
                                                        className="quantity-add"
                                                        onClick={() => this.onChangeQuantity('minus')}
                                                    >
                                                        <Icon style={{ cursor: "pointer" }}
                                                            type="minus"
                                                            onClick={() => this.onChangeQuantity('minus')}
                                                        >

                                                        </Icon>
                                                    </Col>
                                                    <Col className="quantity-value">
                                                        {selectedProductItem.selected_quantity > 0 ? selectedProductItem.selected_quantity : 0}
                                                    </Col>
                                                    <Col
                                                        style={{ cursor: "pointer" }}
                                                        // style={{ marginLeft: 10 }}
                                                        className="quantity-minus"
                                                        onClick={() => this.onChangeQuantity('add')}
                                                    >
                                                        <Icon style={{ cursor: "pointer" }}
                                                            type="plus"
                                                            onClick={() => this.onChangeQuantity('add')}
                                                        ></Icon>
                                                    </Col>

                                                </div>
                                            </div>
                                        } */}

                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </Modal>
            </div>
        )
    }
}

export default ProductDetails
