import React, { Component } from 'react'
import { Typography, Table, Divider, Row, Col, Icon } from 'antd'
import _ from 'lodash';

class Summary extends Component {

    onChangeQuantity = (record, option, index) => {
        console.log('onChangeQuantity', record, option, index)
        if (this.props.changeHandler && record) {
            let value
            if (option) {
                if (option === "add") {
                    value = record.qty + 1
                } else {
                    value = record.qty - 1
                }
            }
            this.props.changeHandler(record, record.item_index, value, "orderSummary", index)
        }

    }

    locale = (string) => {
        if (this.props.templateSettings.LocalizationDetailValues &&
            this.props.templateSettings.LocalizationDetailValues.length > 0 &&
            this.props.templateSettings.LocalizationDetailValues.find(item => item.DefaultValue === string)) {
            return this.props.templateSettings.LocalizationDetailValues.find(item => item.DefaultValue === string).AlternateValue
        } else {
            return string
        }
    }

    renderAddOns = (value) => {
        return (
            <div>
                {Object.keys(value).map((key) => {
                    if (key) {
                        return value[key].map(item => {

                            if (item && item.length > 0 && item[0] && item[1]) {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <p id="summary-first-text">{item[0]}</p>
                                        <p style={{ paddingRight: 16 }}>{this.props.defaultPriceUnit + ' ' + parseFloat(item[1]).toFixed(2)}</p>
                                    </div>
                                )
                            }
                        })
                    }
                })
                }
            </div>
        )
    }

    render = () => {
        const { Title, Text } = Typography;
        const { templateSettings } = this.props;
        const lineItems = templateSettings && templateSettings.LineItems && templateSettings.LineItems.length > 0 ? templateSettings.LineItems : []
        let checkAttribute = lineItems && lineItems.length > 0 && lineItems[0].product_attribute && lineItems[0].product_attribute.length > 0 ? _.findIndex(lineItems[0].product_attribute, function (o) { return o.attributeValue !== '' && o.attributeName !== '' }) : -1
        let checkNewCatalogData = lineItems && lineItems.length > 0 && lineItems[0] && lineItems[0].hasOwnProperty('product_subcategory_id') ? true : false
        const data = this.props.items;
        console.log(this.props,"summarydata")
        let priceUnit = '';
        if (this.props.defaultPriceUnit != '') {
            priceUnit = ' (' + this.props.defaultPriceUnit + ')'
        }
        const tableColumns = [
            {
                title: this.locale('Name'),
                dataIndex: 'name',
                key: 'name',
                width: '35%',
            },
            {
                title: this.locale('Unit Price') + priceUnit,
                dataIndex: 'unit_price',
                key: 'price',
                width: '30%',
                render: (val, record, key) => {
                    return <div>
                        {parseFloat(val).toFixed(2)}
                    </div>
                }
            },
            {
                title: this.locale('Qty'),
                dataIndex: 'qty',
                key: 'qty',
                width: '20%',
                render: (val, record, key) => {
                    return <div>
                        {
                            this.props.showOrderQuantityEdit ?
                                <Row className="quantity-row">
                                    <Col
                                        // style={{ marginRight: 10 }} 
                                        className="quantity-add">
                                        <Icon style={{ cursor: "pointer" }}
                                            type="minus" onClick={() => this.onChangeQuantity(record, 'minus', key)}></Icon>
                                    </Col>
                                    <Col className="quantity-value">
                                        {val}
                                    </Col>
                                    <Col
                                        // style={{ marginLeft: 10 }} 
                                        className="quantity-minus">
                                        <Icon style={{ cursor: "pointer" }}
                                            type="plus" onClick={() => this.onChangeQuantity(record, 'add', key)}></Icon>
                                    </Col>

                                </Row> :
                                <Text>{val}</Text>
                        }
                    </div>
                }
            },
            {
                title: this.locale('Total') + priceUnit,
                dataIndex: 'total',
                key: 'total',
                width: '30%'
            },
            // {
            //     dataIndex: 'total',
            //     key: 'delete',

            //     render: (val, record, key) => {
            //         return <div>
            //             {
            //                 this.props.showOrderQuantityEdit &&
            //                 <Icon style={{ cursor: "pointer" }}
            //                     type="delete"
            //                     style={{ fontSize: 16 }}
            //                     onClick={() => this.props.onRemoveItem(record, this.props.items, key)}></Icon>
            //             }
            //         </div>
            //     }
            // }
        ]
        return (
            <div id="order-summary-container" style={{ marginBottom: '4%' }}>
                <Divider />
                <Title level={4} className="pubilc-url-title" style={{ marginBottom: '4%' }}>
                    {(templateSettings.LocalizationDetailValues &&
                        templateSettings.LocalizationDetailValues.length > 0 &&
                        templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Order details') ?
                        templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Order details').AlternateValue : 'Order Summary')}
                </Title>

                {/* <Table columns={tableColumns} dataSource={data} pagination={false} /> */}
                {checkNewCatalogData ?
                    <div>
                        {lineItems && lineItems.length > 0 &&
                            <div>
                                {lineItems.map((value) => {
                                    console.log(value, 'valueueuitem')
                                    return(
                                    <div className="quantity-container-total public-order-container" id="order-container-summarry">

                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 6, minHeight: 24 }}>
                                            {value.product_name &&
                                                <div id="summary-first-text">
                                                    <Title level={4} id="title-view" style={{ fontWeight: "bold", marginBottom: 15 }}>{value.product_name}</Title>
                                                </div>
                                            }
                                            {/* {value.quantity && value.total &&
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 6, height: 24 }}> */}

                                            {value.quantity &&
                                                <Text className="total-unit-value">{value.quantity}</Text>
                                            }
                                            {value.quantity && value.total &&
                                                <p className="total-unit-value" >{this.props.defaultPriceUnit + ' ' + parseFloat(parseFloat(value.total)).toFixed(2)}</p>
                                            }
                                            {/* </div>
                                        } */}
                                        </div>
                                        {value && value.product_description &&
                                            <div style={{ color: 'black', fontSize: 14, marginBottom: 15, fontWeight: 300 }}>
                                                {value.product_description}
                                            </div>
                                        }
                                        {value && value.hasVariant && value.product_variant_option &&
                                            <div style={{ display: 'flex', marginRight: 10, }}>{Object.entries(value.product_variant_option).map((key, ind) => (
                                                <div style={{ color: 'black', fontSize: 15, marginBottom: 15, fontWeight: 300, paddingRight: 5 }}>
                                                    {key[0]}: {key[1]}{Object.keys(value.product_variant_option).length - 1 !== ind && ' , '}
                                                </div>
                                            ))
                                            }</div>
                                        }

                                        <Divider />
                                    </div>
                                )})
                                }
                            </div>
                        }
                    </div>
                    :
                    <div>
                        {checkAttribute !== -1 ?
                            <div>
                                {lineItems && lineItems.length > 0 &&
                                    <div>
                                        {lineItems.map((value) =>
                                        {
                                            console.log(value, 'valueueuitem')
                                            return (
                                            <div className="quantity-container-total" id="order-container-summarry">

                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 6, minHeight: 24 }}>
                                                    {value.product_name &&
                                                        <div id="summary-first-text">
                                                            <Title level={4} id="title-view" style={{ fontWeight: "bold" }}>{value.product_name}</Title>
                                                        </div>
                                                    }
                                                    {value.product_baseprice &&
                                                        <p style={{ paddingRight: 16 }}>{this.props.defaultPriceUnit + ' ' + parseFloat(value.product_baseprice).toFixed(2)}</p>
                                                    }
                                                </div>

                                                {value.selected_product_attributes &&
                                                    this.renderAddOns(value.selected_product_attributes)
                                                }

                                                {value.quantity && value.total &&
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 6, height: 24 }}>

                                                        {value.quantity &&
                                                            <Text className="total-unit-value">Quantity: {value.quantity}</Text>
                                                        }
                                                        {value.quantity && value.total &&
                                                            <p className="total-unit-value" style={{ paddingRight: 16 }}>{this.props.defaultPriceUnit + ' ' + parseFloat(parseFloat(value.total)).toFixed(2)}</p>
                                                        }
                                                    </div>
                                                }
                                                <Divider />
                                            </div>
                                        )})
                                        }
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                {data && data.length > 0 ?
                                    <div>
                                        {data.map((value, index) => (
                                            
                                            <div className="quantity-container-total">
                                                { value.image && value.image[0] && value.image[0].url ?<div>
                                                <img id="produc-image-view" style={{ width:80, height: 80, marginRight: 10 }}
                                                 src={value.image[0].url} />
                                                </div>:<p></p>}
                                                <div id="add-cart-details">
                                                <Title level={5} id="title-view" style={{ fontWeight: "bold",marginBottom:8 ,}}>{value.name}</Title>
                                                {value && value.hasVariant && value.product_variant_option &&
                                                        <div style={{ display: 'flex', marginRight: 10,marginBottom:8 }}>{Object.entries(value.product_variant_option).map((key, ind) => (
                                                            <div style={{ color: 'black', fontSize: 15, marginBottom: 15, fontWeight: 300, paddingRight: 5 ,marginBottom:8}}>
                                                                {key[0]}: {key[1]}{Object.keys(value.product_variant_option).length - 1 !== ind && ' , '}
                                                            </div>
                                                        ))
                                                        }</div>
                                                    }
                                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 6, height: 24,paddingLeft:10 }} id="summary-total-container">
                                                        {/* <p style={{ fontWeight: 500, width: '33%' }}>{this.props.defaultPriceUnit + ' ' + parseFloat(value.unit_price).toFixed(2)}</p> */}
                                                        {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
                                                        <div
                                                            style={{ width: '60%', display: 'flex', }}
                                                        >
                                                            {
                                                                this.props.showOrderQuantityEdit ?
                                                                    <Row className="quantity-row" id="quantity-row-view" >
                                                                        <Col
                                                                            // style={{ marginRight: 10 }} 
                                                                            className="quantity-add">
                                                                            <Icon style={{ cursor: "pointer" }}
                                                                                type="minus" onClick={() => this.onChangeQuantity(value, 'minus', index)}></Icon>
                                                                        </Col>
                                                                        <Col className="quantity-value">
                                                                            {value.qty}
                                                                        </Col>
                                                                        <Col
                                                                            // style={{ marginLeft: 10 }} 
                                                                            className="quantity-minus">
                                                                            <Icon style={{ cursor: "pointer" }}
                                                                                type="plus" onClick={() => this.onChangeQuantity(value, 'add', index)}></Icon>
                                                                        </Col>

                                                                    </Row> :
                                                                    <Text>{value.qty}</Text>
                                                            }
                                                        </div>
                                                        <p className="total-unit-value" id="order-total-price" style={{ paddingRight: 5, width: '40%', textAlign: 'right' }}>{this.props.defaultPriceUnit + ' ' + value.total}</p>
                                                        {/* </div> */}
                                                    </div>
                                                    {value.discountPromotion && (templateSettings.DiscountsPromotionsValues || (templateSettings.Total && templateSettings.Total.TotalDiscount > 0)) &&
                                                        <div style={{ display: 'flex', flexDirection: 'row', color: 'red' }}>

                                                            <p className="product-discount-value">{value.discountPromotion.discount}</p>
                                                        </div>
                                                    }

                                                </div>
                                               
                                            </div>
                                        ))
                                        }
                                    </div>
                                    :
                                    <p>{this.locale('No data')}</p>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default Summary
