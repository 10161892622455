import React, { Component } from "react";
import { storage } from '../../firebase/firebase';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Input, Typography, Row, Col, Divider, DatePicker, Form, Upload, Button, message, Icon, Tooltip } from 'antd';
import moment from 'moment'
import _ from 'lodash'
import { cloneDeep } from 'lodash'

let emptyData = "-"

const { TextArea } = Input;

class OrderDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            transactionFormData: {},
            orderDetailFields: [],
            downloadUrl: null,
            selectedData: {},
            isDataReadOnly: false
        }
        if (props.getUserOrderDetails) {
            props.getUserOrderDetails(this.getUserOrderDetailsValues.bind(this));
        }
    }


    componentDidMount() {
        this.setFormData(this.props)
        this.setFieldsList(this.props)
    }

    componentWillReceiveProps(newProps) {
        if (this.props.data !== newProps.data) {
            this.setFormData(newProps)
        }
        if (this.props.templateSettingsData !== newProps.templateSettingsData) {
            this.setFieldsList(newProps)
        }
    }

    //template fields
    setFieldsList = (propsValue) => {
        if (propsValue && propsValue.templateSettingsData && propsValue.templateSettingsData.orderDetails.length > 0) {
            this.setState({ orderDetailFields: propsValue.templateSettingsData.orderDetails })
        }
        else {
            this.setState({ orderDetailFields: [] })
        }
    }

    //short url data
    setFormData = (propsValue) => {
        if (propsValue && Object.keys(propsValue.data).length > 0) {
            this.setState({ isDataReadOnly: true }, () => {
                if (propsValue.data && propsValue.data.formData && propsValue.data.formData.OrderDetails) {
                    this.setState({ transactionFormData: propsValue.data.formData.OrderDetails }, () => {
                        // this.setCustomerDetails()
                    })
                }
                else {
                    this.setState({ transactionFormData: {} })
                }
            })
        }

    }

    //replace transaction form data with field attribut value
    setCustomerDetails = () => {
        if (this.state.transactionFormData && this.state.orderDetailFields.length > 0) {
            let list = this.state.transactionFormData
            let data = this.state.orderDetailFields
            for (var key in list) {
                if (list.hasOwnProperty(key)) {
                    let findValue = data.find(v => v.attributeName == key)
                    if (findValue) {
                        data.find(v => v.attributeName == key).attributeValue = list[key];
                    }
                }
            }
            this.setState({ orderDetailFields: data })
        }
    }

    //get customer form details while submiting the form
    getUserOrderDetailsValues = () => {
        let newField = {}
        console.log(this.state.orderDetailFields, 'this.state.orderDetailFields')
        let data = _.find(this.state.orderDetailFields, function (item) {
            if (item && item.required) {
                return !item.attributeValue
            }
        });
        if (!data) {
            this.state.orderDetailFields.map((res, index) => {
                newField[res.attributeName] = res.attributeValue
            })
            return newField
        } else {
            return null
        }

    }

    uploadFile = (info, indexValue) => {
        let data = cloneDeep(this.state.orderDetailFields)
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {

            const file = info.fileList[0].originFileObj;
            const fileUrl = `ecommerce/${file.name}`;
            const uploadTask = storage.ref(fileUrl).put(file)

            uploadTask.on(
                'state_changed',
                snapshot => {
                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                        // message.success(`${info.file.name} file uploaded successfully`);
                        if (downloadURL) {
                            let value = []
                            value.push(downloadURL)
                            data[indexValue].attributeValue = value
                            this.setState({ orderDetailFields: data }, () => {
                            })
                        }
                    });
                    // progress function
                },
                error => {
                    // this.setState({ showLoading: true })
                    //error function
                })
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    removeFile = (info, indexValue) => {
        let data = cloneDeep(this.state.orderDetailFields)
        data[indexValue].attributeValue = []
        this.setState({ orderDetailFields: data }, () => {
        })
    }

    onChangeValues = (event, indexValue) => {
        let data = cloneDeep(this.state.orderDetailFields)
        data[indexValue].attributeValue = event.target.value
        this.setState({ orderDetailFields: data }, () => {
        })
    }

    //when opening previus link
    onOpenPreviousLink = (item) => {
        if (item) {
            window.open(`${item}`)
        }
    }

    isUrl(string) {
        var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        return regexp.test(string);
    }


    render() {

        const { Text, Title } = Typography
        const { orderDetailFields, transactionFormData, isDataReadOnly } = this.state
        const { getFieldDecorator } = this.props.form
        let formData = (Object.keys(transactionFormData).length > 0) ? true : false
        console.log(orderDetailFields, 'orderDetailFields')
        return (
            <div>
                {isDataReadOnly === false ?
                    <Form>
                        {
                            orderDetailFields && orderDetailFields.map((item, index) => {
                                console.log(item, 'item')
                                if (item && item.attributeName) {
                                    let name = _.startCase(item.attributeName)
                                    return (
                                        <Form.Item className="customer-details-field order-instruction"

                                            label={name}
                                            labelCol={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                            wrapperCol={{ xs: 12, sm: 12 }}
                                        >
                                            {getFieldDecorator(`${item.attributeName}`, {
                                                rules: [
                                                    {
                                                        required: item.required,
                                                        message: 'Please input your' + " " + `${item.attributeName}`,
                                                    },
                                                ],
                                            })(
                                                <div>

                                                    <div>
                                                        {
                                                            item.attributeType === "Input" &&
                                                            <Input onChange={(event) => this.onChangeValues(event, index)} />
                                                        }
                                                        {
                                                            (item.attributeType === "TextArea") &&
                                                            <TextArea rows={4} onChange={(event) => this.onChangeValues(event, index)} />
                                                        }
                                                        {
                                                            item.attributeType === "FileUpload" &&
                                                            <Upload
                                                                // name='file'
                                                                action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                                                listType='picture'
                                                                // onChange={this.uploadFile.bind(this)}
                                                                onChange={(data) => this.uploadFile(data, index)}
                                                                multiple={false}
                                                                onRemove={(data) => this.removeFile(data, index)}
                                                            >
                                                                {
                                                                    item.attributeValue.length === 0 &&
                                                                    <Button>
                                                                        Upload
                                                                </Button>
                                                                }

                                                            </Upload>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                            }
                                        </Form.Item>
                                    )
                                }

                            })
                        }
                    </Form>
                    :
                    <div>
                        {transactionFormData && Object.keys(transactionFormData).map((key) => {
                            console.log(transactionFormData[key], key, 'transactionFormData[key]')
                            let name = _.startCase(key)

                            if (key) {
                                return (
                                    <Form.Item className="customer-details-field"
                                        label={name}
                                        labelCol={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                        wrapperCol={{ xs: 12, sm: 12 }}
                                    >
                                        {getFieldDecorator(`${key}`, {})(
                                            <div>
                                                <div className="customer-data-view">
                                                    {this.isUrl(transactionFormData[key]) ?
                                                        <Tooltip title="Click to open">
                                                            <Icon id="icon-view" type="file" style={{ marginLeft: '10px' }}
                                                                onClick={() => this.onOpenPreviousLink(transactionFormData[key])}
                                                            />
                                                        </Tooltip>
                                                        :
                                                        <p id="customer-detail-desc"
                                                            dangerouslySetInnerHTML={{
                                                                __html: transactionFormData[key] ? `${transactionFormData[key].replace(/\r?\n/g, '<br />')}` : emptyData
                                                            }}
                                                        />
                                                    }
                                                    {/* <Text>{transactionFormData[key] ? this.isUrl(transactionFormData[key]) ?
                                                        <Tooltip title="Click to open">
                                                            <Icon id="icon-view" type="file" style={{ marginLeft: '10px' }}
                                                                onClick={() => this.onOpenPreviousLink(transactionFormData[key])}
                                                            />
                                                        </Tooltip> :
                                                        transactionFormData[key] : emptyData}</Text> */}
                                                </div>
                                            </div>
                                        )
                                        }
                                    </Form.Item>
                                )
                            }
                        })
                        }
                    </div>
                }
            </div >
        )
    }
}

const WrappedApp = Form.create()(OrderDetails);
export default WrappedApp;

