import React, { Component } from 'react'
import LineItem from './LineItem'
import { Typography, Select, Row, Col, Divider } from 'antd'
import moment from 'moment'
import Avatar from 'react-avatar';
import QRCode from "qrcode.react";

class ListingDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            imageUrl: "",
            address: "",
            phone: "",
            templateSettings: {},
            listingName: "",
            transactionOrderId: "",
            listingImg: ""
        }
    }

    componentDidMount() {
        this.setDetail(this.props.data)
        this.setTransactionDetail(this.props.transactionDetail)
    }

    componentWillReceiveProps(newProps) {
        if (this.props.data !== newProps.data) {
            this.setDetail(newProps.data)
        }

        if (this.props.transactionDetail !== newProps.transactionDetail) {
            this.setTransactionDetail(newProps.transactionDetail)
        }
    }

    setTransactionDetail = (data) => {
        if (data && data.formData && data.formData.orderId) {
            this.setState({ transactionOrderId: data.formData.orderId })
        }
    }

    setDetail = (propsValue) => {
        this.setState({
            templateSettings: (propsValue && propsValue.templateSettings) ?
                propsValue.templateSettings : {}
        })

        if (propsValue && propsValue.listing) {
            let detail = propsValue.listing[0]
            let listingImage = detail.listing_img ? detail.listing_img : ""
            let image = propsValue.templateSettings ?
                propsValue.templateSettings.fieldValues ?
                    propsValue.templateSettings.fieldValues.HeaderImage ?
                        propsValue.templateSettings.fieldValues.HeaderImage.showImage ?
                            propsValue.templateSettings.fieldValues.HeaderImage.ImageDetail.length > 0 ?
                                propsValue.templateSettings.fieldValues.HeaderImage.ImageDetail[0].url :
                                '' : '' : '' : '' : '';
            let mobile = detail.phoneNumber ? detail.phoneNumber : ""
            let name = detail.name ? detail.name : ""

            let addressValue = ''
            let value = detail
            if (value.address1) {
                addressValue = value.address1
            }
            if (value.address2) {
                addressValue = addressValue + ',' + value.address2
            }
            if (value.city) {
                addressValue = addressValue + ',' + value.city
            }
            if (value.state) {
                addressValue = addressValue + ',' + value.state
            }
            if (value.country) {
                addressValue = addressValue + ',' + value.country
            }
            if (value.postalCode) {
                addressValue = addressValue + ',' + value.postalCode
            }
            this.setState({
                imageUrl: image, address: addressValue, phone: mobile, listingName: name,
                listingImg: listingImage
            })
        }
    }

    locale = (string) => {
        // if ((Object.keys(this.props.transactionDetail).length > 0)) {
        //     if (this.props.transactionDetail.formData.LocalizationDetailValues &&
        //         this.props.transactionDetail.formData.LocalizationDetailValues.length > 0 &&
        //         this.props.transactionDetail.formData.LocalizationDetailValues.find(item => item.DefaultValue === string)) {
        //         return this.props.transactionDetail.formData.LocalizationDetailValues.find(item => item.DefaultValue === string).AlternateValue
        //     } else {
        //         return string
        //     }
        // } else {
        if (this.state.templateSettings.fieldValues && this.state.templateSettings.fieldValues.LocalizationDetailValues &&
            this.state.templateSettings.fieldValues.LocalizationDetailValues.length > 0 &&
            this.state.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.DefaultValue === string)) {
            return this.state.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.DefaultValue === string).AlternateValue
        } else {
            return string
        }
        // }
    }

    render = () => {
        const { imageUrl, address, phone, templateSettings, listingName, listingImg } = this.state
        const { Text } = Typography
        const { isReadOnly, transactionDetail, statusDes } = this.props;

        return (
            <div style={{ marginBottom: '1%' }}>
                {
                    this.props.screenName && this.props.screenName === "listingDetail" &&
                    <div>
                        <h4 className="order-Name-detail">{this.props && this.props.data && this.props.data.name}</h4>
                    </div>
                }
                <div id="image-qr-view">
                    {this.props.screenName !== "listingDetail" &&
                        <div style={{ textAlign: "center", marginBottom: 15 }} className={isReadOnly ? "short-url-view" : "public-url-view"}>

                            {/* if header section img is uploaded */}

                            {
                                imageUrl ?
                                    <img
                                        id="form-upl-profile"
                                        style={{ objectFit: 'contain' }}
                                        src={imageUrl}
                                    /> :
                                    // if header section img is not uploaded show listing img
                                    <div>
                                        {
                                            listingImg ?
                                                <img
                                                    id="form-upl-profile"
                                                    style={{ objectFit: 'contain' }}
                                                    src={listingImg}
                                                /> :
                                                <div>
                                                    {
                                                        listingName &&
                                                        <Avatar name={listingName} size="150" maxInitials={2} textSizeRatio={1.75} />
                                                    }
                                                </div>
                                        }

                                    </div>
                            }
                        </div>
                    }
                    {this.props.transactionDetail && this.props.transactionDetail.formData &&

                        <div id="qr-code-view">
                            <QRCode
                                value={`${this.props.transactionDetail.formData.orderId}/${this.props.transactionDetail.formData.LineItems[0].listing_id}`}
                            />
                        </div>
                    }
                </div>
                {this.props.screenName !== "listingDetail" && isReadOnly &&
                    <h4 className="order-Name-detail">{this.locale('Thank you for your order with ')}{listingName}</h4>
                }

                {/* {isReadOnly &&
                    <h4 className="pubilc-url-title" style={{ fontWeight: 600, paddingLeft: '5%' }}>{this.locale('Order Information')}</h4>
                } */}
                {
                    this.props.screenName && this.props.screenName === "listingDetail" ?
                        <div>
                            {
                                this.props.transactionDetail && this.props.transactionDetail.formData &&
                                this.props.transactionDetail.formData.orderId &&
                                <div>
                                    <Row id="order-view-contain" style={{ marginBottom: '2%' }}>
                                        <Col xs={12} md={12} lg={12} id="order-id-view">
                                            <Text> {this.locale('Order Id')}: </Text>
                                        </Col>
                                        <Col xs={12} md={12} className="sub-details">
                                            <Text>{this.props.transactionDetail.formData.orderId}</Text>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {
                                this.props.transactionDetail && this.props.transactionDetail.createdAt &&
                                <div>
                                    {/* <Row id="order-view-contain" style={{ marginBottom: '2%' }}>
                                        <Col xs={12} md={12} lg={12} id="order-id-view">
                                            <Text>  {this.locale('Order Date')}: </Text>
                                        </Col>
                                        <Col xs={12} md={12} className="sub-details">
                                            <Text>{moment(this.props.transactionDetail.createdAt).format('MMM DD YYYY, hh:mm A')}</Text>
                                        </Col>
                                    </Row> */}
                                    <Row id="order-view-contain" style={{ marginBottom: '2%' }}>
                                        <Col xs={12} md={12} lg={12} id="order-id-view">
                                            <Text>  {this.locale('Order Date')}: </Text>
                                        </Col>
                                        <Col xs={12} md={12} className="sub-details">
                                            <Text>{moment(this.props.transactionDetail.createdAt).format('MMM DD YYYY, hh:mm A')}</Text>
                                        </Col>
                                    </Row>
                                    {/* <br /> */}
                                </div>
                            }

                            {
                                this.props.transactionDetail && this.props.transactionDetail.signedIP &&
                                this.props.transactionDetail.signedIP &&
                                <div>
                                    <Row id="order-view-contain" style={{ marginBottom: '2%' }}>
                                        <Col xs={12} md={12} lg={12} id="order-id-view">
                                            <Text> {this.locale('Logged in IP address')}: </Text>
                                        </Col>
                                        <Col xs={12} md={12} className="sub-details">
                                            <Text>{this.props.transactionDetail.signedIP}</Text>
                                        </Col>
                                    </Row>
                                    {/* <br /> */}
                                </div>
                            }

                            <div>
                                <Row id="order-view-contain" style={{ marginBottom: '2%' }}>
                                    <Col xs={12} md={12} lg={12} id="order-id-view">
                                        <Text> {this.locale('Version')}: </Text>
                                    </Col>
                                    <Col xs={12} md={12} className="sub-details">
                                        <Text>{transactionDetail && transactionDetail.formData && transactionDetail.formData.version ? transactionDetail.formData.version : '-'}</Text>
                                    </Col>
                                </Row>
                                {/* <br /> */}
                            </div>
                        </div>
                        :
                        <div id="read-only-details" className="list-content-container">
                            {!isReadOnly && templateSettings.fieldValues && (!templateSettings.fieldValues.HeaderDetailValues ||
                                templateSettings.fieldValues.HeaderDetailValues.length === 0) &&
                                <div id="read-only-details">
                                    {
                                        listingName &&
                                        <div className="list-view-content">
                                            <Row>
                                                <Col xs={24} md={10} lg={10} id="details-view-text">
                                                    <Text> Name </Text>
                                                </Col>
                                                <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                                    <Text>{listingName}</Text>
                                                </Col>
                                            </Row>
                                            <br />
                                        </div>
                                    }
                                    {
                                        address &&
                                        <div className="list-view-content">
                                            <Row >
                                                <Col xs={24} md={10} lg={10} id="details-view-text">
                                                    <Text> Address</Text>
                                                </Col>
                                                <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                                    <Text>{address}</Text>
                                                </Col>
                                            </Row>
                                            <br />
                                        </div>
                                    }
                                    {
                                        phone &&
                                        <div className="list-view-content" >
                                            <Row>
                                                <Col xs={24} md={10} lg={10} id="details-view-text">
                                                    <Text> Phone Number</Text>
                                                </Col>
                                                <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                                    <Text>{phone}</Text>
                                                </Col>
                                            </Row>
                                            <br />
                                        </div>
                                    }
                                    {
                                        templateSettings &&
                                        <div className="list-view-content">
                                            {templateSettings.GSTINNumber &&
                                                <div>
                                                    <Row>
                                                        <Col xs={24} md={10} lg={10} id="details-view-text">
                                                            <Text> GSTIN  </Text>
                                                        </Col>
                                                        <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                                            <Text>{templateSettings.GSTINNumber}</Text>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </div>
                                            }
                                            {templateSettings.PANNumber &&
                                                <div className="list-view-content">
                                                    <Row>
                                                        <Col xs={24} md={10} lg={10} className="sub-details" id="details-view-text">
                                                            <Text> PAN Number </Text>
                                                        </Col>
                                                        <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                                            <Text>{templateSettings.PANNumber}</Text>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            {isReadOnly && this.props.transactionDetail &&
                                this.props.transactionDetail.formData && (
                                    !this.props.transactionDetail.formData.HeaderDetails ||
                                    this.props.transactionDetail.formData.HeaderDetails.length === 0) &&
                                <div id="read-only-details">
                                    {/* {
                                        listingName &&
                                        <div className="list-view-content">
                                            <Row>
                                                <Col xs={24} md={4} lg={4} id="details-view-text">
                                                    <Text> Name </Text>
                                                </Col>
                                                <Col xs={24} md={12} lg={12} className="sub-details" id="personal-details-view">
                                                    <Text>{listingName}</Text>
                                                </Col>
                                            </Row>
                                            <br />
                                        </div>
                                    } */}
                                    {
                                        address &&
                                        <div className="list-view-content">
                                            <Row>
                                                <Col xs={24} md={10} lg={10} id="details-view-text">
                                                    <Text> Address </Text>
                                                </Col>
                                                <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                                    <Text>{address}</Text>
                                                </Col>
                                            </Row>
                                            <br />
                                        </div>
                                    }
                                    {
                                        phone &&
                                        <div className="list-view-content">
                                            <Row>
                                                <Col xs={24} md={10} lg={10} id="details-view-text">
                                                    <Text> Phone Number </Text>
                                                </Col>
                                                <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                                    <Text>{phone}</Text>
                                                </Col>
                                            </Row>
                                            <br />
                                        </div>
                                    }
                                    {
                                        templateSettings &&
                                        <div>
                                            {templateSettings.GSTINNumber &&
                                                <div className="list-view-content">
                                                    <Row>
                                                        <Col xs={24} md={10} lg={10} id="details-view-text">
                                                            <Text> GSTIN  </Text>
                                                        </Col>
                                                        <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                                            <Text>{templateSettings.GSTINNumber}</Text>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </div>
                                            }
                                            {templateSettings.PANNumber &&
                                                <div className="list-view-content" >
                                                    <Row>
                                                        <Col xs={24} md={10} lg={10} className="sub-details" id="details-view-text">
                                                            <Text> PAN Number </Text>
                                                        </Col>
                                                        <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                                            <Text>{templateSettings.PANNumber}</Text>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }

                            {!isReadOnly && templateSettings.fieldValues &&
                                templateSettings.fieldValues.HeaderDetailValues &&
                                templateSettings.fieldValues.HeaderDetailValues.length > 0 &&
                                templateSettings.fieldValues.HeaderDetailValues.map((data, index) => {
                                    if ((data.attributeType !== "Name")) {
                                        return (
                                            <div className="list-view-content" >
                                                <Row>
                                                    <Col xs={24} md={10} lg={10} id="details-view-text">
                                                        <Text> {data.attributeName} </Text>
                                                    </Col>
                                                    <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                                        <Text>{data.attributeValue}</Text>
                                                    </Col>
                                                </Row>
                                                <br />
                                            </div>
                                        );
                                    }
                                })
                            }
                            {isReadOnly && this.props.transactionDetail &&
                                this.props.transactionDetail.formData &&
                                this.props.transactionDetail.formData.HeaderDetails &&
                                this.props.transactionDetail.formData.HeaderDetails.length > 0 &&
                                this.props.transactionDetail.formData.HeaderDetails.map((data, index) => {
                                    if ((data.attributeType !== "Name")) {
                                        return (
                                            <div className="list-view-content">
                                                <Row>
                                                    <Col xs={24} md={10} lg={10} className="sub-details" id="details-view-text">
                                                        <Text> {data.attributeName}: </Text>
                                                    </Col>
                                                    <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                                        <Text>{data.attributeValue}</Text>
                                                    </Col>
                                                </Row>
                                                <br />
                                            </div>
                                        );
                                    }
                                })
                            }
                            {/* <div> */}
                            {this.state.transactionOrderId &&
                                <div className="list-view-content">
                                    <Row>
                                        <Col xs={24} md={10} lg={10} className="sub-details" id="details-view-text">
                                            <Text>{this.locale('Your order No')}: </Text>
                                        </Col>
                                        <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                            <Text>{this.state.transactionOrderId}</Text>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                            }
                            {
                                this.props.transactionDetail && this.props.transactionDetail.createdAt &&
                                <div className="list-view-content">
                                    <Row>
                                        <Col xs={24} md={10} lg={10} className="sub-details" id="details-view-text">
                                            <Text>  {this.locale('Order Date')}: </Text>
                                        </Col>
                                        <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                            <Text>{moment(this.props.transactionDetail.createdAt).format('MMM DD YYYY, hh:mm A')}</Text>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                            }
                            {/* </div> */}
                            {statusDes &&
                                <div className="list-view-content">
                                    <Row>
                                        <Col xs={24} md={10} lg={110} className="sub-details" id="details-view-text">
                                            <Text>{this.locale('Order status')}: </Text>
                                        </Col>
                                        <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                            <Text>{statusDes}</Text>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                            }
                            {
                                this.props.transactionDetail &&
                                this.props.transactionDetail.updatedAt &&
                                this.props.transactionDetail.createdAt &&
                                (moment(this.props.transactionDetail.updatedAt).format('MMM DD YYYY, hh:mm A') !== moment(this.props.transactionDetail.createdAt).format('MMM DD YYYY, hh:mm A')) &&
                                <div className="list-view-content">
                                    <Row>
                                        <Col xs={24} md={10} lg={10} className="sub-details" id="details-view-text">
                                            <Text>  {this.locale('Updated At')}: </Text>
                                        </Col>
                                        <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                            <Text>{moment(this.props.transactionDetail.updatedAt).format('MMM DD YYYY, hh:mm A')}</Text>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                            }
                            {isReadOnly && this.props.transactionDetail &&
                                this.props.transactionDetail.formData &&
                                this.props.transactionDetail.formData.UserForm &&
                                this.props.transactionDetail.formData.UserForm &&
                                Object.keys(this.props.transactionDetail.formData.UserForm).map((key) => {
                                    let removeSpace = key.replace(' ', '')
                                    let lowerCase = removeSpace.toLowerCase()

                                    if (lowerCase && (lowerCase === 'name' || lowerCase === 'phonenumber' || lowerCase === 'email')) {
                                        if (this.props.transactionDetail.formData.UserForm[key]) {
                                            return (
                                               
                                                <div className="list-view-content">
                                                    <Row>
                                                        <Col xs={24} md={10} lg={10} className="sub-details" id="details-view-text">
                                                            <Text>{this.locale(`Customer ${key}`)}: </Text>
                                                        </Col>
                                                        <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                                            <Text>{
                                                                this.props.transactionDetail.formData.UserForm[key] ?
                                                                    `${this.props.transactionDetail.formData.UserForm[key]}` : '-'
                                                            }</Text>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </div>
                                                
                                            )
                                        }
                                    }
                                })
                            }
                            {/* {isReadOnly && this.props.transactionDetail &&
                                this.props.transactionDetail.formData &&
                                this.props.transactionDetail.formData.UserForm &&
                                this.props.transactionDetail.formData.UserForm["Phone Number"] &&
                                <div className="list-view-content">
                                    <Row>
                                        <Col xs={24} md={10} lg={10} className="sub-details" id="details-view-text">
                                            <Text>{this.locale('Customer phone number')}: </Text>
                                        </Col>
                                        <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                            <Text>{this.props.transactionDetail.formData.UserForm["Phone Number"]}</Text>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                            } */}
                            {/* {isReadOnly && this.props.transactionDetail &&
                                this.props.transactionDetail.formData &&
                                this.props.transactionDetail.formData.UserForm &&
                                this.props.transactionDetail.formData.UserForm.email &&
                                <div className="list-view-content">
                                    <Row>
                                        <Col xs={24} md={10} lg={10} className="sub-details" id="details-view-text">
                                            <Text>{this.locale('Customer email')}: </Text>
                                        </Col>
                                        <Col xs={24} md={14} lg={14} className="sub-details" id="personal-details-view">
                                            <Text>{this.props.transactionDetail.formData.UserForm.email}</Text>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                            } */}
                        </div>
                }
            </div>
        )
    }
}

export default ListingDetail