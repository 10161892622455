import React, { Component } from 'react'
import LineItem from './LineItem'
import Avatar from 'react-avatar'

import { Typography, Row, Divider, AutoComplete, Select, Input, Spin, Col, Icon, Modal, Button, Tag, Collapse } from 'antd'
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VList from 'react-virtualized/dist/commonjs/List';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import _ from 'lodash'
import { cloneDeep } from 'lodash'
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import ProductDetails from './ProductDetails'
import EcommerceForm from '.';
import { isBrowser, isMobile, MobileView } from 'react-device-detect';

const fakeDataUrl = 'https://randomuser.me/api/?results=5&inc=name,gender,email,nat&noinfo';


const { Option } = Select;
const { Panel } = Collapse;
let showTitle = true;
let title = 'no';
let finalTitle = 'yes';

let remoteRowCount

let list = [];

const STATUS_LOADING = 1;
const STATUS_LOADED = 2;


class LineItems extends Component {

    constructor(props) {
        super(props)
        this.state = {
            productitems: [],
            duplicateProductItems: [],
            selectedCategoryId: ['All'],
            loader: false,
            productSearch: "",
            categoryList: [],
            categoryListIdWithoutAll: [],
            data: [],
            loading: false,
            loadedRowCount: 0,
            loadedRowsMap: {},
            loadingRowCount: 0,
            iscategoryDropdownVisible: false,
            isOpenproductDetailModal: false,
            selectedProductDetail: {},
            isOpenFilter: false,
            subCategoryList: [],
            selectedSubcategoryId: '',
            activePanel: null,
            classactive: false
        }
    }

    componentDidMount() {
        this.setCatagoryList(this.props)
        this.setState({
            productitems: this.props.items,
            productitems: this.props.items, duplicateProductItems: this.props.items,
            selectedCategoryId: this.props.selectedCategoryId
        })
    }
    componentWillReceiveProps(newProps) {
        if (this.props.items !== newProps.items) {
            this.setState({
                productitems: newProps.items, duplicateProductItems: newProps.items
            }, () => {
            })
        }
        if (this.props.selectedCategoryId !== newProps.selectedCategoryId) {
            this.setState({ selectedCategoryId: newProps.selectedCategoryId })
        }
        if (this.props.categories !== newProps.categories) {
            this.setCatagoryList(newProps)
        }
    }

    componentDidUpdate() {
        if (this.list) {
            this.list.forceUpdateGrid();
        }
    }


    setCatagoryList = (propsValue) => {
        console.log('setCatagoryList', propsValue)
        if (propsValue) {
            let data = []
            let catagoryIdWithoutAll = []
            let allData = {
                "product_category_id": "All",
                "product_category_name": 'All',
                // "category_image": [
                //     {
                //         "uid": "c92dcdb9-b307-3167-8c74-617e639e5f85",
                //         "url": "https://firebasestorage.googleapis.com/v0/b/totely-dev1.appspot.com/o/ecommerce%2F6517f139-5e26-488b-a850-b654bc367559.jpg?alt=media&token=ff844866-7a1e-4c7a-aa31-ede8dbdd2d21",
                //         "name": "6517f139-5e26-488b-a850-b654bc367559.jpg",
                //         "status": "done"
                //     }
                // ]
            }
            // data.push(<Option key={allData.category_id}>{allData.category_name}</Option>)
            // data.push(allData)
            if (propsValue.categories.length > 0) {
                propsValue.categories.map((category, i) => {
                    if (category) {
                        data.push(category)
                        // data.push(<Option key={category.product_category_id}>{category.product_category_name}</Option>)
                        if (category.product_category_id !== "All") {
                            catagoryIdWithoutAll.push(category.product_category_id)
                        }
                        this.setState({
                            categoryList: data,
                            categoryListIdWithoutAll: catagoryIdWithoutAll,
                        })
                    }

                });


            } else {
                this.setState({
                    categoryList: data,
                    categoryListIdWithoutAll: catagoryIdWithoutAll,
                })
            }
        }
    }

    handleChange = (params, i) => {
        this.setState({ activePanel: i + 1 })
        let value = params.product_category_id
        if (value !== 'search') {
            console.log('handleChange', params, this.state.selectedCategoryId)
            let allOption = 'All'
            let selectedValue = []
            let checkArray = this.state.categoryListIdWithoutAll

            //check if all value present in state and filter selected option
            let isAllOptionPresentInSelectedArray = _.includes(value, allOption)
            let isAllOptionPresentInState = _.includes(this.state.selectedCategoryId, allOption)
            console.log('isAllOptionPresentInSelectedArray', isAllOptionPresentInSelectedArray, isAllOptionPresentInState)
            //If "ALL" option is present in selected array & state array
            if (isAllOptionPresentInSelectedArray === true && isAllOptionPresentInState === true) {

                let temp = []
                temp.push(value)
                let isPresent = temp.filter((item) => {
                    return checkArray.indexOf(item.trim()) != -1
                })
                console.log('isPresent', isPresent, temp, checkArray)
                //other the All value present
                if (isPresent && isPresent.length > 0) {
                    selectedValue = isPresent
                }
                //else push the All value
                else {
                    selectedValue = value
                }
            }

            //All value not present in state and present in checked
            else if (isAllOptionPresentInState === false && isAllOptionPresentInSelectedArray === true) {
                selectedValue.push(allOption)
            }

            //otherwise
            else {
                selectedValue = value
            }
            console.log('selectedValue11', selectedValue)
            this.setState({
                selectedCategoryId: selectedValue,
                loadedRowCount: 0,
                loadedRowsMap: {},
                loadingRowCount: 0,
                productSearch: ""
            }, () => {
                console.log('set selectedValue', selectedValue)
                if (params && params.product_subcategories && params.product_subcategories.length && value !== 'All') {
                    this.setState({
                        subCategoryList: params.product_subcategories
                    })
                } else {
                    this.setState({
                        subCategoryList: []
                    })
                }
                if (this.props.onDeSearchCategories) {
                    this.props.onSelectedCategory(selectedValue)
                }
            })
        }
    }
    onDeselect = (event) => {
        this.setState({ selectedCategoryId: event }, () => {
            if (this.props.onDeSearchCategories && this.state.iscategoryDropdownVisible === false) {
                this.setState({
                    productSearch: "", loadedRowCount: 0,
                    loadedRowsMap: {},
                    loadingRowCount: 0,
                }, () => {
                    this.props.onDeSearchCategories(event)
                })
            }
        })
    }

    onDropdownVisibleChange = (event) => {
        this.setState({ iscategoryDropdownVisible: event }, () => {
            if (event === false) {
                if (this.props.onSelectedCategory) {
                    this.setState({
                        productSearch: "", loadedRowsMap: {},
                        loadingRowCount: 0, loadingRowCount: 0
                    }, () => {
                        this.props.onSelectedCategory(this.state.selectedCategoryId)
                    })
                }
            }
        })
    }

    onSearchProducts = (event) => {
        console.log('onSearchProducts', event.target.value)
        this.setState({
            productSearch: event.target.value,
            activePanel: event.target.value ? 1 : null
            //  loadedRowCount: 0,
            // loadedRowsMap: {},
            // loadingRowCount: 0,
        }, () => {
            if (this.props.onSearchProducts) {
                this.props.onSearchProducts(this.state.productSearch)
            }
        })
    }


    _isRowLoaded = ({ index }) => {
        const { loadedRowsMap } = this.state;
        return !!loadedRowsMap[index]; // STATUS_LOADING or STATUS_LOADED
    }

    _loadMoreRows = ({ startIndex, stopIndex }) => {
        const { loadedRowsMap, loadingRowCount } = this.state;
        const increment = stopIndex - startIndex + 1;

        for (var i = startIndex; i <= stopIndex; i++) {
            loadedRowsMap[i] = STATUS_LOADING;
        }

        this.setState({
            loadingRowCount: loadingRowCount + increment,
        });

        const timeoutId = setTimeout(() => {
            const { loadedRowCount, loadingRowCount } = this.state;

            for (var i = startIndex; i <= stopIndex; i++) {
                loadedRowsMap[i] = STATUS_LOADED;
            }

            this.setState({
                loadingRowCount: loadingRowCount - increment,
                loadedRowCount: loadedRowCount + increment,
            });

            this.props.onLoadMoreProducts(startIndex, stopIndex)
        }, 1000 + Math.round(Math.random() * 2000));

    }

    _rowRenderer = ({ index, key, style }) => {
        const {
            defaultPriceUnit,
            ...functions
        } = this.props;
        const { loadedRowsMap } = this.state;

        const row = this.state.productitems[index];
        let content;

        if (loadedRowsMap[index] === STATUS_LOADED) {
            content = row.product_name;
        } else {
            content = (
                <div style={{ width: row.size }} />
            );
        }
        return (
            <div key={key} style={style}>
                <LineItem
                    productImageView={this.props.productImageView}
                    key={index}
                    categoryIndex={index}
                    productIndex={index}
                    name={row.product_name}
                    image={row.product_image}
                    description={row.product_description}
                    quantities={row}
                    defaultPriceUnit={defaultPriceUnit}
                    {...functions}
                />
            </div>
        );
    }

    locale = (string) => {

        if (this.props.templateSettings.LocalizationDetailValues &&
            this.props.templateSettings.LocalizationDetailValues.length > 0 &&
            this.props.templateSettings.LocalizationDetailValues.find(item => item.DefaultValue === string)) {
            return this.props.templateSettings.LocalizationDetailValues.find(item => item.DefaultValue === string).AlternateValue
        } else {
            return string
        }
    }

    onOpenProductDetailModal = (item) => {
        this.setState({ isOpenproductDetailModal: true, selectedProductDetail: item })
    }
    onCloseProductdetailModal = () => {
        this.setState({ isOpenproductDetailModal: false, selectedProductDetail: {} })
    }
    onQuantityChange = (item, index, option) => {

        let product = cloneDeep(item)
        // let quantity = Math.abs(value)\
        if (product.hasOwnProperty('selected_quantity')) {
            if (option === "minus") {
                product['selected_quantity'] = product.selected_quantity - 1
            } else {
                product['selected_quantity'] = product.selected_quantity + 1
            }
        } else {
            product['selected_quantity'] = 1
        }

        this.setState({ selectedProductDetail: product }, () => {
            // this.props.changeHandler(item, index, product.selected_quantity, "productDetail")
        })
    }
    onAddToCart = (item) => {
        this.props.onAddToCartProduct(item)
        this.setState({ selectedProductDetail: {} }, () => {
            this.onCloseProductdetailModal()
        })

    }


    onclickSaveFilter = () => {
        if (this.props && this.props.onSelectedCategory && this.state.selectedSubcategoryId) {
            this.setState({ isOpenFilter: false }, () => {
                this.props.onSelectedCategory(this.state.selectedCategoryId, this.state.selectedSubcategoryId)
            })
        }
    }

    clearFilter = () => {
        if (this.props && this.props.onSelectedCategory) {
            this.setState({ isOpenFilter: false, selectedSubcategoryId: '' }, () => {
                this.props.onSelectedCategory(this.state.selectedCategoryId)
            })
        }
    }

    filterFooter = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                <Button type="primary"
                    id="filter-cancel-button"
                    style={{ width: 90, height: 40, flexDirection: 'row', alignItems: 'center', fontSize: 15, display: 'flex', justifyContent: 'center', backgroundColor: '#0000' }}
                    disabled={false}
                    onClick={() => this.clearFilter()}>
                    {'Clear'}
                </Button>
                <Button type="primary"
                    style={{ width: 90, height: 40, flexDirection: 'row', alignItems: 'center', fontSize: 15, display: 'flex', justifyContent: 'center' }}
                    disabled={false}
                    onClick={() => this.onclickSaveFilter()}>
                    {'Done'}
                </Button>
            </div>
        )
    }

    classchange() {
        this.setState({ classactive: true });
        this.props.onClassActive(true)
    }

    render = () => {
        const {
            items,
            defaultPriceUnit,
            addHandler,
            onSearchCategories,
            onSelectedCategory,
            templateSettings,
            scrollIntoView,
            ...functions
        } = this.props;
        const { productitems } = this.state;
        const { Title, Text } = Typography;
        const Option = AutoComplete.Option;
        console.log(this.state.classactive, 'bclassactive')
        console.log('subCategoryList', this.state.subCategoryList, this.state.selectedCategoryId)
        // this.state.categoryList[0] = <Option key={'All'}>{this.props.templateSettings ? this.locale('All') : 'All'}</Option>
        return (
            <div>
                <div style={{ marginBottom: '8px' }}>
                    <Title level={4} className="pubilc-url-title" style={{ marginBottom: '2%' }}>
                        {(templateSettings.LocalizationDetailValues &&
                            templateSettings.LocalizationDetailValues.length > 0 &&
                            templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Shop') ?
                            templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Shop').AlternateValue : 'Shop')}
                    </Title>
                    <div>
                        <Row id="filter-row-view" className={this.state.classactive ? 'search-bar-container' : 'search-product-input'} style={{ marginBottom: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginRight: 0, marginLeft: 0 }}>
                            <Input
                                placeholder=
                                {(templateSettings.LocalizationDetailValues &&
                                    templateSettings.LocalizationDetailValues.length > 0 &&
                                    templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Search products') ?
                                    templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Search products').AlternateValue : "search products")}
                                style={{ marginBottom: '8px', width: '50%' }}

                                onChange={(event) => this.onSearchProducts(event)}
                                onClick={() => {
                                    console.log(isMobile, isBrowser, 'bmobile')
                                    if (isMobile && !isBrowser) {
                                        scrollIntoView('menuSection');
                                        this.classchange();
                                    }
                                }}
                                allowClear={true}
                                value={this.state.productSearch}
                            />
                            {this.state.classactive &&
                                <MobileView>
                                    <Button className='cancel-bar-container'
                                        onClick={() => {
                                            if (isMobile && !isBrowser) {
                                                this.setState({ classactive: false });
                                                this.props.onClassActive(false)
                                            }
                                        }}
                                    >
                                        <Text style={{ color: 'black' }}>Cancel</Text></Button>
                                </MobileView>
                            }
                            {/* {
                            // this.state.selectedCategoryId && this.state.selectedCategoryId[0] !== 'All' &&
                            this.state.subCategoryList && this.state.subCategoryList.length > 0 &&
                            <Icon
                                onClick={() => this.setState({ isOpenFilter: true })}
                                style={{ fontSize: 24, marginRight: 10, color: 'grey', marginLeft: 15 }}
                                type="filter" />
                        } */}
                        </Row>

                    </div>


                    <div id="accordion-view">
                        {this.state.categoryList && this.state.categoryList.length > 0 &&
                            this.state.categoryList.map((value, i) => (
                                <Collapse
                                    activeKey={this.state.activePanel}
                                    expandIconPosition={"right"}
                                    onChange={(val) =>
                                        val && val.length > 0 ? this.handleChange(value, i) : this.setState({ activePanel: null })
                                    }
                                >
                                    <Panel header={value.product_category_name} key={i + 1}

                                        onChange={val => console.log('Collapse', val)}
                                    >
                                        {(this.state.productitems && this.state.productitems.length > 0) ?
                                            <div style={{ width: '100%', flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between' }}>
                                                {
                                                    this.state.productitems.map((row, index) => {
                                                        return (

                                                            <div className="product-list-details" id="product-view-container" style={{ marginTop: 0, width: '32%' }}>
                                                                {
                                                                    !this.state.productSearch && this.state.selectedCategoryId.indexOf("All") > -1 &&
                                                                    <div>
                                                                        {
                                                                            index === 0 && row.listing_product_category &&
                                                                            row.listing_product_category.category_name &&
                                                                            <div>
                                                                                <h5 className="product-title-view">{row.listing_product_category.category_name}  </h5>
                                                                                <Divider />
                                                                            </div>
                                                                        }

                                                                        {
                                                                            index !== 0 && index + 1 <= this.state.productitems.length &&
                                                                            this.state.productitems[index].category_id !==
                                                                            this.state.productitems[index - 1].category_id &&
                                                                            <div id="product-view-container">
                                                                                <h5 className="product-title-container">{row.listing_product_category.category_name}  </h5>
                                                                                <Divider />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    row && row.hide_from_catalog === false &&
                                                                    <div onClick={() => this.onOpenProductDetailModal(row)}>
                                                                        <LineItem
                                                                            productImageView={this.props.productImageView}
                                                                            key={index}
                                                                            categoryIndex={index}
                                                                            productIndex={index}
                                                                            name={row.product_name}
                                                                            image={row.product_image}
                                                                            description={row.product_description}
                                                                            quantities={row}
                                                                            defaultPriceUnit={defaultPriceUnit}
                                                                            catalogDiscount={this.props.catalogDiscount}
                                                                            {...functions}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>



                                                        )
                                                    })
                                                }

                                                {/* <InfiniteLoader
                                isRowLoaded={this._isRowLoaded}
                                loadMoreRows={this._loadMoreRows}
                                rowCount={productitems.length}>
                                {({ onRowsRendered, registerChild }) => (
                                    <AutoSizer disableHeight>
                                        {({ width, height }) => (
                                            <List
                                                ref={registerChild}
                                                height={productitems.length >= this.props.productLoadListLimit ?
                                                    1000 : productitems.length * 119}
                                                onRowsRendered={onRowsRendered}
                                                rowCount={productitems.length}
                                                rowHeight={119}
                                                rowRenderer={this._rowRenderer}
                                                width={width}
                                                overscanRowCount={0}
                                                data={productitems}
                                            />
                                        )}
                                    </AutoSizer>
                                )}
                            </InfiniteLoader> */}
                                            </div>
                                            :
                                            <div style={{
                                                marginTop: "3%", marginLeft: "6%", marginBottom: '5%',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}> <span > No products matched the search keywords  </span></div>
                                        }
                                    </Panel>
                                    {/* </div> */}
                                </Collapse>
                                // <div id="category-container-view"
                                //     onClick={() => this.handleChange(value)}
                                //     style={
                                //         (
                                //             this.state.selectedCategoryId === value.product_category_id ||
                                //             (this.state.selectedCategoryId.indexOf("All") > -1 && this.state.selectedCategoryId[0] === value.product_category_id)
                                //         ) ?
                                //             { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '20%', marginBottom: 20, boxShadow: '2px 2px 2px 2px lightgray', borderRadius: 5,paddingTop:10,paddingBottom:10,position:'relative',bottom:10 }
                                //             :
                                //             { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '20%', marginBottom: 20 }
                                //     }
                                // >
                                //     {value &&
                                //         value.category_image &&
                                //         value.category_image.length > 0 &&
                                //         value.category_image[0].url ?

                                //             <img style={
                                //                 { width: 70, height: 70, marginRight: 10, borderRadius: '50%' }
                                //             }
                                //                 //  onClick={this.handleShowDialog} 
                                //                 src={value.category_image[0].url} />

                                //         :
                                //         <Avatar
                                //             style={
                                //                 { marginRight: 10, color: 'white' }
                                //             }
                                //             size={'70px'}
                                //             className="avatarListingName"
                                //             fgColor="#FF0000" name={value.product_category_name} round={true} maxInitials={1} />
                                //     }
                                //     <div style={{ marginTop: 8, textAlign: 'center', fontWeight: '600' }}>{value.product_category_name}</div>
                                // </div>

                            ))
                        }
                    </div>


                    {/* {(this.state.productitems && this.state.productitems.length > 0) ?
                        <div style={{ width: '100%', flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between' }}>
                            {
                                this.state.productitems.map((row, index) => {
                                    return (

                                        <div className="product-list-details" id="product-view-container" style={{ marginTop: '4%', width: '48%' }}>
                                            {
                                                !this.state.productSearch && this.state.selectedCategoryId.indexOf("All") > -1 &&
                                                <div>
                                                    {
                                                        index === 0 && row.listing_product_category &&
                                                        row.listing_product_category.category_name &&
                                                        <div>
                                                            <h5 className="product-title-view">{row.listing_product_category.category_name}  </h5>
                                                            <Divider />
                                                        </div>
                                                    }

                                                    {
                                                        index !== 0 && index + 1 <= this.state.productitems.length &&
                                                        this.state.productitems[index].category_id !==
                                                        this.state.productitems[index - 1].category_id &&
                                                        <div id="product-view-container">
                                                            <h5 className="product-title-container">{row.listing_product_category.category_name}  </h5>
                                                            <Divider />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            
                                        </div>



                                    )
                                })
                            }

                        </div>
                        :
                        <div style={{
                            marginTop: "3%", marginLeft: "6%", marginBottom: '5%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}> <span > No products matched the search keywords  </span></div>
                    } */}
                </div>

                <Modal
                    className="publicView modal-public-view"
                    title={'Filter'}
                    visible={this.state.isOpenFilter}
                    //  onOk={this.onClickOk}
                    onCancel={() => this.setState({ isOpenFilter: false })}
                    footer={this.filterFooter()}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {this.state.subCategoryList && this.state.subCategoryList.length > 0 &&
                            this.state.subCategoryList.map(val => (
                                <Tag
                                    id="filter-popup-view"
                                    style={{ cursor: "pointer", width: '48%', textAlign: 'center', paddingTop: 8, paddingBottom: 8, marginRight: 2, paddingRight: 0 }}
                                    color={val.product_subcategory_id === this.state.selectedSubcategoryId ? 'red' : '#fff'}
                                    onClick={() => this.setState({ selectedSubcategoryId: val.product_subcategory_id })}
                                >
                                    {val.product_subcategory_name}
                                </Tag>
                            ))

                        }
                    </div>
                </Modal>

                {this.state.isOpenproductDetailModal &&
                    <ProductDetails
                        isOpenproductDetailModal={this.state.isOpenproductDetailModal}
                        onCloseProductdetailModal={this.onCloseProductdetailModal}
                        selectedProductDetail={this.state.selectedProductDetail}
                        defaultPriceUnit={defaultPriceUnit}
                        locale={this.locale}
                        onQuantityChange={this.onQuantityChange}
                        onAddToCart={this.onAddToCart}
                        catalogDiscount={this.props.catalogDiscount}
                    />
                }
            </div>
        )
    }
}

export default LineItems