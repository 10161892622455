import React, { Component } from "react";
import { Typography, Row, Col, Input, Divider, Spin, Layout, Icon, Badge, Button, Popconfirm, message } from 'antd'

import UserForm from "./UserForm";
import LineItems from './LineItems';
import Summary from './Summary';
import ListingDetail from './ListingDetail'
import OrderDetails from './OrderDetails'
import DeliveryDetails from './DeliveryDetails'
import { cloneDeep } from 'lodash'


import _ from 'lodash'

import {
    GET_LISTING_CATEGORIES, GET_LISTING_PRODUCTS_CATEGORIES,
    GET_TRANSACTION_TEMPLATE_LISTING_DETAIL_BY_ID, GET_LISTING_PRODUCTS_BY_CATEGORY_ID, GET_STATUS_DESC,
    GET_PRODUCTS_BY_CATEGORY_ID
} from '../../graphQl/queries';
import {

    GET_CATAGORIES_BY_TEMPLATE_ID_SUB
} from '../../graphQl/subscriptions';

import client from '../../apollo/apollo';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
// import { StickyContainer, Sticky } from 'react-sticky';
// import { GET_LISTING_CATEGORIES, GET_LISTING_PRODUCTS_BY_CATEGORY_ID } from '../../graphQl/queries';

const { Header, Footer, Sider, Content } = Layout;
let emptyData = "-"

const { TextArea } = Input;
let totalPriceWithTax = 0
let productLoadListLimit = 10

class EcommerceForm extends Component {
    constructor(props) {
        super(props);
        this.searchRef = React.createRef()
        this.listingDetailsRef = React.createRef()
        this.cartRef = React.createRef()

        this.state = {

            // Products
            lineItems: [],
            lineItemId: '',
            shorturlData: {},
            shorturlFormDataTotal: {},
            lineItemsFinal: [],

            //order summary
            lineOrderSummary: [],
            description: "",
            listingDetail: {},
            defaultPriceUnit: '',
            CGSTValue: "",
            SGSTValue: "",
            templateSettings: {},
            // totalPriceWithTax: 0

            // Categories
            categories: [],
            duplicateCategories: [],
            categoryId: [],
            searchCategories: '%%',
            duplicateLineItems: [],
            duplicateCategoryId: [],
            loading: false,
            orderSummary: [],
            showHeaderView: false,
            listingName: "",
            editedBySeller: false,
            discountFromSeller: [],
            additionalFeesFromSeller: [],
            productListOffset: 0,
            loadMoreLoading: false,
            emptylineItem: false,
            isCategorySelected: false,
            productSearchText: "",
            productStartIndex: 0,
            productEndIndex: productLoadListLimit,
            formListingCountry: "in",
            deliveryFee: "",
            showDeliveryMsgModal: true,
            discountPromotionDetails: {},
            statusDes: '',
            catalogDiscount: '',
            subcategoryId: '',
            classactive: false,
            openCartmodal: false,
            templateData: {},
            paylater: false
        }
        if (props.getCurrentPoint) {
            props.getCurrentPoint(this.getFormData.bind(this));
        }

    }

    componentDidMount() {

        if (this.props && this.props.templateData) {
            console.log('templateData', this.props)
            this.setState({ templateData: this.props.templateData })
        }

        //find out country code from listing phone number to display in phone number field
        if (this.props.formListingDetail && this.props.formListingDetail.listing &&
            this.props.formListingDetail.listing.phoneNumber) {
            const phoneNumber = parsePhoneNumberFromString(this.props.formListingDetail.listing.phoneNumber)
            if (phoneNumber && phoneNumber.country) {
                this.setState({ formListingCountry: phoneNumber.country.toLowerCase() })
            } else {
                this.setState({ formListingCountry: 'in' })
            }
        }
        else {
            this.setState({ formListingCountry: 'in' })
        }

        //when viewing data via short url
        if (this.props.transactionData) {

            //description
            if (this.props.transactionData.formData && this.props.transactionData.formData.Description &&
                this.props.transactionData.formData.Description.description) {
                this.setState({ description: this.props.transactionData.formData.Description.description })
            }

            if (this.props.transactionData.formData.editedBySeller) {


                if (this.props.transactionData.formData.editedBySellerValues &&
                    this.props.transactionData.formData.editedBySellerValues.Discounts &&
                    this.props.transactionData.formData.editedBySellerValues.Discounts.length > 0) {
                    this.setState({ discountFromSeller: this.props.transactionData.formData.editedBySellerValues.Discounts });
                }

                if (this.props.transactionData.formData.editedBySellerValues &&
                    this.props.transactionData.formData.editedBySellerValues.AdditionalFees &&
                    this.props.transactionData.formData.editedBySellerValues.AdditionalFees.length > 0) {
                    this.setState({ additionalFeesFromSeller: this.props.transactionData.formData.editedBySellerValues.AdditionalFees });
                }

                //order summary
                if (this.props.transactionData.formData.editedBySellerValues &&
                    this.props.transactionData.formData.editedBySellerValues.editedLineItems) {
                    if (this.props.transactionData.formData.editedBySellerValues.editedLineItems.length > 0) {
                        this.setState({
                            lineItems: this.props.transactionData.formData.editedBySellerValues.editedLineItems,
                            duplicateLineItems: this.props.transactionData.formData.editedBySellerValues.editedLineItems,
                            orderSummary: this.props.transactionData.formData.editedBySellerValues.editedLineItems,
                        }, () => {
                            this.getOrderSummary()
                        })
                        //price total
                        if (this.props.transactionData.formData.editedBySellerValues &&
                            this.props.transactionData.formData.editedBySellerValues.editedTotal) {
                            var delFeeadded = this.props.transactionData.formData.editedBySellerValues.editedTotal;
                            delFeeadded.DeliveryFee = this.props.transactionData.formData.Total.DeliveryFee;
                            if (this.props.transactionData.formData.Total.DeliveryFee && this.props.transactionData.formData.Total.DeliveryFee > 0) {
                                delFeeadded.TotalPriceAfterTax = delFeeadded.TotalPriceAfterTax + this.props.transactionData.formData.Total.DeliveryFee;
                            }
                            this.setState({ shorturlFormDataTotal: delFeeadded }, () => {
                            })
                        }
                    } else {
                        if (this.props.transactionData.formData && this.props.transactionData.formData.Total) {
                            this.setState({ shorturlFormDataTotal: this.props.transactionData.formData.Total })
                        }
                        this.setState({
                            lineItems: this.props.transactionData.formData.LineItems,
                            duplicateLineItems: this.props.transactionData.formData.LineItems,
                            orderSummary: this.props.transactionData.formData.LineItems,
                        }, () => {
                            this.getOrderSummary()
                        })
                    }

                }
                this.setState({ editedBySeller: true });
            } else {
                //price total
                if (this.props.transactionData.formData && this.props.transactionData.formData.Total) {
                    this.setState({ shorturlFormDataTotal: this.props.transactionData.formData.Total })
                }
                //order summary
                if (this.props.transactionData.formData && this.props.transactionData.formData.LineItems) {
                    this.setState({
                        lineItems: this.props.transactionData.formData.LineItems,
                        duplicateLineItems: this.props.transactionData.formData.LineItems,
                        orderSummary: this.props.transactionData.formData.LineItems,
                    }, () => {
                        this.getOrderSummary()
                    })
                }
            }


            this.setState({ shorturlData: this.props.transactionData })
        } else {
            this.setState({ shorturlData: {} })
            this.getCategories(this.state.categoryName, 'initial');
            this.getProducts(null)
            // this.getProductCategories();
        }

        // listing info
        if (this.props.templateId) {
            this.getTemplateListingDetail()
        }

        if (this.props && this.props.templateId && this.props.transactionData && this.props.transactionData.currentStatus) {
            this.getStatusDesc(this.props.templateId, this.props.transactionData.currentStatus)
        }

        this.scrollToViewSection(this.props)

    }

    componentWillReceiveProps(newProps) {

        if (newProps && newProps.openCartModal) {
            console.log('newProps', newProps.openCartModal)
            this.setState({ openCartmodal: true })
        }

        if (newProps.scrollViewSection !== this.props.scrollViewSection) {
            this.scrollToViewSection(newProps)
        }

    }

    scrollToViewSection = (props) => {
        console.log('clicked', props)
        if (props.scrollViewSection) {
            if (props.scrollViewSection === "homeSection") {
                this.listingDetailsRef.current.scrollIntoView({ behavior: 'auto' });
            }
            if (props.scrollViewSection === "menuSection") {
                this.searchRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            if (props.scrollViewSection === "cartSection") {
                this.cartRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }

    componentWillMount() {
        if (this.props.lineItems) {
            this.setState({
                lineItems: this.props.lineItems,
                duplicateLineItems: this.props.lineItems,
                orderSummary: this.props.lineItems,
                lineItemId: this.props.lineItems.length
            })
        }
    }

    getStatusDesc = (tempId, status) => {
        client.query({
            query: GET_STATUS_DESC,
            variables: {
                transactionTemplateId: tempId,
                statusName: status
            }
        })
            .then(res => {
                if (res.data &&
                    res.data.transaction_template_status &&
                    res.data.transaction_template_status[0] &&
                    res.data.transaction_template_status[0].statusDesc) {
                    this.setState({ statusDes: res.data.transaction_template_status[0].statusDesc })
                }
            })
            .catch(err => {
            })
    }

    getTemplateListingDetail = () => {
        client.query({
            query: GET_TRANSACTION_TEMPLATE_LISTING_DETAIL_BY_ID,
            variables: { id: this.props.templateId }
        })
            .then((res) => {
                if (res && res.data && res.data.transaction_template.length > 0) {
                    let value = res.data.transaction_template[0]
                    let listing = value.listing && value.listing.length > 0 && value.listing[0].name ?
                        value.listing[0].name : ""

                    this.setState({
                        listingDetail: res.data.transaction_template[0],
                        listingName: listing
                    })

                    // get default price set from template settings
                    if (res.data.transaction_template[0]) {
                        if (res.data.transaction_template[0].hasOwnProperty('templateSettings')) {
                            if (res.data.transaction_template[0].templateSettings.hasOwnProperty('priceUnit')) {
                                if (res.data.transaction_template[0].templateSettings.hasOwnProperty('priceUnit') != ''
                                    &&
                                    res.data.transaction_template[0].templateSettings.hasOwnProperty('priceUnit') != null) {
                                    let priceUnit = res.data.transaction_template[0].templateSettings.priceUnit;
                                    this.setState({
                                        defaultPriceUnit: priceUnit
                                    })
                                }

                            }
                        }

                        if (res.data.transaction_template[0].hasOwnProperty('templateSettings')) {
                            if (res.data.transaction_template[0].templateSettings.hasOwnProperty('catalogDiscount')) {
                                if (res.data.transaction_template[0].templateSettings.hasOwnProperty('catalogDiscount') != ''
                                    &&
                                    res.data.transaction_template[0].templateSettings.hasOwnProperty('catalogDiscount') != null) {
                                    let catalogDiscount = res.data.transaction_template[0].templateSettings.catalogDiscount;
                                    this.setState({
                                        catalogDiscount: catalogDiscount
                                    })
                                }

                            }
                        }

                        // CGSTValue & SGSTValue
                        let data = res.data.transaction_template[0].templateSettings
                        if (data.fieldValues) {
                            this.setState({ templateSettings: data.fieldValues }, () => {
                                this.getDiscountPromotion();
                            });
                        }

                        if (data.hasOwnProperty('CGSTValue')) {
                            this.setState({
                                CGSTValue: data.CGSTValue ? data.CGSTValue : ""
                            })
                        }
                        if (data.hasOwnProperty('SGSTValue')) {
                            this.setState({
                                SGSTValue: data.SGSTValue ? data.SGSTValue : ""
                            })
                        }
                    }
                }
                else {
                    this.setState({ listingDetail: {} })
                }
            }).catch((err) => {
                console.log(err, 'err')
                this.setState({ loading: false })
            })
    }

    /******************************** Category **********************************/

    // Get categories
    getProductCategories() {
        client.query({
            query: GET_LISTING_PRODUCTS_CATEGORIES,
            variables: {
                listingId: this.props.listingId,
                templateId: this.props.templateId
            }
        })
            .then((res) => {
                if (res && res.data && res.data.listing_product_categories.length > 0) {
                    this.setState({
                        lineItems: res.data.listing_product_categories,
                        duplicateLineItems: res.data.listing_product_categories
                    })
                }
            }).catch((err) => {
                this.setState({ loading: false })
            })
    }

    /******************************** Onchange Functions ********************************/
    getOrderSummary(option) {
        console.log('getOrderSummary123')
        let lineOrderSummaryItems = [];
        this.state.orderSummary.map((product, i) => {
            console.log(product, 'productproduct')
            // if (details.hasOwnProperty('listing_products')) {
            // details.listing_products.map((product, i) => {
            if (product) {
                // product.product_config.map((item, j) => {
                if (product && product.hasOwnProperty('selected_quantity') && product.selected_quantity !== null) {
                    let cgstPrice
                    let sgstPrice
                    let igstPrice
                    if (product.selected_quantity != 0) {
                        if (this.state.templateSettings && this.state.templateSettings.taxLevel &&
                            this.state.templateSettings.taxLevel === "ItemLevel") {
                            let taxData = this.state.templateSettings.taxesValues
                            if (product.product_taxes) {
                                let amt = product.discountedUnitPrice ?
                                    product.total :
                                    (product.selected_quantity * product.product_list_price).toFixed(2)
                                let tax = product.product_taxes
                                if (tax.CGST && taxData.CGST) {
                                    let percentageValue = (parseInt(tax.CGST) / 100)
                                    cgstPrice = amt * percentageValue;
                                }
                                if (tax.IGST && taxData.IGST) {
                                    let percentageValue = (parseInt(tax.SGST) / 100)
                                    igstPrice = amt * percentageValue;
                                }
                                if (tax.SGST && taxData.SGST) {
                                    let percentageValue = (parseInt(tax.SGST) / 100)
                                    sgstPrice = amt * percentageValue;
                                }
                            }
                        }
                        // push the line items
                        if (this.state.discountPromotionDetails.categoryLevel &&
                            this.state.discountPromotionDetails.categoryLevel.find(o => o.category === product.listing_product_category.category_name)) {
                            let isPercent = false;
                            let valueTocalculate = 0;
                            let amountToreduce = 0;
                            var productItem = this.state.discountPromotionDetails.categoryLevel.find(o => o.category === product.listing_product_category.category_name);
                            if (productItem.value.includes('%')) {
                                isPercent = true;
                                valueTocalculate = productItem.value.replace('%', '');
                                valueTocalculate = parseFloat(valueTocalculate);
                                amountToreduce = (product.selected_quantity * product.product_list_price) * valueTocalculate / 100;
                            } else {
                                isPercent = false;
                                amountToreduce = product.selected_quantity * parseFloat(productItem.value);
                            }
                            lineOrderSummaryItems.push({
                                "name": product.product_name ? product.product_name : "",
                                "image": product.product_image,
                                "unit_price": product.product_list_price,
                                "qty": product.selected_quantity,
                                "quantity": product.selected_quantity,
                                "total": (product.selected_quantity * product.product_list_price).toFixed(2),
                                "id": product.id,
                                "item_index": i,
                                "tax_values": {
                                    CGST: cgstPrice,
                                    IGST: igstPrice,
                                    SGST: sgstPrice,
                                },
                                "discountPromotion": {
                                    discount: productItem.value,
                                    calculatedDiscount: amountToreduce
                                },
                                "hasVariant": product.hasVariant,
                                "product_variant_option": product.product_variant_option
                            })
                        } else {
                            lineOrderSummaryItems.push({
                                "name": product.product_name ? product.product_name : "",
                                "image": product.product_image,
                                "unit_price": product.discountedUnitPrice ?
                                    product.discountedUnitPrice : product.product_list_price,
                                "qty": product.selected_quantity,
                                "quantity": product.selected_quantity,
                                "total": product.discountedUnitPrice ?
                                    product.total : (product.selected_quantity * product.product_list_price).toFixed(2),
                                "id": product.id,
                                "item_index": i,
                                "tax_values": {
                                    CGST: cgstPrice,
                                    IGST: igstPrice,
                                    SGST: sgstPrice,
                                },
                                "hasVariant": product.hasVariant,
                                "product_variant_option": product.product_variant_option
                            })
                        }
                    }
                }
                // })
            }
            // });
            // }
        });
        // if (this.props.getCartIconCount) {
        //     this.props.getCartIconCount(lineOrderSummaryItems.length)
        // }
        if (option) {
            return lineOrderSummaryItems.length;
        } else {
            return lineOrderSummaryItems;
        }


    }



    /******************************** Calculations ********************************/

    // Calculate total price before tax
    calcTotalPriceBeforeTax = () => {
        let totalPrice = 0;
        this.state.orderSummary.map((product, i) => {
            // details.listing_products.map((product, i) => {
            if (product) {
                // product.product_config.map((item, j) => {
                if (product.hasOwnProperty('selected_quantity') && product.selected_quantity > 0) {
                    if (product.discountedUnitPrice) {
                        totalPrice = totalPrice + parseFloat(product.total);
                    } else {
                        totalPrice = totalPrice + (product.selected_quantity * product.product_list_price);
                    }
                }
                // })
            }
            // });
        });
        return totalPrice
        // if (this.state.deliveryFee) {
        //     return totalPrice + this.state.deliveryFee
        // } else {
        //     return totalPrice
        // }

    }


    calculateTotalDiscounts = () => {
        let totalDiscount = 0;
        let items = this.getOrderSummary()
        items && items.map((res) => {
            if (res.discountPromotion) {
                totalDiscount = totalDiscount + res.discountPromotion.calculatedDiscount
            }
        });
        return totalDiscount;
    }

    getFormData = async () => {
        let customerDetail = {}
        let customerDetailFullData = {}
        let deliveryDetailFullData = {}
        let deilveryDetail = {}
        let orderDetails = {}
        let option = ""
        let taxValues = []

        const { templateSettings } = this.state

        //if customer session is added , get values
        if (templateSettings && templateSettings.hasOwnProperty('customerDetails') &&
            templateSettings.customerDetails) {
            var res = this.getUserFormValues();
            if (res && res.userForm) {
                customerDetail = res.userForm
                customerDetailFullData = res.userFormFullData
            } else {
                customerDetail = null
                // customerDetailFullData = res.userFormFullData
            }
        }

        //if delivery session is added , get values
        if (templateSettings && templateSettings.hasOwnProperty('DeliveryPickupDetailValues') &&
            templateSettings.DeliveryPickupDetailValues) {
            var value = this.getDeliveryDetailValues()
            option = this.getSelectedDeliveryOption()
            let minorderamount = this.getMinorderValue()
            if (value && value.deliveryDetailsForm && value.deliveryDetailsFormFullData) {
                deliveryDetailFullData = value.deliveryDetailsFormFullData
                deilveryDetail = {
                    selectedOption: option,
                    detail: value.deliveryDetailsForm,
                    minimumOrderPrice: minorderamount
                }
            } else {
                deilveryDetail = null;
            }

        }

        //if order session is added , get values
        if (templateSettings && templateSettings.hasOwnProperty('orderDetails') &&
            templateSettings.orderDetails.length > 0) {
            orderDetails = this.getUserOrderDetailsValues()
        }
        var copyLineItems = Object.assign([], this.state.orderSummary);
        localStorage.setItem('ecommerce_order_form', JSON.stringify(copyLineItems))
        var duplicateLineItems = localStorage.getItem('ecommerce_order_form')
        duplicateLineItems = JSON.parse(duplicateLineItems)
        let computeListItems = [];
        let finalListItems = [];
        // // get selected products's category  
        // computeListItems = _.forEach(duplicateLineItems, function (product, index) {
        //     // _.forEach(details.listing_products, function (product) {
        //     let product_config = product.filter(e => e.selected_quantity && e.selected_quantity > 0)
        //     product = product_config;
        //     // });
        // });
        computeListItems = duplicateLineItems.filter(e => e.selected_quantity && e.selected_quantity > 0)
        // // get selected products
        // computeListItems && computeListItems.map((product, index) => {
        //     let products = [];
        //     // _.forEach(details.listing_products, function (product) {
        //     if (product) {
        //         finalListItems.push(product);
        //     }
        //     // })
        //     // if (products.length > 0) {
        //     //     // let data = details;
        //     //     // data.listing_products = products;
        //     //     finalListItems.push(products);
        //     // }
        // });
        //tax calculation if item level not set
        if (templateSettings && !templateSettings.taxLevel) {
            if (templateSettings && templateSettings.hasOwnProperty('taxesDetailsValues') &&
                templateSettings.taxesDetailsValues.length > 0) {
                taxValues = templateSettings.taxesDetailsValues
            } else {
                taxValues = []
            }
        }

        //tax calculation
        if (templateSettings && templateSettings.taxLevel) {
            if (templateSettings.taxLevel === "SubTotalLevel") {
                if (templateSettings && templateSettings.hasOwnProperty('taxesDetailsValues') &&
                    templateSettings.taxesDetailsValues.length > 0) {
                    taxValues = templateSettings.taxesDetailsValues
                } else {
                    taxValues = []
                }
            }
            if (templateSettings.taxLevel === "ItemLevel") {
                if (templateSettings && templateSettings.hasOwnProperty('taxesValues')
                ) {
                    taxValues = templateSettings.taxesValues
                } else {
                    taxValues = []
                }
            }
        }


        let TotalPriceBeforeTax = this.calcTotalPriceBeforeTax();
        let TotalPriceAfterTax = this.calcTotalPriceAfterTax();
        let TotalDiscount = this.calculateTotalDiscounts()
        return {
            UserForm: customerDetail,
            UserFormFullData: customerDetailFullData,
            LineItems: computeListItems,
            Total: {
                TotalPriceBeforeTax,
                TotalPriceAfterTax,
                TaxDetails: taxValues,
                TaxLevel: templateSettings.taxLevel,
                DeliveryFee: this.state.deliveryFee,
                TotalDiscount
            },
            Description: {
                description: this.state.description
            },
            DeliveryDetails: deilveryDetail,
            DeliveryDetailsFullData: deliveryDetailFullData,
            OrderDetails: orderDetails,
            OtherTemplateValues: {
                termsConditions: templateSettings.hasOwnProperty('termsConditionsDetailValues') ? templateSettings.termsConditionsDetailValues : '',
                paymentDetail: templateSettings.hasOwnProperty('paymentDetailsValues') ? templateSettings.paymentDetailsValues.paymentDetail : ''
            },
            HeaderDetails: templateSettings.hasOwnProperty('HeaderDetailValues') ? templateSettings.HeaderDetailValues : [],
            LocalizationDetailValues: templateSettings.hasOwnProperty('LocalizationDetailValues') ? templateSettings.LocalizationDetailValues : [],
            DiscountsPromotionsValues: templateSettings.hasOwnProperty('DiscountsPromotionsValues') ? templateSettings.DiscountsPromotionsValues : {},
        }
    }

    onChangeDescription = (event) => {
        this.setState({ description: event.target.value })
    }

    // Calculate price sgst tax
    calcTotalPriceFromTax = (item) => {

        const { shorturlData } = this.state

        const lineItems = shorturlData && shorturlData.formData && shorturlData.formData.LineItems && shorturlData.formData.LineItems.length > 0 ? shorturlData.formData.LineItems : []
        let checkAttribute = lineItems && lineItems.length > 0 && lineItems[0].product_attribute && lineItems[0].product_attribute.length > 0 ? _.findIndex(lineItems[0].product_attribute, function (o) { return o.attributeValue !== '' && o.attributeName !== '' }) : -1
        if (item) {
            if (shorturlData && shorturlData.formData && shorturlData.formData.Total && shorturlData.formData.Total.TotalPriceBeforeTax) {
                let percentageValue = (item.taxComputationType / 100)
                let price = shorturlData.formData.Total.TotalPriceBeforeTax * percentageValue;
                return price
            } else {
                let percentageValue = (item.taxComputationType / 100)
                let price = this.calcTotalPriceBeforeTax() * percentageValue;
                return price
            }
        }
    }

    // Calculate total price after tax
    calcTotalPriceAfterTax = () => {
        const { templateSettings } = this.state

        let totalPrice = 0
        let totaltaxPrice = 0
        //for sub level tax
        if (templateSettings && (templateSettings.taxLevel === "SubTotalLevel") || (!templateSettings.taxLevel)) {
            let totalDiscount = this.calculateTotalDiscounts();
            if (templateSettings && templateSettings.hasOwnProperty('taxesDetailsValues') &&
                templateSettings.taxesDetailsValues.length > 0) {
                templateSettings.taxesDetailsValues.map(item => {
                    let percentageValue = (item.taxComputationType / 100)
                    let price = this.calcTotalPriceBeforeTax() * percentageValue;
                    totalPrice = totalPrice + price
                })
                if (this.state.deliveryFee) {
                    return this.calcTotalPriceBeforeTax() + totalPrice - totalDiscount + this.state.deliveryFee
                } else {
                    return this.calcTotalPriceBeforeTax() + totalPrice - totalDiscount
                }

            } else {
                if (this.state.deliveryFee) {
                    return this.calcTotalPriceBeforeTax() - totalDiscount + this.state.deliveryFee
                } else {
                    return this.calcTotalPriceBeforeTax() - totalDiscount
                }
            }
        }

        //for item level tax
        if (templateSettings && templateSettings.taxLevel === "ItemLevel") {
            let items = this.getOrderSummary();
            let totalDiscount = this.calculateTotalDiscounts();
            items && items.map((res) => {
                if (res && res.tax_values) {
                    let tax = res.tax_values
                    Object.keys(tax).map((key) => {
                        if (key && tax[key] > 0) {
                            totaltaxPrice = totaltaxPrice + tax[key]
                        }
                    })
                }
            })
            if (this.state.deliveryFee) {
                return this.calcTotalPriceBeforeTax() + totaltaxPrice - totalDiscount + this.state.deliveryFee
            } else {
                return this.calcTotalPriceBeforeTax() + totaltaxPrice - totalDiscount
            }
        }
    }

    // Calculate total price after tax for item level tax
    calcItemLevelTotalPriceFromTax = (option) => {
        let totaltaxPrice = 0;
        let items = this.getOrderSummary()
        items && items.map((res) => {
            if (res && res.tax_values) {
                let tax = res.tax_values
                Object.keys(tax).map((key) => {
                    if (key && key === option && tax[key] > 0) {
                        totaltaxPrice = totaltaxPrice + tax[key]
                    }
                })
            }
        })
        return totaltaxPrice
    }

    // getCategories(str, option) {
    //     // this.setState({ loading: true })
    //     client.query({
    //         query: GET_LISTING_CATEGORIES,
    //         variables: { listingId: this.props.listingId, categoryName: str, templateId: this.props.templateId }
    //     })
    //         .then((res) => {
    //             if (res && res.data && res.data.listing_product_categories.length > 0) {
    //                 this.setState({
    //                     categories: res.data.listing_product_categories,
    //                     categoryId: ["All"]
    //                 }, () => {
    //                     this.getProducts(this.state.categoryId, option ? 'catagorySelect' : 'loadMore');
    //                 })
    //             } else {
    //                 this.setState({
    //                     categories: [],
    //                     categoryId: [],
    //                     loading: false
    //                 })
    //             }
    //         }).catch((err) => {
    //             this.setState({ loading: false })
    //         })
    // }
    getCategories(str, option) {
        // this.setState({ loading: true })


        let variables = {
            listingId: this.props.listingId,
            templateId: this.props.templateId
        }

        client
            .subscribe({ query: GET_CATAGORIES_BY_TEMPLATE_ID_SUB, variables: variables })
            .subscribe(
                res => {
                    if (res && res.data && res.data.product_categories.length > 0) {
                        this.setState({
                            categories: res.data.product_categories,
                            duplicateCategories: res.data.product_categories,
                            categoryId: ["All"]
                        }, () => {
                            this.getProducts(this.state.categoryId, option ? 'catagorySelect' : 'loadMore');
                        })
                    }
                    else {
                        this.setState({
                            categories: [],
                            categoryId: [],
                            loading: false
                        })
                    }
                }, err => {
                    this.setState({ loading: false })
                })
    }


    // Get Products
    // getProducts(id, option) {

    //     if (id.length > 0) {
    //         let variables = {
    //             listingId: this.props.listingId,
    //             categoryId: null,
    //             templateId: this.props.templateId
    //         }
    //         client.query({
    //             query: GET_LISTING_PRODUCTS_BY_CATEGORY_ID, variables: variables
    //         })
    //             .then((res) => {
    //                 if (res && res.data && res.data.listing_products.length > 0) {
    //                     this.setState({ categoryId: ["All"], duplicateLineItems: res.data.listing_products })
    //                     this.setProductData(0, productLoadListLimit)
    //                 } else {
    //                     this.setState({
    //                         lineItems: [],
    //                         duplicateLineItems: [],
    //                         orderSummary: this.state.orderSummary,
    //                         loading: false,
    //                         loadMoreLoading: false,
    //                         emptylineItem: true
    //                     })
    //                 }
    //             }).catch((err) => {
    //                 this.setState({ loading: false })
    //             })
    //     } else {
    //         this.setState({
    //             lineItems: [],
    //             duplicateLineItems: [],
    //             loadMoreLoading: false,
    //             loading: false
    //         })
    //     }
    // }
    getProducts(id) {
        this.setState({ loading: true }, () => {
            // if (id.length > 0) {
            let variables = {
                listingId: this.props.listingId,
                categoryId: 'All' ? null: id,
                templateId: this.props.templateId
            }
            console.log("variables123", variables)
            client.query({
                query: GET_PRODUCTS_BY_CATEGORY_ID, variables: variables
            })
                .then((res) => {
                    if (res && res.data && res.data.product_master.length > 0) {
                        this.setState({
                            lineItems: res.data.product_master,
                            duplicateLineItems: res.data.product_master,
                            orderSummary: this.state.orderSummary,
                            loading: false,
                            loadMoreLoading: false,
                            emptylineItem: true,
                            categoryId: ["All"]
                        })
                        // this.setState({ categoryId: ["All"] })
                        // this.setProductData(0, productLoadListLimit)
                    } else {
                        this.setState({
                            lineItems: [],
                            duplicateLineItems: [],
                            orderSummary: this.state.orderSummary,
                            loading: false,
                            loadMoreLoading: false,
                            emptylineItem: true
                        })
                    }
                }).catch((err) => {
                    console.log('err', err)
                    this.setState({ loading: false })
                })
            // } 
            // else {
            //     this.setState({
            //         lineItems: [],
            //         duplicateLineItems: [],
            //         loadMoreLoading: false,
            //         loading: false
            //     })
            // }
        })

    }

    onLoadMoreProducts = (start, end) => {
        this.setState({ isCategorySelected: false }, () => {
            this.setProductData(end + 1, end + productLoadListLimit, 'loadMore')
        })
    }

    setProductData = (start, end, option) => {
        this.setState({
            productStartIndex: 0,
            productEndIndex: productLoadListLimit
        }, () => {
            let order = this.state.orderSummary
            let filterId = []
            let orderData = []

            var data = this.getCategoriesFromStorage()
            let productData = []
            //if any serch product value is there
            if (this.state.productSearchText) {
                let text = this.state.productSearchText.split(' ');
                productData = data.filter(function (item) {
                    return text.every(function (el) {
                        return item.product_name.toLowerCase().indexOf(el) > -1;
                    });
                });
            }
            //if search product value is empty
            else {
                let checkAll = this.state.categoryId.indexOf("All") > -1 ? true : false
                //if all is selected in ctegory dropdown, assign all the product data
                if (checkAll) {
                    productData = data
                } else if (this.state.categoryId && this.state.subcategoryId) {
                    console.log('subcategoryId', this.state.subcategoryId)
                    let filterId = _.filter(data, (val) => {
                        if (val.product_category_id === this.state.categoryId &&
                            val.product_subcategory_id === this.state.subcategoryId) {
                            return val
                        }
                    })
                    productData = filterId
                } else {
                    let filterId = _.filter(data, (data) =>
                        _.includes(this.state.categoryId, data.product_category_id))
                    productData = filterId
                }
            }
            console.log('productData', productData)
            if (productData && productData.length > 0) {
                this.setState({
                    lineItems: productData,
                    loading: false
                })
            } else {
                this.setState({
                    lineItems: [],
                    loading: false
                })
            }

            // //if products data is there and not exceed the whole data length
            // if (productData && productData.length > 0 && start <= productData.length) {
            //     let displayArray = []
            //     //if product lenght is greater than load more limit, call load more fuction
            //     if (productData.length >= productLoadListLimit) {
            //         displayArray = _.slice(productData, start, end)
            //         if (option && option === "loadMore") {
            //             displayArray = [...this.state.lineItems, ...displayArray]
            //         }
            //     }
            //     //if product lenght is less than load more limit
            //     if (productData.length <= productLoadListLimit) {
            //         displayArray = productData
            //     }
            //     this.setState({
            //         lineItems: productData,
            //         loading: false
            //     })
            // } else {
            //     //if product lenght is empty when product search value is empty make UI is empty
            //     if (this.state.productSearchText && productData.length === 0) {
            //         this.setState({
            //             lineItems: [],
            //             loading: false
            //         })
            //     }
            // }
            // //for maintaining order summary
            // if (this.state.orderSummary.length > 0) {
            //     this.state.orderSummary.map((item) => {
            //         let data = _.filter(productData, function (o) {
            //             return o.product_id === item.product_id
            //         });
            //         if (productData.length > 0) {
            //             orderData.push(data[0]);
            //         }
            //     });
            //     var orderNew = productData.filter((item) => !orderData.includes(item));
            //     filterId = [...order, ...orderNew]
            // } else {
            //     filterId = [...order, ...productData]
            // }
            // this.setState({ orderSummary: filterId, })
        })

    }

    getCategoriesFromStorage = () => {
        return this.state.duplicateLineItems
    }

    // select category id
    handleCategorySelected = (id, subcatId) => {
        console.log('handleCategorySelected', id, subcatId)
        this.setState({ categoryId: id, subcategoryId: subcatId ? subcatId : '', isCategorySelected: true, productSearchText: "" }, () => {
            this.setProductData(0, productLoadListLimit)
        })
    }



    onDeSearchCategories = (event) => {
        let data = _.filter(this.state.categoryId, function (o) {
            return o !== event
        });
        this.setState({ categoryId: data, isCategorySelected: true, productSearchText: "" }, () => {
            this.setProductData(0, productLoadListLimit)
        })
    }

    searchProducts = (event) => {
        this.setState({ productSearchText: event ? event.toLowerCase() : "", isCategorySelected: true }, () => {
            // this.setProductData(0, productLoadListLimit)
            this.fetchSearchedCategor()
        })
    }

    scrollItems = (event) => {
        if (event) {
            if (event === "homeSection") {
                this.listingDetailsRef.current.scrollIntoView({ behavior: 'auto' });
            }
            if (event === "menuSection") {
                this.searchRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            if (event === "cartSection") {
                this.cartRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
        console.log(event, 'bevent')
    }

    onclassActive = (e) => {
        this.setState({ classactive: e });
        this.props.placeOrderdisable(e)
    }

    fetchSearchedCategor = () => {
        console.log('fetchSearchedCategor', this.state.productSearchText)
        var data = this.getCategoriesFromStorage()
        if (this.state.productSearchText) {
            let text = this.state.productSearchText.split(' ');
            let productData = data.filter(function (item) {
                return text.every(function (el) {
                    return item.product_name.toLowerCase().indexOf(el) > -1;
                });
            });
            console.log('fetchSearchedCategor productData', productData)
            if (productData && productData.length) {
                let tempCate = [{
                    product_category_id: 'search',
                    product_category_name: `Result for ${this.state.productSearchText} (${productData.length})`
                }]
                this.setState({
                    categories: tempCate,
                    lineItems: productData,
                    loading: false
                })
            }
        } else {
            this.setState({ categories: this.state.duplicateCategories })
        }
    }

    /******************************** Onchange Functions ********************************/

    // change lineitems when line item changes
    // handleLineItemChange = (categoryIndex, productIndex, elementIndex) => (value) => {
    handleLineItemChange = async (item, index, value, option, indexOfOrderSummary) => {
        if (item) {

            // let lineItems = cloneDeep(this.state.lineItems)
            // let duplicateLineItems = cloneDeep(this.state.duplicateLineItems)
            let orderSummary = cloneDeep(this.state.orderSummary)
            console.log('orderSummary', orderSummary, value, item, option, indexOfOrderSummary)
            // await _.find(lineItems, (res, i) => {
            //     if ((res && (res.id === item.id))) {

            //         lineItems[i]['selected_quantity'] = Math.abs(value);
            //         this.setState({ lineItems }, () => {
            //         })
            //     }
            // })
            // await _.find(duplicateLineItems, (res, i) => {
            //     if ((res && (res.id === item.id))) {
            //         duplicateLineItems[i]['selected_quantity'] = Math.abs(value);
            //         this.setState({ duplicateLineItems }, () => {
            //         })
            //     }
            // });

            if (option === "orderSummary") {
                // await _.find(orderSummary, (res, i) => {
                //     if ((res && (res.id === item.id))) {
                orderSummary[indexOfOrderSummary]['selected_quantity'] = Math.abs(value);
                orderSummary[indexOfOrderSummary]['quantity'] = Math.abs(value);
                orderSummary[indexOfOrderSummary]['total'] = orderSummary[indexOfOrderSummary].discountedUnitPrice ?
                    (orderSummary[indexOfOrderSummary].selected_quantity * orderSummary[indexOfOrderSummary].discountedUnitPrice).toFixed(2)
                    :
                    (orderSummary[indexOfOrderSummary].selected_quantity * orderSummary[indexOfOrderSummary].product_list_price).toFixed(2);

                let finalSummary = _.filter(orderSummary, (val) => {
                    if (val.selected_quantity > 0) {
                        return val
                    }
                })
                console.log('orderSummary111', orderSummary, finalSummary)
                this.setState({ orderSummary: finalSummary }, () => {
                    if (this.props.getCartIconCount) {
                        let computeListItems = this.state.orderSummary.filter(e => e.selected_quantity && e.selected_quantity > 0)
                        this.props.getCartIconCount(computeListItems.length)
                    }
                })
                //     }
                // });
            }


            // this.setProductData(this.state.productStartIndex, this.state.productEndIndex)
        }

    }

    //when delete product in order summary
    onRemoveSummaryItem = (item, orders, summaryIndex) => {
        if (item) {
            let index = item.item_index

            let lineItems = cloneDeep(this.state.lineItems)
            let duplicateLineItems = cloneDeep(this.state.duplicateLineItems)
            let orderSummary = cloneDeep(this.state.orderSummary)
            _.find(lineItems, (res, i) => {
                if ((res && (res.product_id === item.product_id))) {
                    lineItems[i]['product_config'][index]['selected_quantity'] = null;
                    this.setState({ lineItems }, () => {
                    })
                }
            })
            _.find(duplicateLineItems, (res, i) => {
                if ((res && (res.product_id === item.product_id))) {
                    duplicateLineItems[i]['product_config'][index]['selected_quantity'] = null;
                    this.setState({ duplicateLineItems }, () => {
                    })
                }
            });

            _.find(orderSummary, (res, i) => {
                if ((res && (res.product_id === item.product_id))) {
                    orderSummary[i]['product_config'][index]['selected_quantity'] = null;
                    this.setState({ orderSummary }, () => {
                    })
                }
            });
        }
    }

    scrollToCartRef = () => {
        this.cartRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

    }
    scrollToSearchRef = () => {
        this.searchRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    scrollToListingDetail = () => {
        this.listingDetailsRef.current.scrollIntoView({ behavior: 'auto' });
    }


    discountCalculate = (value, total) => {
        let discountValue = parseInt(total) * parseInt(value.attributeValue) / 100;
        return discountValue;
    }

    calculateTotalWithSellerValues = (total) => {
        let eachDiscount = 0;
        let Totalaftercalculation = 0;
        let eachAddfees = 0;
        if (this.state.discountFromSeller && this.state.discountFromSeller.length > 0) {
            this.state.discountFromSeller.map((value, index) => {
                eachDiscount = eachDiscount + parseInt(total) * parseInt(value.attributeValue) / 100;
            });
        }
        if (this.state.additionalFeesFromSeller && this.state.additionalFeesFromSeller.length > 0) {
            this.state.additionalFeesFromSeller.map((value, index) => {
                eachAddfees = eachAddfees + parseInt(value.attributeValue);
            });
        }
        Totalaftercalculation = (total + eachAddfees) - eachDiscount;
        return Totalaftercalculation;
    }


    locale = (string) => {
        if ((Object.keys(this.state.shorturlData).length > 0)) {
            if (this.state.shorturlData.formData.LocalizationDetailValues &&
                this.state.shorturlData.formData.LocalizationDetailValues.length > 0 &&
                this.state.shorturlData.formData.LocalizationDetailValues.find(item => item.DefaultValue === string)) {
                return this.state.shorturlData.formData.LocalizationDetailValues.find(item => item.DefaultValue === string).AlternateValue
            } else {
                return string
            }
        } else {
            if (this.state.templateSettings.LocalizationDetailValues &&
                this.state.templateSettings.LocalizationDetailValues.length > 0 &&
                this.state.templateSettings.LocalizationDetailValues.find(item => item.DefaultValue === string)) {
                return this.state.templateSettings.LocalizationDetailValues.find(item => item.DefaultValue === string).AlternateValue
            } else {
                return string
            }
        }
    }

    getDeliveryFee = (values) => {
        this.setState({ deliveryFee: values && values.length > 0 ? values[1] ? parseFloat(values[1]) : "" : '' })
    }
    showDeliveryMessageModal = (value) => {
        this.setState({ showDeliveryMsgModal: value })
    }

    getDiscountPromotion = () => {
        const { templateSettings, shorturlData } = this.state;
        if (Object.keys(shorturlData).length === 0 && templateSettings.DiscountsPromotionsValues && templateSettings.DiscountsPromotionsValues.length > 0) {
            this.state.discountPromotionDetails.categoryLevel = [];
            templateSettings.DiscountsPromotionsValues.map((value, index) => {
                if (value.attributeType === 'DiscountCategorylevel' && value.attributeValue !== '') {
                    let colanSeperated = [];
                    let commaSeperated = [];
                    commaSeperated = value.attributeValue.split(',');
                    commaSeperated.map((val) => {
                        colanSeperated = val.split(':');
                        let eachItems = {
                            value: colanSeperated[0],
                            category: colanSeperated[1]
                        }
                        this.state.discountPromotionDetails.categoryLevel.push(eachItems);
                    })
                    this.setState({ discountPromotionDetails: this.state.discountPromotionDetails }, () => {
                    });
                }
            })
        } else if (shorturlData.formData && shorturlData.formData.DiscountsPromotionsValues && shorturlData.formData.DiscountsPromotionsValues.length > 0) {
            this.state.discountPromotionDetails.categoryLevel = [];
            shorturlData.formData.DiscountsPromotionsValues.map((value, index) => {
                if (value.attributeType === 'DiscountCategorylevel' && value.attributeValue !== '') {
                    let colanSeperated = [];
                    let commaSeperated = [];
                    commaSeperated = value.attributeValue.split(',');
                    commaSeperated.map((val) => {
                        colanSeperated = val.split(':');
                        let eachItems = {
                            value: colanSeperated[0],
                            category: colanSeperated[1]
                        }
                        this.state.discountPromotionDetails.categoryLevel.push(eachItems);
                    })
                    this.setState({ discountPromotionDetails: this.state.discountPromotionDetails }, () => {
                    });
                }
            })

        }
    }

    onAddToCartProduct = async (productValue) => {
        let summaryItems = cloneDeep(this.state.orderSummary)
        let product = cloneDeep(productValue)
        let { catalogDiscount } = this.state;
        product['quantity'] = product.selected_quantity

        if (product.product_settings &&
            product.product_settings.discount_price) {

            let discount = parseFloat(product.product_settings.discount_price)
            let originalPrice = product.product_list_price
            let price = (originalPrice - discount)
            // itemTotal = (quantity * price)
            // itemTotal = (quantity * discount)
            // productPrice = discount
            product['discountedUnitPrice'] = discount;
            product['total'] = (product.selected_quantity * discount).toFixed(2)

        }
        else if (product &&
            product.product_category &&
            product.product_category.category_discount) {

            let originalPrice = product.product_list_price

            let percentage = parseFloat(product.product_category.category_discount);
            let afterCalc = parseFloat(originalPrice) * (percentage / 100);
            let finalPrice = parseFloat(originalPrice) - parseFloat(afterCalc)
            // itemTotal = (quantity * finalPrice)
            // productPrice = finalPrice
            product['discountedUnitPrice'] = finalPrice;

            product['total'] = (product.selected_quantity * finalPrice).toFixed(2)


        } else if (catalogDiscount && catalogDiscount !== '') {

            let originalPrice = product.product_list_price

            let percentage = parseFloat(catalogDiscount);
            let afterCalc = parseFloat(originalPrice) * (percentage / 100);
            let finalPrice = parseFloat(originalPrice) - parseFloat(afterCalc)
            // itemTotal = (quantity * finalPrice)
            // productPrice = finalPrice
            product['discountedUnitPrice'] = finalPrice;

            product['total'] = (product.selected_quantity * finalPrice).toFixed(2)


        }
        //if product has not discount price
        else {
            product['total'] = (product.selected_quantity * product.product_list_price).toFixed(2)
        }

        product['product_full_data'] = productValue
        summaryItems.push(product)


        // let lineItems = cloneDeep(this.state.lineItems)
        // let duplicateLineItems = cloneDeep(this.state.duplicateLineItems)
        // await _.find(lineItems, (res, i) => {
        //     if ((res && (res.id === productValue.id))) {

        //         lineItems[i]['selected_quantity'] = 0;
        //         this.setState({ lineItems }, () => {
        //         })
        //     }
        // })
        // await _.find(duplicateLineItems, (res, i) => {
        //     if ((res && (res.id === productValue.id))) {
        //         duplicateLineItems[i]['selected_quantity'] = 0;
        //         this.setState({ duplicateLineItems }, () => {
        //         })
        //     }
        // });

        this.setState({ orderSummary: summaryItems, }, () => {
            message.success('Added to the cart')
            if (this.props.getCartIconCount) {
                let computeListItems = this.state.orderSummary.filter(e => e.selected_quantity && e.selected_quantity > 0)
                this.props.getCartIconCount(computeListItems.length)
            }
        })

    }

    onClickCheckout = (type) => {
        console.log('onClickCheckout', this.props, this.state.paylater)
        if (this.props && this.props.checkOut) {
            localStorage.setItem('paylater', type)
            this.props.checkOut()
        }
    }

    renderOrderContact = () => {
        const { shorturlData, shorturlFormDataTotal, templateSettings, loadMoreLoading } = this.state
        const { Text, Title } = Typography
        let transactionDataValues = (Object.keys(shorturlData).length > 0) ? true : false
        return (
            <div>
                {templateSettings && templateSettings.hasOwnProperty('orderDetails') &&
                    templateSettings.orderDetails.length > 0 &&
                    <OrderDetails
                        templateSettingsData={templateSettings}
                        data={this.state.shorturlData}
                        getUserOrderDetails={getUserOrderDetailsValues => { this.getUserOrderDetailsValues = getUserOrderDetailsValues }}
                    />
                }
                {shorturlData && shorturlData.formData && shorturlData.formData.PaymentDetails &&
                    <div>
                        <Divider />
                        <Title level={4} className="pubilc-url-title">Payment details</Title>
                        {shorturlData.formData.PaymentDetails.status &&
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <p className="strip-payment-label" > Status:  </p>
                                <p className="strip-payment-status">{shorturlData.formData.PaymentDetails.status} </p>
                            </div>
                        }
                        {shorturlData.formData.PaymentDetails.selectedOption &&
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <p className="strip-payment-label">Selected Option: </p>
                                <p className="strip-payment-status">{shorturlData.formData.PaymentDetails.selectedOption} </p>
                            </div>
                        }
                    </div>
                }
                <div ref={this.cartRef}
                // style={{ paddingTop: '10%' }}
                >
                    <Summary
                        items={this.getOrderSummary()}
                        defaultPriceUnit={this.state.defaultPriceUnit}
                        changeHandler={this.handleLineItemChange}
                        onRemoveItem={this.onRemoveSummaryItem}
                        showOrderQuantityEdit={this.props.showOrderFormHeader}
                        templateSettings={!transactionDataValues ? templateSettings : shorturlData.formData}
                    />


                    <Row className="total-after-tax" style={{ marginBottom: '2%', marginTop: '2%' }}>
                        <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                            <Text> {this.locale('Total (before tax)')}: </Text>
                        </Col>
                        <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                            {(transactionDataValues === false) ?
                                <Text>{this.state.defaultPriceUnit} {this.calcTotalPriceBeforeTax().toFixed(2)} </Text> :
                                <Text>
                                    {((Object.keys(shorturlFormDataTotal).length > 0)
                                        ? `${this.state.defaultPriceUnit}` + ' ' + `${shorturlFormDataTotal.TotalPriceBeforeTax.toFixed(2)}` : emptyData)} </Text>
                            }
                        </Col>
                    </Row>
                    {(transactionDataValues === false) ?
                        <div>
                            {this.calculateTotalDiscounts() !== 0 &&
                                <Row className="total-after-tax" style={{ marginBottom: '2%', marginTop: '2%' }}>
                                    <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                        <Text> {this.locale('Discount')}: </Text>
                                    </Col>
                                    <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                        <Text>{this.state.defaultPriceUnit} {this.calculateTotalDiscounts().toFixed(2)} </Text>
                                    </Col>
                                </Row>
                            }
                        </div>
                        :
                        <div>
                            {((Object.keys(shorturlFormDataTotal).length > 0) && shorturlFormDataTotal && shorturlFormDataTotal.TotalDiscount !== 0) &&
                                <Row className="total-after-tax" style={{ marginBottom: '2%', marginTop: '2%' }}>
                                    <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                        <Text> {this.locale('Discount')}: </Text>
                                    </Col>
                                    <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                        <Text>
                                            {((Object.keys(shorturlFormDataTotal).length > 0)
                                                ? `${this.state.defaultPriceUnit}` + ' ' + `${shorturlFormDataTotal.TotalDiscount ? shorturlFormDataTotal.TotalDiscount.toFixed(2) : emptyData}` : emptyData)} </Text>
                                    </Col>
                                </Row>
                            }
                        </div>
                    }
                    {/* taxes */}
                    {this.props.accessingFrom !== 'CreateTemplate' &&
                        <div>
                            {(transactionDataValues === false) ?
                                <div>
                                    {
                                        this.state.deliveryFee && this.state.deliveryFee !== '' &&
                                        <div>
                                            {/* <br /> */}
                                            <Row className="total-after-tax" style={{ marginBottom: '2%', marginTop: '2%' }}>
                                                <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                                    <Text> {this.locale('Delivery Fee')}: </Text>
                                                </Col>
                                                <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                                    <Text>{this.state.defaultPriceUnit} {this.state.deliveryFee.toFixed(2)}</Text>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </div>
                                :
                                <div>
                                    {
                                        ((Object.keys(shorturlFormDataTotal).length > 0) && shorturlFormDataTotal.DeliveryFee) &&
                                        <div>
                                            {/* <br /> */}
                                            <Row className="total-after-tax" style={{ marginBottom: '2%', marginTop: '2%' }}>
                                                <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                                    <Text>{this.locale('Delivery Fee')}: </Text>
                                                </Col>
                                                <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                                    <Text>{`${this.state.defaultPriceUnit}` + ' ' + `${shorturlFormDataTotal.DeliveryFee.toFixed(2)}`}</Text>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </div>
                            }


                            {/* in public url (not read only) */}
                            {!transactionDataValues && templateSettings &&
                                <div>

                                    {/* for sublevel tax */}
                                    {
                                        ((!templateSettings.taxLevel) || (templateSettings.taxLevel === "SubTotalLevel")) &&
                                        <div>
                                            {
                                                templateSettings && templateSettings.hasOwnProperty('taxesDetailsValues') &&
                                                templateSettings.taxesDetailsValues.length > 0 &&
                                                templateSettings.taxesDetailsValues.map(res => {
                                                    if (res && res.taxType && res.taxComputationType) {
                                                        return (
                                                            <div>
                                                                {/* <br /> */}
                                                                <Row className="total-after-tax" style={{ marginBottom: '2%', marginTop: '2%' }}>
                                                                    <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                                                        <Text> {res.taxType} at {res.taxComputationType}%: </Text>
                                                                    </Col>
                                                                    <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                                                        <Text>{this.state.defaultPriceUnit} {this.calcTotalPriceFromTax(res).toFixed(2)}</Text>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    }

                                                })
                                            }

                                        </div>
                                    }

                                    {/* for item level */}
                                    {
                                        templateSettings.taxLevel && templateSettings.taxLevel === "ItemLevel" &&
                                        <div>
                                            {
                                                templateSettings && templateSettings.hasOwnProperty('taxesValues') &&
                                                Object.keys(templateSettings.taxesValues).map((key) => {
                                                    if (key && templateSettings.taxesValues[key] === true) {
                                                        return (
                                                            <div>
                                                                {/* <br /> */}
                                                                <Row className="total-after-tax" style={{ marginBottom: '2%', marginTop: '2%' }}>
                                                                    <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                                                        <Text> {this.locale('Total')} {key}: </Text>
                                                                    </Col>
                                                                    <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                                                        <Text>{this.state.defaultPriceUnit} {this.calcItemLevelTotalPriceFromTax(key).toFixed(2)}</Text>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    }

                                                })
                                            }

                                        </div>
                                    }
                                </div>
                            }

                            {/* short url page (read only)  - if tax evel type not set*/}
                            {transactionDataValues && shorturlData && shorturlData.formData.hasOwnProperty('Total') &&
                                !shorturlData.formData.Total.TaxLevel &&
                                shorturlData.formData.Total.TaxDetails.length > 0 &&
                                shorturlData.formData.Total.TaxDetails.map(res => {
                                    if (res && res.taxType && res.taxComputationType) {
                                        let taxValue = this.calcTotalPriceFromTax(res) > 0 ? true : false
                                        return (
                                            <div>
                                                {/* <br /> */}
                                                {taxValue &&
                                                    <Row className="total-after-tax" style={{ marginBottom: '2%', marginTop: '2%' }}>
                                                        <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                                            <Text> {res.taxType} at {res.taxComputationType}%: </Text>
                                                        </Col>
                                                        <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                                            <Text>{this.state.defaultPriceUnit} {this.calcTotalPriceFromTax(res).toFixed(2)}</Text>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        )
                                    }

                                })

                            }
                            {/* short url page (read only)  - if tax sub level type not set*/}
                            {transactionDataValues && shorturlData && shorturlData.formData.hasOwnProperty('Total') &&
                                shorturlData.formData.Total.TaxLevel && shorturlData.formData.Total.TaxLevel === "SubTotalLevel" &&
                                shorturlData.formData.Total.TaxDetails.length > 0 &&
                                shorturlData.formData.Total.TaxDetails.map(res => {
                                    if (res && res.taxType && res.taxComputationType) {
                                        let taxValue = this.calcTotalPriceFromTax(res) > 0 ? true : false
                                        return (
                                            <div>
                                                {/* <br /> */}
                                                {taxValue &&
                                                    <Row className="total-after-tax" style={{ marginBottom: '2%', marginTop: '2%' }}>
                                                        <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                                            <Text> {res.taxType} at {res.taxComputationType}%: </Text>
                                                        </Col>
                                                        <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                                            <Text>{this.state.defaultPriceUnit} {this.calcTotalPriceFromTax(res).toFixed(2)}</Text>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        )
                                    }

                                })
                            }
                            {/* short url page (read only)  - if tax item level type not set*/}
                            {transactionDataValues && shorturlData && shorturlData.formData.hasOwnProperty('Total') &&
                                shorturlData.formData.Total.TaxLevel && shorturlData.formData.Total.TaxLevel === "ItemLevel" &&
                                Object.keys(shorturlData.formData.Total.TaxDetails).map((key) => {
                                    if (key && shorturlData.formData.Total.TaxDetails[key] === true) {
                                        let taxValue = this.calcItemLevelTotalPriceFromTax(key) > 0 ? true : false
                                        return (
                                            <div>
                                                {/* <br /> */}
                                                {taxValue &&
                                                    <Row className="total-after-tax" style={{ marginBottom: '2%', marginTop: '2%' }}>
                                                        <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                                            <Text> {this.locale('Total')} {key}: </Text>
                                                        </Col>
                                                        <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                                            <Text>{this.state.defaultPriceUnit} {this.calcItemLevelTotalPriceFromTax(key).toFixed(2)}</Text>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        )
                                    }

                                })

                            }

                            {/* <br /> */}

                            {this.state.editedBySeller ?
                                <div>
                                    {this.state.discountFromSeller && this.state.discountFromSeller.length > 0 &&
                                        this.state.discountFromSeller.map((value, index) =>

                                            <Row className="total-after-tax" style={{ marginBottom: '2%', marginTop: '2%' }}>
                                                <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                                    <Text> {value.attributeName} ({this.locale('Discount')}) </Text>
                                                </Col>
                                                <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                                    <Text>- {this.state.defaultPriceUnit} {this.discountCalculate(value, shorturlFormDataTotal.TotalPriceAfterTax).toFixed(2)}</Text>
                                                </Col>
                                            </Row>
                                        )
                                    }

                                    {this.state.additionalFeesFromSeller && this.state.additionalFeesFromSeller.length > 0 &&
                                        this.state.additionalFeesFromSeller.map((value, index) =>

                                            <Row className="total-after-tax" style={{ marginBottom: '2%', marginTop: '2%' }}>
                                                <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                                    <Text> {value.attributeName} ({this.locale('Additional Fees')}) </Text>
                                                </Col>
                                                <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                                    <Text>{this.state.defaultPriceUnit} {value.attributeValue}</Text>
                                                </Col>
                                            </Row>
                                        )
                                    }

                                    <Row className="total-after-tax" style={{ marginBottom: '4%' }}>
                                        <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                            <Text style={{ fontWeight: 'bold' }}>{this.locale('Total (after tax)')}:</Text>
                                        </Col>
                                        <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                            <Text style={{ fontWeight: 'bold' }}>
                                                {((Object.keys(shorturlFormDataTotal).length > 0)
                                                    ? `${this.state.defaultPriceUnit}` + ' ' + `${this.calculateTotalWithSellerValues(shorturlFormDataTotal.TotalPriceAfterTax).toFixed(2)}` : emptyData)} </Text>
                                        </Col>
                                    </Row>
                                </div>
                                :
                                <Row className="total-after-tax" style={{ marginBottom: '4%' }}>
                                    <Col xs={17} sm={17} md={18} lg={21} className="tax-value-view">
                                        <Text style={{ fontWeight: 'bold' }}>{this.locale('Total (after tax)')}:</Text>
                                    </Col>
                                    <Col xs={7} sm={7} md={5} lg={3} className="total-content-modal">
                                        {(transactionDataValues === false) ?
                                            <Text style={{ fontWeight: 'bold' }}>{this.state.defaultPriceUnit} {this.calcTotalPriceAfterTax() > 0 ? this.calcTotalPriceAfterTax().toFixed(2) : this.calcTotalPriceAfterTax().toFixed(2)} </Text> :
                                            <Text style={{ fontWeight: 'bold' }}>
                                                {((Object.keys(shorturlFormDataTotal).length > 0)
                                                    ? `${this.state.defaultPriceUnit}` + ' ' + `${shorturlFormDataTotal.TotalPriceAfterTax.toFixed(2)}` : emptyData)} </Text>
                                        }
                                    </Col>
                                </Row>
                            }

                        </div>
                    }
                    {/* </div> */}

                    {/* customer details */}

                    {/* customer details */}

                    {templateSettings && templateSettings.hasOwnProperty('customerDetails') &&
                        templateSettings.customerDetails &&
                        <UserForm
                            templateSettingsData={templateSettings}
                            listingDetails={this.state.listingDetail}
                            data={this.state.shorturlData}
                            getUserValue={getUserFormValues => { this.getUserFormValues = getUserFormValues }}
                            contactsDetails={this.props.contactsDetails}
                            templateSettings={!transactionDataValues ? templateSettings : shorturlData.formData}
                            formListingCountry={this.state.formListingCountry} />

                    }
                </div>
            </div>
        )
    }

    render() {
        const { Text, Title } = Typography
        const { shorturlData, shorturlFormDataTotal, templateSettings, loadMoreLoading } = this.state
        let transactionDataValues = (Object.keys(shorturlData).length > 0) ? true : false
        let showedAlready = false;
        let stripExist = (this.state.templateData &&
            this.state.templateData.templateSettings &&
            this.state.templateData.templateSettings.paymentType &&
            this.state.templateData.templateSettings.paymentType === 'Stripe') ? true : false
        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
        console.log(shorturlData, "jawatransaction")
        return (
            <div>
                {
                    (this.props.showOrderFormHeader && this.props.isloginModal === false &&
                        this.state.showDeliveryMsgModal === true) &&
                    <Layout className="sticky" id="sticky-view">
                        <Header className="sticky-header">
                            <div style={{
                                width: '98%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'baseline'
                            }} >

                                {
                                    templateSettings && templateSettings.HeaderDetailValues &&
                                    templateSettings.HeaderDetailValues.length > 0 &&
                                    templateSettings.HeaderDetailValues.map((res) => {
                                        if (res && res.attributeType && (res.attributeType === "Name" ||
                                            res.attributeType === "name") && !showedAlready) {
                                            showedAlready = true;
                                            return <h3 id="order-title-content" style={{ textAlign: 'center', fontWeight: 500, cursor: "pointer" }}
                                                onClick={() => this.scrollToListingDetail()}>{_.startCase(res.attributeValue)}</h3>
                                        }
                                    })
                                }
                                {/* 
                                <div style={{ position: 'absolute', right: 20, top: 23 }}>
                                    <Badge style={{ cursor: "pointer" }}
                                        count={this.getOrderSummary('orderCount')} onClick={() => this.scrollToCartRef()}>
                                        <Icon onClick={() => this.scrollToCartRef()} style={{ fontSize: 24, marginRight: 10, color: 'grey' }} type="shopping-cart" />
                                    </Badge>
                                    <Icon style={{ fontSize: 24, color: 'grey', marginRight: 10, }} onClick={() => this.scrollToSearchRef()} type="search" />
                                </div> */}

                            </div>
                        </Header>


                    </Layout>
                }

                <div ref={this.listingDetailsRef}
                    className="order_form" style={{
                        // backgroundColor:'red',
                        // paddingTop: '15%',
                        // marginTop: -50
                    }}>
                    {this.state.classactive === false &&
                        <div>
                            <ListingDetail data={this.state.listingDetail}
                                transactionDetail={this.state.shorturlData}
                                isReadOnly={transactionDataValues}
                                screenName={this.props.screenFrom}
                                templateSettings={templateSettings}
                                statusDes={this.state.statusDes}
                            />

                            {/* delivery details */}
                            {
                                templateSettings && templateSettings.hasOwnProperty('DeliveryPickupDetailValues') &&
                                templateSettings.DeliveryPickupDetailValues &&
                                <DeliveryDetails
                                    templateSettingsData={templateSettings}
                                    data={this.state.shorturlData}
                                    screenFrom={this.props.screenFrom}
                                    getDeliveryDetail={getDeliveryDetailValues => { this.getDeliveryDetailValues = getDeliveryDetailValues }}
                                    getSelectedDelivery={getSelectedDeliveryOption => { this.getSelectedDeliveryOption = getSelectedDeliveryOption }}
                                    getMinorderVal={getMinorderValue => { this.getMinorderValue = getMinorderValue }}
                                    templateSettings={!transactionDataValues ? templateSettings : shorturlData.formData}
                                    formListingCountry={this.state.formListingCountry}
                                    getDeliveryFee={this.getDeliveryFee}
                                    showDeliveryMessageModal={this.showDeliveryMessageModal}
                                />
                            }
                        </div>
                    }
                    {/* <Divider /> */}
                    <div
                        className="order_form-details"
                        ref={this.searchRef}
                        style={{
                            paddingTop: 80,
                            marginTop: -60
                        }}>

                        <div
                        // style={{ paddingLeft: '15px', paddingRight: '15px', }}
                        >
                            {/* <Divider /> */}

                            {/* <Spin spinning={this.state.loading} /> */}


                            {this.props.accessingFrom !== 'CreateTemplate' &&
                                <>
                                    {
                                        !transactionDataValues &&
                                        <Spin spinning={this.state.loading}>
                                            <LineItems
                                                data={this.state.shorturlData}
                                                categories={this.state.categories}
                                                onSelectedCategory={this.handleCategorySelected}
                                                items={this.state.lineItems}
                                                changeHandler={this.handleLineItemChange}
                                                defaultPriceUnit={this.state.defaultPriceUnit}
                                                onDeSearchCategories={this.onDeSearchCategories}
                                                // onSelectedCategory={this.handleCategorySelected}
                                                reorderHandler={this.handleReorderLineItems}
                                                scrollIntoView={this.scrollItems}
                                                selectedCategoryId={this.state.categoryId}
                                                onSearchProducts={this.searchProducts}
                                                onLoadMoreProducts={this.onLoadMoreProducts}
                                                isCategorySelected={this.state.isCategorySelected}
                                                productLoadListLimit={productLoadListLimit}
                                                templateSettings={templateSettings}
                                                productImageView={this.props.productImageView}
                                                onAddToCartProduct={this.onAddToCartProduct}
                                                catalogDiscount={this.state.catalogDiscount}
                                                onClassActive={this.onclassActive}
                                            />
                                        </Spin>
                                    }
                                    {transactionDataValues &&
                                        <div>
                                            {this.renderOrderContact()}
                                        </div>
                                    }
                                    {/* </div> */}
                                    {this.state.openCartmodal &&
                                        <div className="order-summary-popup">
                                            <div className="order-summary-popup-view"></div>
                                            <div id="order-popup-summary">
                                                <div className="order-summary-popup-sub-container">
                                                    <p id="close-text-view" onClick={() => this.setState({ openCartmodal: false }, () => {
                                                        this.props.onChangeCartModal(false)
                                                    })}>Close</p>
                                                    {this.renderOrderContact()}
                                                    <div ref={this.cartRef}
                                                    // style={{ paddingTop: '10%' }}
                                                    >
                                                        <div id="buttton-view-checkout">
                                                            {/* <Button type="primary" onClick={() => this.onClickCheckout()}>{'Checkout'}</Button> */}
                                                            {/* <div class="ecommerce-button" style={{
                                                                display: 'flex', justifyContent: 'center',
                                                            }}> */}
                                                            {
                                                                // (this.state.templateData && this.state.templateData.templateSettings &&
                                                                //     this.state.templateData.templateSettings.paymentType && this.state.templateData.templateSettings.paymentType === 'Stripe') ?

                                                                //     <Popconfirm
                                                                //         title={this.locale("Would you like to proceed with the order with payment?")}
                                                                //         onConfirm={() => {
                                                                //             // this.setState({ paylater: false }, () => {
                                                                //             this.onClickCheckout(false)
                                                                //             // })
                                                                //         }}
                                                                //         okText={this.locale("Pay Now")}
                                                                //         cancelText={this.locale("Pay Later")}
                                                                //         onCancel={() =>
                                                                //             // this.setState({ paylater: true }, () => {
                                                                //             this.onClickCheckout(true)
                                                                //             // })
                                                                //         }
                                                                //     >
                                                                //         <Button type="primary" >
                                                                //             {(this.state.templateData && this.state.templateData.templateSettings &&
                                                                //                 this.state.templateData.templateSettings.fieldValues &&
                                                                //                 this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues &&
                                                                //                 this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.length > 0 &&
                                                                //                 this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.FieldType === 'Place order') ?
                                                                //                 this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.FieldType === 'Place order').AlternateValue : 'Checkout')}
                                                                //         </Button>
                                                                //     </Popconfirm>
                                                                // :
                                                                <Popconfirm
                                                                    title={this.locale("Would you like to proceed with the order?")}
                                                                    onConfirm={() => stripExist ? this.onClickCheckout(false) : this.onClickCheckout(true)}
                                                                    okText={stripExist ? this.locale("Pay now") : this.locale("Pay at pickup")}
                                                                    cancelText={this.locale("No")}
                                                                >
                                                                    <Button type="primary" >
                                                                        {(this.state.templateData && this.state.templateData.templateSettings &&
                                                                            this.state.templateData.templateSettings.fieldValues &&
                                                                            this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues &&
                                                                            this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.length > 0 &&
                                                                            this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.FieldType === 'Place order') ?
                                                                            this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.FieldType === 'Place order').AlternateValue : 'Checkout')}
                                                                    </Button>
                                                                </Popconfirm>

                                                            }
                                                            {/* </div> */}
                                                            <Button type="primary" onClick={() => this.setState({ openCartmodal: false })}>{'Add items'}</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
                {/* payment details */}

                {/* {
                    !transactionDataValues || transactionDataValues ? */}
                <div className="payment-details-order">
                    {templateSettings && templateSettings.hasOwnProperty('paymentDetailsValues') &&
                        templateSettings.paymentDetailsValues && templateSettings.paymentDetailsValues.paymentDetail &&
                        <div className="pubilc-detail-container">
                            {/* <br /> */}
                            <Divider />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Title level={4} className="pubilc-url-title">
                                    {(templateSettings.LocalizationDetailValues &&
                                        templateSettings.LocalizationDetailValues.length > 0 &&
                                        templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Payments details') ?
                                        templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Payments details').AlternateValue : 'Payment Details')}
                                </Title>

                                <p className="pubilc-url-detail" dangerouslySetInnerHTML={{
                                    __html: `${templateSettings.paymentDetailsValues.paymentDetail.replace(/\r?\n/g, '<br />')}`
                                }}
                                />
                            </div>
                        </div>
                    }

                    {/* terms and condition */}
                    {templateSettings && templateSettings.hasOwnProperty('termsConditionsDetailValues') &&
                        templateSettings.termsConditionsDetailValues && templateSettings.termsConditionsDetailValues &&
                        <div className="pubilc-detail-container">
                            {/* <br /> */}
                            <Divider />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Title level={4} className="pubilc-url-title">
                                    {(templateSettings.LocalizationDetailValues &&
                                        templateSettings.LocalizationDetailValues.length > 0 &&
                                        templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Terms and conditions') ?
                                        templateSettings.LocalizationDetailValues.find(item => item.FieldType === 'Terms and conditions').AlternateValue :
                                        'Terms and Conditions'
                                    )}
                                </Title>

                                <p className="pubilc-url-detail" dangerouslySetInnerHTML={{
                                    __html: (templateSettings.termsConditionsDetailValues.termsConditions || templateSettings.termsConditionsDetailValues.termsConditions === '')
                                        ?
                                        `${templateSettings.termsConditionsDetailValues.termsConditions.replace(/\r?\n/g, '<br />')}`
                                        :
                                        `${templateSettings.termsConditionsDetailValues.replace(/\r?\n/g, '<br />')}`
                                }}
                                />
                            </div>
                        </div>
                    }
                </div>



                {/*} :
                        <div className="payment-details-order">
                            {shorturlData.formData && shorturlData.formData.hasOwnProperty('OtherTemplateValues') &&
                                shorturlData.formData.OtherTemplateValues.paymentDetail &&
                                <div className="pubilc-detail-container" id="payment-details-view">
                                    <Divider />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Title level={4}>
                                            {(shorturlData.formData.LocalizationDetailValues &&
                                                shorturlData.formData.LocalizationDetailValues.length > 0 &&
                                                shorturlData.formData.LocalizationDetailValues.find(item => item.FieldType === 'Payments details') ?
                                                shorturlData.formData.LocalizationDetailValues.find(item => item.FieldType === 'Payments details').AlternateValue : "Payment Details")}
                                        </Title>

                                        <p className="pubilc-url-detail" dangerouslySetInnerHTML={{
                                            __html: `${shorturlData.formData.OtherTemplateValues.paymentDetail.replace(/\r?\n/g, '<br />')}`
                                        }}
                                        />
                                    </div>
                                </div>
                            }

                            {shorturlData.formData && shorturlData.formData.hasOwnProperty('OtherTemplateValues') &&
                                shorturlData.formData.OtherTemplateValues.termsConditions &&
                                <div style={{ marginTop: '3%', marginBottom: '3%' }}>
                                    <Divider />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Title level={4}>
                                            {(shorturlData.formData.LocalizationDetailValues &&
                                                shorturlData.formData.LocalizationDetailValues.length > 0 &&
                                                shorturlData.formData.LocalizationDetailValues.find(item => item.FieldType === 'Terms and conditions') ?
                                                shorturlData.formData.LocalizationDetailValues.find(item => item.FieldType === 'Terms and conditions').AlternateValue : 'Terms and Conditions')}
                                        </Title>

                                        <p dangerouslySetInnerHTML={{
                                            __html: `${shorturlData.formData.OtherTemplateValues.termsConditions.replace(/\r?\n/g, '<br />')}`
                                        }}
                                        />
                                    </div>
                                </div>
                            }

                            {shorturlData.formData && shorturlData.formData.editedBySeller && shorturlData.formData.hasOwnProperty('editedBySellerValues') &&
                                shorturlData.formData.editedBySellerValues.EditedOrderDetails && shorturlData.formData.editedBySellerValues.EditedOrderDetails.notes &&
                                <div style={{ marginTop: '3%', marginBottom: '3%' }}>
                                    <Divider />
                                    <Title level={4} >{this.locale('Edited Order Details Notes')} </Title>

                                    <p dangerouslySetInnerHTML={{
                                        __html: `${shorturlData.formData.editedBySellerValues.EditedOrderDetails.notes.replace(/\r?\n/g, '<br />')}`
                                    }}
                                    />
                                </div>
                            }
                        </div>
                } */}
                {/* </div> */}
                {/* <Layout style={{ position: "sticky", bottom: "0" }}>
                    <Footer>
                        <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
                        <Icon onClick={() => this.scrollToListingDetail()}
                            style={{ fontSize: 24, marginRight: 10, color: 'grey' }}
                            type="home" />
                        <Icon onClick={() => this.scrollToSearchRef()}
                            style={{ fontSize: 24, marginRight: 10, color: 'grey' }}
                            type="menu" />
                           
                        <Icon onClick={() => this.scrollToCartRef()}
                            style={{ fontSize: 24, marginRight: 10, color: 'grey' }}
                            type="shopping-cart" />
                            </div>
                    </Footer>
                </Layout> */}
            </div >
        )
    }
}

export default EcommerceForm;